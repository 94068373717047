import { baseApi as api } from './base.api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getActivities: build.query<GetActivitiesApiResponse, GetActivitiesApiArg>({
      query: (queryArg) => ({
        url: `/activities`,
        params: {
          user_id: queryArg.userId,
          actions: queryArg.actions,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          search: queryArg.search,
          search_field: queryArg.searchField,
          request_id: queryArg.requestId,
          after: queryArg.after,
          before: queryArg.before,
          limit: queryArg.limit,
          sort: queryArg.sort,
        },
      }),
    }),
    getAnnouncements: build.query<GetAnnouncementsApiResponse, GetAnnouncementsApiArg>({
      query: (queryArg) => ({
        url: `/announcements`,
        params: {
          categories: queryArg.categories,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postAnnouncements: build.mutation<PostAnnouncementsApiResponse, PostAnnouncementsApiArg>({
      query: (queryArg) => ({ url: `/announcements`, method: 'POST', body: queryArg.body }),
    }),
    deleteAnnouncementsByAnnouncementId: build.mutation<
      DeleteAnnouncementsByAnnouncementIdApiResponse,
      DeleteAnnouncementsByAnnouncementIdApiArg
    >({
      query: (queryArg) => ({ url: `/announcements/${queryArg.announcementId}`, method: 'DELETE' }),
    }),
    getAnnouncementsByAnnouncementId: build.query<
      GetAnnouncementsByAnnouncementIdApiResponse,
      GetAnnouncementsByAnnouncementIdApiArg
    >({
      query: (queryArg) => ({ url: `/announcements/${queryArg.announcementId}` }),
    }),
    patchAnnouncementsByAnnouncementId: build.mutation<
      PatchAnnouncementsByAnnouncementIdApiResponse,
      PatchAnnouncementsByAnnouncementIdApiArg
    >({
      query: (queryArg) => ({
        url: `/announcements/${queryArg.announcementId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    getBatches: build.query<GetBatchesApiResponse, GetBatchesApiArg>({
      query: (queryArg) => ({
        url: `/batches`,
        params: {
          assigned_user_id: queryArg.assignedUserId,
          is_assigned: queryArg.isAssigned,
          pickable_for_user_id: queryArg.pickableForUserId,
          pickable_for_pick_equipment_group_id: queryArg.pickableForPickEquipmentGroupId,
          created_user_id: queryArg.createdUserId,
          status: queryArg.status,
          statuses: queryArg.statuses,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          completed_before_datetime: queryArg.completedBeforeDatetime,
          completed_after_datetime: queryArg.completedAfterDatetime,
          search: queryArg.search,
          shipper_ids: queryArg.shipperIds,
          has_unresolved_exception: queryArg.hasUnresolvedException,
          automation_config_id: queryArg.automationConfigId,
          requested_lot_number: queryArg.requestedLotNumber,
          product_id: queryArg.productId,
          pick_strategy: queryArg.pickStrategy,
          pack_strategy: queryArg.packStrategy,
          picked_lot_number: queryArg.pickedLotNumber,
          pick_equipment_group_ids: queryArg.pickEquipmentGroupIds,
          has_pick_equipment_group: queryArg.hasPickEquipmentGroup,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postBatches: build.mutation<PostBatchesApiResponse, PostBatchesApiArg>({
      query: (queryArg) => ({ url: `/batches`, method: 'POST', body: queryArg.body }),
    }),
    getBatchesAssignedUsers: build.query<
      GetBatchesAssignedUsersApiResponse,
      GetBatchesAssignedUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/batches/assigned-users`,
        params: {
          assigned_user_id: queryArg.assignedUserId,
          pickable_for_user_id: queryArg.pickableForUserId,
          status: queryArg.status,
          statuses: queryArg.statuses,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          completed_before_datetime: queryArg.completedBeforeDatetime,
          completed_after_datetime: queryArg.completedAfterDatetime,
          search: queryArg.search,
          shipper_ids: queryArg.shipperIds,
          has_unresolved_exception: queryArg.hasUnresolvedException,
        },
      }),
    }),
    postBatchesBulk: build.mutation<PostBatchesBulkApiResponse, PostBatchesBulkApiArg>({
      query: (queryArg) => ({ url: `/batches/bulk`, method: 'POST', body: queryArg.body }),
    }),
    getBinLocations: build.query<GetBinLocationsApiResponse, GetBinLocationsApiArg>({
      query: (queryArg) => ({
        url: `/bin-locations`,
        params: {
          active: queryArg.active,
          zone_ids: queryArg.zoneIds,
          types: queryArg.types,
          subtypes: queryArg.subtypes,
          search: queryArg.search,
          location_ids: queryArg.locationIds,
          shipper_ids: queryArg.shipperIds,
          names: queryArg.names,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getBinLocationsDownload: build.query<
      GetBinLocationsDownloadApiResponse,
      GetBinLocationsDownloadApiArg
    >({
      query: (queryArg) => ({
        url: `/bin-locations/download`,
        params: {
          types: queryArg.types,
          subtypes: queryArg.subtypes,
          active: queryArg.active,
          search: queryArg.search,
          shipper_ids: queryArg.shipperIds,
        },
      }),
    }),
    getBuildingAnnouncements: build.query<
      GetBuildingAnnouncementsApiResponse,
      GetBuildingAnnouncementsApiArg
    >({
      query: (queryArg) => ({
        url: `/building-announcements`,
        params: {
          building_id: queryArg.buildingId,
          building_name: queryArg.buildingName,
          announcement_id: queryArg.announcementId,
          active: queryArg.active,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postBuildingAnnouncements: build.mutation<
      PostBuildingAnnouncementsApiResponse,
      PostBuildingAnnouncementsApiArg
    >({
      query: (queryArg) => ({
        url: `/building-announcements`,
        method: 'POST',
        body: queryArg.buildingAnnouncement,
      }),
    }),
    deleteBuildingAnnouncementsByBuildingAnnouncementId: build.mutation<
      DeleteBuildingAnnouncementsByBuildingAnnouncementIdApiResponse,
      DeleteBuildingAnnouncementsByBuildingAnnouncementIdApiArg
    >({
      query: (queryArg) => ({
        url: `/building-announcements/${queryArg.buildingAnnouncementId}`,
        method: 'DELETE',
      }),
    }),
    postBuildingAnnouncementsBulk: build.mutation<
      PostBuildingAnnouncementsBulkApiResponse,
      PostBuildingAnnouncementsBulkApiArg
    >({
      query: (queryArg) => ({
        url: `/building-announcements/bulk`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postOutboundsByOutboundIdParcels: build.mutation<
      PostOutboundsByOutboundIdParcelsApiResponse,
      PostOutboundsByOutboundIdParcelsApiArg
    >({
      query: (queryArg) => ({
        url: `/outbounds/${queryArg.outboundId}/parcels`,
        method: 'POST',
        body: queryArg.body,
        params: { type: queryArg['type'] },
      }),
    }),
    getOutboundsByOutboundIdParcels: build.query<
      GetOutboundsByOutboundIdParcelsApiResponse,
      GetOutboundsByOutboundIdParcelsApiArg
    >({
      query: (queryArg) => ({
        url: `/outbounds/${queryArg.outboundId}/parcels`,
        params: { page: queryArg.page, page_size: queryArg.pageSize },
      }),
    }),
    deleteOutboundsByOutboundIdParcelsInProgress: build.mutation<
      DeleteOutboundsByOutboundIdParcelsInProgressApiResponse,
      DeleteOutboundsByOutboundIdParcelsInProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/outbounds/${queryArg.outboundId}/parcels-in-progress`,
        method: 'DELETE',
      }),
    }),
    deleteOutboundsByOutboundIdParcelsAndParcelId: build.mutation<
      DeleteOutboundsByOutboundIdParcelsAndParcelIdApiResponse,
      DeleteOutboundsByOutboundIdParcelsAndParcelIdApiArg
    >({
      query: (queryArg) => ({
        url: `/outbounds/${queryArg.outboundId}/parcels/${queryArg.parcelId}`,
        method: 'DELETE',
        params: { force: queryArg.force },
      }),
    }),
    getOutboundsByOutboundIdParcelsAndParcelId: build.query<
      GetOutboundsByOutboundIdParcelsAndParcelIdApiResponse,
      GetOutboundsByOutboundIdParcelsAndParcelIdApiArg
    >({
      query: (queryArg) => ({
        url: `/outbounds/${queryArg.outboundId}/parcels/${queryArg.parcelId}`,
      }),
    }),
    postOutboundsByOutboundIdParcelsAndParcelIdGenerateLabel: build.mutation<
      PostOutboundsByOutboundIdParcelsAndParcelIdGenerateLabelApiResponse,
      PostOutboundsByOutboundIdParcelsAndParcelIdGenerateLabelApiArg
    >({
      query: (queryArg) => ({
        url: `/outbounds/${queryArg.outboundId}/parcels/${queryArg.parcelId}/generate-label`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getOutboundsByOutboundIdParcelsAndParcelIdPackingSlip: build.query<
      GetOutboundsByOutboundIdParcelsAndParcelIdPackingSlipApiResponse,
      GetOutboundsByOutboundIdParcelsAndParcelIdPackingSlipApiArg
    >({
      query: (queryArg) => ({
        url: `/outbounds/${queryArg.outboundId}/parcels/${queryArg.parcelId}/packing-slip`,
        params: { format: queryArg.format },
      }),
    }),
    postShippersByShipperIdParcelsBulk: build.mutation<
      PostShippersByShipperIdParcelsBulkApiResponse,
      PostShippersByShipperIdParcelsBulkApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperId}/parcels/bulk`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getShippersByShipperIdParcelsBulkAndJobId: build.query<
      GetShippersByShipperIdParcelsBulkAndJobIdApiResponse,
      GetShippersByShipperIdParcelsBulkAndJobIdApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperId}/parcels/bulk/${queryArg.jobId}`,
      }),
    }),
    putOutboundsByOutboundIdParcelsAndParcelIdItemsItemId: build.mutation<
      PutOutboundsByOutboundIdParcelsAndParcelIdItemsItemIdApiResponse,
      PutOutboundsByOutboundIdParcelsAndParcelIdItemsItemIdApiArg
    >({
      query: (queryArg) => ({
        url: `/outbounds/${queryArg.outboundId}/parcels/${queryArg.parcelId}/items/${queryArg.itemId}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getOutboundsByOutboundIdAttachments: build.query<
      GetOutboundsByOutboundIdAttachmentsApiResponse,
      GetOutboundsByOutboundIdAttachmentsApiArg
    >({
      query: (queryArg) => ({ url: `/outbounds/${queryArg.outboundId}/attachments` }),
    }),
    postOutboundsByOutboundIdAttachments: build.mutation<
      PostOutboundsByOutboundIdAttachmentsApiResponse,
      PostOutboundsByOutboundIdAttachmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/outbounds/${queryArg.outboundId}/attachments`,
        method: 'POST',
        body: queryArg.attachment,
      }),
    }),
    getParcels: build.query<GetParcelsApiResponse, GetParcelsApiArg>({
      query: (queryArg) => ({
        url: `/parcels`,
        params: {
          tracking_number: queryArg.trackingNumber,
          enhanced_tracking_number: queryArg.enhancedTrackingNumber,
          has_tracking_number: queryArg.hasTrackingNumber,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          updated_after_datetime: queryArg.updatedAfterDatetime,
          updated_before_datetime: queryArg.updatedBeforeDatetime,
          void: queryArg['void'],
        },
      }),
    }),
    getParcelsByParcelId: build.query<GetParcelsByParcelIdApiResponse, GetParcelsByParcelIdApiArg>({
      query: (queryArg) => ({ url: `/parcels/${queryArg.parcelId}` }),
    }),
    postParcelsByParcelIdShip: build.mutation<
      PostParcelsByParcelIdShipApiResponse,
      PostParcelsByParcelIdShipApiArg
    >({
      query: (queryArg) => ({ url: `/parcels/${queryArg.parcelId}/ship`, method: 'POST' }),
    }),
    postShippersByShipperNameOutboundsAndOrderNumberAttachments: build.mutation<
      PostShippersByShipperNameOutboundsAndOrderNumberAttachmentsApiResponse,
      PostShippersByShipperNameOutboundsAndOrderNumberAttachmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperName}/outbounds/${queryArg.orderNumber}/attachments`,
        method: 'POST',
        body: queryArg.attachment,
      }),
    }),
    getAttachmentsByAttachmentId: build.query<
      GetAttachmentsByAttachmentIdApiResponse,
      GetAttachmentsByAttachmentIdApiArg
    >({
      query: (queryArg) => ({ url: `/attachments/${queryArg.attachmentId}` }),
    }),
    deleteAttachmentsByAttachmentId: build.mutation<
      DeleteAttachmentsByAttachmentIdApiResponse,
      DeleteAttachmentsByAttachmentIdApiArg
    >({
      query: (queryArg) => ({ url: `/attachments/${queryArg.attachmentId}`, method: 'DELETE' }),
    }),
    getBatchesByBatchId: build.query<GetBatchesByBatchIdApiResponse, GetBatchesByBatchIdApiArg>({
      query: (queryArg) => ({ url: `/batches/${queryArg.batchId}` }),
    }),
    patchBatchesByBatchId: build.mutation<
      PatchBatchesByBatchIdApiResponse,
      PatchBatchesByBatchIdApiArg
    >({
      query: (queryArg) => ({
        url: `/batches/${queryArg.batchId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    postBatchesByBatchIdPick: build.mutation<
      PostBatchesByBatchIdPickApiResponse,
      PostBatchesByBatchIdPickApiArg
    >({
      query: (queryArg) => ({
        url: `/batches/${queryArg.batchId}/pick`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getBatchesByBatchIdPickPath: build.query<
      GetBatchesByBatchIdPickPathApiResponse,
      GetBatchesByBatchIdPickPathApiArg
    >({
      query: (queryArg) => ({
        url: `/batches/${queryArg.batchId}/pick-path`,
        params: {
          search: queryArg.search,
          is_original_pick: queryArg.isOriginalPick,
          has_exception: queryArg.hasException,
          is_completed: queryArg.isCompleted,
          product_ids: queryArg.productIds,
          location_ids: queryArg.locationIds,
          sort: queryArg.sort,
        },
      }),
    }),
    postBatchesByBatchIdPickPath: build.mutation<
      PostBatchesByBatchIdPickPathApiResponse,
      PostBatchesByBatchIdPickPathApiArg
    >({
      query: (queryArg) => ({ url: `/batches/${queryArg.batchId}/pick-path`, method: 'POST' }),
    }),
    postBatchesByBatchIdAlternatePick: build.mutation<
      PostBatchesByBatchIdAlternatePickApiResponse,
      PostBatchesByBatchIdAlternatePickApiArg
    >({
      query: (queryArg) => ({
        url: `/batches/${queryArg.batchId}/alternate-pick`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postBatchesByBatchIdRelease: build.mutation<
      PostBatchesByBatchIdReleaseApiResponse,
      PostBatchesByBatchIdReleaseApiArg
    >({
      query: (queryArg) => ({ url: `/batches/${queryArg.batchId}/release`, method: 'POST' }),
    }),
    postBatchesByBatchIdShortSplit: build.mutation<
      PostBatchesByBatchIdShortSplitApiResponse,
      PostBatchesByBatchIdShortSplitApiArg
    >({
      query: (queryArg) => ({ url: `/batches/${queryArg.batchId}/short-split`, method: 'POST' }),
    }),
    getPrinters: build.query<GetPrintersApiResponse, GetPrintersApiArg>({
      query: (queryArg) => ({
        url: `/printers`,
        params: { page: queryArg.page, page_size: queryArg.pageSize, sort: queryArg.sort },
      }),
    }),
    postPrinters: build.mutation<PostPrintersApiResponse, PostPrintersApiArg>({
      query: (queryArg) => ({ url: `/printers`, method: 'POST', body: queryArg.printer }),
    }),
    deletePrintersByPrinterId: build.mutation<
      DeletePrintersByPrinterIdApiResponse,
      DeletePrintersByPrinterIdApiArg
    >({
      query: (queryArg) => ({ url: `/printers/${queryArg.printerId}`, method: 'DELETE' }),
    }),
    patchPrintersByPrinterId: build.mutation<
      PatchPrintersByPrinterIdApiResponse,
      PatchPrintersByPrinterIdApiArg
    >({
      query: (queryArg) => ({
        url: `/printers/${queryArg.printerId}`,
        method: 'PATCH',
        body: queryArg.printer,
      }),
    }),
    getItems: build.query<GetItemsApiResponse, GetItemsApiArg>({
      query: (queryArg) => ({
        url: `/items`,
        params: {
          active: queryArg.active,
          search: queryArg.search,
          location_ids: queryArg.locationIds,
          location_names: queryArg.locationNames,
          location_types: queryArg.locationTypes,
          location_subtypes: queryArg.locationSubtypes,
          locked: queryArg.locked,
          inbound_id: queryArg.inboundId,
          outbound_id: queryArg.outboundId,
          product_ids: queryArg.productIds,
          item_ids: queryArg.itemIds,
          license_plate_ids: queryArg.licensePlateIds,
          license_plate_numbers: queryArg.licensePlateNumbers,
          inbound_line_ids: queryArg.inboundLineIds,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          shipper_ids: queryArg.shipperIds,
          skus: queryArg.skus,
          lot_number: queryArg.lotNumber,
          manufacturer_date: queryArg.manufacturerDate,
          expiration_date: queryArg.expirationDate,
          has_expiration_date: queryArg.hasExpirationDate,
          has_manufacturer_date: queryArg.hasManufacturerDate,
          include_orphan_items: queryArg.includeOrphanItems,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postItems: build.mutation<PostItemsApiResponse, PostItemsApiArg>({
      query: (queryArg) => ({ url: `/items`, method: 'POST', body: queryArg.body }),
    }),
    getItemsByItemId: build.query<GetItemsByItemIdApiResponse, GetItemsByItemIdApiArg>({
      query: (queryArg) => ({ url: `/items/${queryArg.itemId}` }),
    }),
    patchItemsByItemId: build.mutation<PatchItemsByItemIdApiResponse, PatchItemsByItemIdApiArg>({
      query: (queryArg) => ({
        url: `/items/${queryArg.itemId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    getItemsByItemIdMovements: build.query<
      GetItemsByItemIdMovementsApiResponse,
      GetItemsByItemIdMovementsApiArg
    >({
      query: (queryArg) => ({
        url: `/items/${queryArg.itemId}/movements`,
        params: {
          user_id: queryArg.userId,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postItemsByItemIdMovements: build.mutation<
      PostItemsByItemIdMovementsApiResponse,
      PostItemsByItemIdMovementsApiArg
    >({
      query: (queryArg) => ({
        url: `/items/${queryArg.itemId}/movements`,
        method: 'POST',
        body: queryArg.movement,
      }),
    }),
    getItemsByItemIdAdjustments: build.query<
      GetItemsByItemIdAdjustmentsApiResponse,
      GetItemsByItemIdAdjustmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/items/${queryArg.itemId}/adjustments`,
        params: {
          product_id: queryArg.productId,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postItemsByItemIdAdjustments: build.mutation<
      PostItemsByItemIdAdjustmentsApiResponse,
      PostItemsByItemIdAdjustmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/items/${queryArg.itemId}/adjustments`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getItemsDownload: build.query<GetItemsDownloadApiResponse, GetItemsDownloadApiArg>({
      query: (queryArg) => ({
        url: `/items/download`,
        params: {
          active: queryArg.active,
          search: queryArg.search,
          location_ids: queryArg.locationIds,
          location_names: queryArg.locationNames,
          location_types: queryArg.locationTypes,
          location_subtypes: queryArg.locationSubtypes,
          locked: queryArg.locked,
          inbound_id: queryArg.inboundId,
          outbound_id: queryArg.outboundId,
          product_ids: queryArg.productIds,
          item_ids: queryArg.itemIds,
          license_plate_ids: queryArg.licensePlateIds,
          license_plate_numbers: queryArg.licensePlateNumbers,
          inbound_line_ids: queryArg.inboundLineIds,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          shipper_ids: queryArg.shipperIds,
          skus: queryArg.skus,
          lot_number: queryArg.lotNumber,
          manufacturer_date: queryArg.manufacturerDate,
          expiration_date: queryArg.expirationDate,
          has_expiration_date: queryArg.hasExpirationDate,
          has_manufacturer_date: queryArg.hasManufacturerDate,
          include_orphan_items: queryArg.includeOrphanItems,
          template: queryArg.template,
        },
      }),
    }),
    postItemsUpload: build.mutation<PostItemsUploadApiResponse, PostItemsUploadApiArg>({
      query: (queryArg) => ({ url: `/items/upload`, method: 'POST', body: queryArg.body }),
    }),
    getItemsLotNumbers: build.query<GetItemsLotNumbersApiResponse, GetItemsLotNumbersApiArg>({
      query: (queryArg) => ({
        url: `/items/lot-numbers`,
        params: {
          shipper_ids: queryArg.shipperIds,
          product_ids: queryArg.productIds,
          lot_number: queryArg.lotNumber,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getMovements: build.query<GetMovementsApiResponse, GetMovementsApiArg>({
      query: (queryArg) => ({
        url: `/movements`,
        params: {
          item_id: queryArg.itemId,
          product_id: queryArg.productId,
          license_plate_id: queryArg.licensePlateId,
          sku: queryArg.sku,
          user_id: queryArg.userId,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getInbounds: build.query<GetInboundsApiResponse, GetInboundsApiArg>({
      query: (queryArg) => ({
        url: `/inbounds`,
        params: {
          status: queryArg.status,
          product_id: queryArg.productId,
          sku: queryArg.sku,
          external_id: queryArg.externalId,
          inbound_line_ids: queryArg.inboundLineIds,
          license_plate_id: queryArg.licensePlateId,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          received_after_datetime: queryArg.receivedAfterDatetime,
          received_before_datetime: queryArg.receivedBeforeDatetime,
          expected_after_date: queryArg.expectedAfterDate,
          expected_before_date: queryArg.expectedBeforeDate,
          put_away_after_datetime: queryArg.putAwayAfterDatetime,
          put_away_before_datetime: queryArg.putAwayBeforeDatetime,
          shipper_ids: queryArg.shipperIds,
          search: queryArg.search,
          channel_name: queryArg.channelName,
          lot_number: queryArg.lotNumber,
          order_types: queryArg.orderTypes,
          source: queryArg.source,
          has_unresolved_exception: queryArg.hasUnresolvedException,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postInbounds: build.mutation<PostInboundsApiResponse, PostInboundsApiArg>({
      query: (queryArg) => ({ url: `/inbounds`, method: 'POST', body: queryArg.body }),
    }),
    getInboundsByInboundOrderId: build.query<
      GetInboundsByInboundOrderIdApiResponse,
      GetInboundsByInboundOrderIdApiArg
    >({
      query: (queryArg) => ({ url: `/inbounds/${queryArg.inboundOrderId}` }),
    }),
    patchInboundsByInboundOrderId: build.mutation<
      PatchInboundsByInboundOrderIdApiResponse,
      PatchInboundsByInboundOrderIdApiArg
    >({
      query: (queryArg) => ({
        url: `/inbounds/${queryArg.inboundOrderId}`,
        method: 'PATCH',
        body: queryArg.inboundOrder,
      }),
    }),
    deleteInboundsByInboundOrderId: build.mutation<
      DeleteInboundsByInboundOrderIdApiResponse,
      DeleteInboundsByInboundOrderIdApiArg
    >({
      query: (queryArg) => ({ url: `/inbounds/${queryArg.inboundOrderId}`, method: 'DELETE' }),
    }),
    getInboundsDownload: build.query<GetInboundsDownloadApiResponse, GetInboundsDownloadApiArg>({
      query: (queryArg) => ({
        url: `/inbounds/download`,
        params: {
          status: queryArg.status,
          product_id: queryArg.productId,
          sku: queryArg.sku,
          external_id: queryArg.externalId,
          inbound_line_ids: queryArg.inboundLineIds,
          license_plate_id: queryArg.licensePlateId,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          received_after_datetime: queryArg.receivedAfterDatetime,
          received_before_datetime: queryArg.receivedBeforeDatetime,
          expected_after_date: queryArg.expectedAfterDate,
          expected_before_date: queryArg.expectedBeforeDate,
          put_away_after_datetime: queryArg.putAwayAfterDatetime,
          put_away_before_datetime: queryArg.putAwayBeforeDatetime,
          shipper_ids: queryArg.shipperIds,
          search: queryArg.search,
          channel_name: queryArg.channelName,
          lot_number: queryArg.lotNumber,
          order_types: queryArg.orderTypes,
          source: queryArg.source,
        },
      }),
    }),
    getFintechCustomers: build.query<GetFintechCustomersApiResponse, GetFintechCustomersApiArg>({
      query: (queryArg) => ({
        url: `/fintech/customers`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          ids: queryArg.ids,
          company_id: queryArg.companyId,
          stord_managed: queryArg.stordManaged,
          search: queryArg.search,
        },
      }),
    }),
    getFintechContracts: build.query<GetFintechContractsApiResponse, GetFintechContractsApiArg>({
      query: (queryArg) => ({
        url: `/fintech/contracts`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          company_ids: queryArg.companyIds,
          customer_ids: queryArg.customerIds,
          category_ids: queryArg.categoryIds,
          effective_after: queryArg.effectiveAfter,
          effective_before: queryArg.effectiveBefore,
          expires_after: queryArg.expiresAfter,
          expires_before: queryArg.expiresBefore,
          status: queryArg.status,
        },
      }),
    }),
    getFintechBillingCategories: build.query<
      GetFintechBillingCategoriesApiResponse,
      GetFintechBillingCategoriesApiArg
    >({
      query: (queryArg) => ({
        url: `/fintech/billing-categories`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          search: queryArg.search,
          ids: queryArg.ids,
        },
      }),
    }),
    getFintechBillingCodes: build.query<
      GetFintechBillingCodesApiResponse,
      GetFintechBillingCodesApiArg
    >({
      query: (queryArg) => ({
        url: `/fintech/billing-codes`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          active: queryArg.active,
          billing_category_id: queryArg.billingCategoryId,
          search: queryArg.search,
          ids: queryArg.ids,
        },
      }),
    }),
    getFintechContractsByContractId: build.query<
      GetFintechContractsByContractIdApiResponse,
      GetFintechContractsByContractIdApiArg
    >({
      query: (queryArg) => ({ url: `/fintech/contracts/${queryArg.contractId}` }),
    }),
    getFintechInvoices: build.query<GetFintechInvoicesApiResponse, GetFintechInvoicesApiArg>({
      query: (queryArg) => ({
        url: `/fintech/invoices`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          company_id: queryArg.companyId,
          customer_id: queryArg.customerId,
          ends_after: queryArg.endsAfter,
          ends_before: queryArg.endsBefore,
          search: queryArg.search,
          starts_after: queryArg.startsAfter,
          starts_before: queryArg.startsBefore,
          status: queryArg.status,
          amount_gt: queryArg.amountGt,
          amount_lt: queryArg.amountLt,
        },
      }),
    }),
    postFintechInvoices: build.mutation<PostFintechInvoicesApiResponse, PostFintechInvoicesApiArg>({
      query: (queryArg) => ({ url: `/fintech/invoices`, method: 'POST', body: queryArg.invoice }),
    }),
    getFintechInvoicesByInvoiceId: build.query<
      GetFintechInvoicesByInvoiceIdApiResponse,
      GetFintechInvoicesByInvoiceIdApiArg
    >({
      query: (queryArg) => ({ url: `/fintech/invoices/${queryArg.invoiceId}` }),
    }),
    patchFintechInvoicesByInvoiceId: build.mutation<
      PatchFintechInvoicesByInvoiceIdApiResponse,
      PatchFintechInvoicesByInvoiceIdApiArg
    >({
      query: (queryArg) => ({
        url: `/fintech/invoices/${queryArg.invoiceId}`,
        method: 'PATCH',
        body: queryArg.invoice,
      }),
    }),
    getFintechInvoicesByInvoiceIdItems: build.query<
      GetFintechInvoicesByInvoiceIdItemsApiResponse,
      GetFintechInvoicesByInvoiceIdItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/fintech/invoices/${queryArg.invoiceId}/items`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          charged_after: queryArg.chargedAfter,
          charged_before: queryArg.chargedBefore,
          company_id: queryArg.companyId,
          invoice_id: queryArg.invoice_id,
          price_id: queryArg.priceId,
          search: queryArg.search,
          amount_gt: queryArg.amountGt,
          amount_lt: queryArg.amountLt,
        },
      }),
    }),
    postFintechInvoicesByInvoiceIdItems: build.mutation<
      PostFintechInvoicesByInvoiceIdItemsApiResponse,
      PostFintechInvoicesByInvoiceIdItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/fintech/invoices/${queryArg.invoiceId}/items`,
        method: 'POST',
        body: queryArg.invoiceItem,
      }),
    }),
    getFintechPrices: build.query<GetFintechPricesApiResponse, GetFintechPricesApiArg>({
      query: (queryArg) => ({
        url: `/fintech/prices`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          company_id: queryArg.companyId,
          billing_code_id: queryArg.billingCodeId,
          contract_id: queryArg.contractId,
          currency: queryArg.currency,
          amount_gt: queryArg.amountGt,
          amount_lt: queryArg.amountLt,
        },
      }),
    }),
    getShippersByShipperNameInboundsAndOrderNumber: build.query<
      GetShippersByShipperNameInboundsAndOrderNumberApiResponse,
      GetShippersByShipperNameInboundsAndOrderNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperName}/inbounds/${queryArg.orderNumber}`,
      }),
    }),
    patchShippersByShipperNameInboundsAndOrderNumber: build.mutation<
      PatchShippersByShipperNameInboundsAndOrderNumberApiResponse,
      PatchShippersByShipperNameInboundsAndOrderNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperName}/inbounds/${queryArg.orderNumber}`,
        method: 'PATCH',
        body: queryArg.inboundOrder,
      }),
    }),
    deleteShippersByShipperNameInboundsAndOrderNumber: build.mutation<
      DeleteShippersByShipperNameInboundsAndOrderNumberApiResponse,
      DeleteShippersByShipperNameInboundsAndOrderNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperName}/inbounds/${queryArg.orderNumber}`,
        method: 'DELETE',
      }),
    }),
    getInboundsByInboundOrderIdLines: build.query<
      GetInboundsByInboundOrderIdLinesApiResponse,
      GetInboundsByInboundOrderIdLinesApiArg
    >({
      query: (queryArg) => ({
        url: `/inbounds/${queryArg.inboundOrderId}/lines`,
        params: {
          product_ids: queryArg.productIds,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getInboundsByInboundOrderIdItems: build.query<
      GetInboundsByInboundOrderIdItemsApiResponse,
      GetInboundsByInboundOrderIdItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/inbounds/${queryArg.inboundOrderId}/items`,
        params: {
          search: queryArg.search,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getInboundsReceipts: build.query<GetInboundsReceiptsApiResponse, GetInboundsReceiptsApiArg>({
      query: (queryArg) => ({
        url: `/inbounds/receipts`,
        params: { inbound_ids: queryArg.inboundIds, status: queryArg.status, sort: queryArg.sort },
      }),
    }),
    getInboundsByInboundOrderIdReceipts: build.query<
      GetInboundsByInboundOrderIdReceiptsApiResponse,
      GetInboundsByInboundOrderIdReceiptsApiArg
    >({
      query: (queryArg) => ({
        url: `/inbounds/${queryArg.inboundOrderId}/receipts`,
        params: {
          receipt_lines_ids: queryArg.receiptLinesIds,
          include_receipt_lines: queryArg.includeReceiptLines,
          sort: queryArg.sort,
        },
      }),
    }),
    getInboundsByInboundOrderIdReceiptsAndReceiptId: build.query<
      GetInboundsByInboundOrderIdReceiptsAndReceiptIdApiResponse,
      GetInboundsByInboundOrderIdReceiptsAndReceiptIdApiArg
    >({
      query: (queryArg) => ({
        url: `/inbounds/${queryArg.inboundOrderId}/receipts/${queryArg.receiptId}`,
      }),
    }),
    patchInboundsByInboundOrderIdReceiptsAndReceiptId: build.mutation<
      PatchInboundsByInboundOrderIdReceiptsAndReceiptIdApiResponse,
      PatchInboundsByInboundOrderIdReceiptsAndReceiptIdApiArg
    >({
      query: (queryArg) => ({
        url: `/inbounds/${queryArg.inboundOrderId}/receipts/${queryArg.receiptId}`,
        method: 'PATCH',
        body: queryArg.receipt,
      }),
    }),
    getReceiptsByReceiptIdReceiptLines: build.query<
      GetReceiptsByReceiptIdReceiptLinesApiResponse,
      GetReceiptsByReceiptIdReceiptLinesApiArg
    >({
      query: (queryArg) => ({
        url: `/receipts/${queryArg.receiptId}/receipt-lines`,
        params: {
          product_id: queryArg.productId,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getShippersByShipperNameInboundsAndExternalIdLines: build.query<
      GetShippersByShipperNameInboundsAndExternalIdLinesApiResponse,
      GetShippersByShipperNameInboundsAndExternalIdLinesApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperName}/inbounds/${queryArg.externalId}/lines`,
        params: { page: queryArg.page, page_size: queryArg.pageSize, sort: queryArg.sort },
      }),
    }),
    getInboundsByInboundOrderIdLinesAndInboundLineId: build.query<
      GetInboundsByInboundOrderIdLinesAndInboundLineIdApiResponse,
      GetInboundsByInboundOrderIdLinesAndInboundLineIdApiArg
    >({
      query: (queryArg) => ({
        url: `/inbounds/${queryArg.inboundOrderId}/lines/${queryArg.inboundLineId}`,
      }),
    }),
    patchInboundsByInboundOrderIdLinesAndInboundLineId: build.mutation<
      PatchInboundsByInboundOrderIdLinesAndInboundLineIdApiResponse,
      PatchInboundsByInboundOrderIdLinesAndInboundLineIdApiArg
    >({
      query: (queryArg) => ({
        url: `/inbounds/${queryArg.inboundOrderId}/lines/${queryArg.inboundLineId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    postInboundsByInboundOrderIdLinesAndInboundLineIdLpns: build.mutation<
      PostInboundsByInboundOrderIdLinesAndInboundLineIdLpnsApiResponse,
      PostInboundsByInboundOrderIdLinesAndInboundLineIdLpnsApiArg
    >({
      query: (queryArg) => ({
        url: `/inbounds/${queryArg.inboundOrderId}/lines/${queryArg.inboundLineId}/lpns`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    patchInboundsByInboundOrderIdLinesAndInboundLineIdLpnsLicensePlateIdItemsItemId: build.mutation<
      PatchInboundsByInboundOrderIdLinesAndInboundLineIdLpnsLicensePlateIdItemsItemIdApiResponse,
      PatchInboundsByInboundOrderIdLinesAndInboundLineIdLpnsLicensePlateIdItemsItemIdApiArg
    >({
      query: (queryArg) => ({
        url: `/inbounds/${queryArg.inboundOrderId}/lines/${queryArg.inboundLineId}/lpns/${queryArg.licensePlateId}/items/${queryArg.itemId}`,
        method: 'PATCH',
        body: queryArg.item,
      }),
    }),
    deleteInboundsByInboundOrderIdLinesAndInboundLineIdLpnsLicensePlateIdItemsItemId:
      build.mutation<
        DeleteInboundsByInboundOrderIdLinesAndInboundLineIdLpnsLicensePlateIdItemsItemIdApiResponse,
        DeleteInboundsByInboundOrderIdLinesAndInboundLineIdLpnsLicensePlateIdItemsItemIdApiArg
      >({
        query: (queryArg) => ({
          url: `/inbounds/${queryArg.inboundOrderId}/lines/${queryArg.inboundLineId}/lpns/${queryArg.licensePlateId}/items/${queryArg.itemId}`,
          method: 'DELETE',
        }),
      }),
    postInboundsByInboundOrderIdRepublish: build.mutation<
      PostInboundsByInboundOrderIdRepublishApiResponse,
      PostInboundsByInboundOrderIdRepublishApiArg
    >({
      query: (queryArg) => ({
        url: `/inbounds/${queryArg.inboundOrderId}/republish`,
        method: 'POST',
      }),
    }),
    postInboundsByInboundOrderIdRepublishReceipts: build.mutation<
      PostInboundsByInboundOrderIdRepublishReceiptsApiResponse,
      PostInboundsByInboundOrderIdRepublishReceiptsApiArg
    >({
      query: (queryArg) => ({
        url: `/inbounds/${queryArg.inboundOrderId}/republish-receipts`,
        method: 'POST',
      }),
    }),
    getOverviewInbound: build.query<GetOverviewInboundApiResponse, GetOverviewInboundApiArg>({
      query: (queryArg) => ({
        url: `/overview/inbound`,
        params: { shipper_ids: queryArg.shipperIds },
      }),
    }),
    getOverviewOutbound: build.query<GetOverviewOutboundApiResponse, GetOverviewOutboundApiArg>({
      query: (queryArg) => ({
        url: `/overview/outbound`,
        params: { shipper_ids: queryArg.shipperIds, from: queryArg['from'], to: queryArg.to },
      }),
    }),
    postLocations: build.mutation<PostLocationsApiResponse, PostLocationsApiArg>({
      query: (queryArg) => ({ url: `/locations`, method: 'POST', body: queryArg.location }),
    }),
    getLocations: build.query<GetLocationsApiResponse, GetLocationsApiArg>({
      query: (queryArg) => ({
        url: `/locations`,
        params: {
          active: queryArg.active,
          type: queryArg['type'],
          types: queryArg.types,
          subtypes: queryArg.subtypes,
          search: queryArg.search,
          location_ids: queryArg.locationIds,
          item_shipper_ids: queryArg.itemShipperIds,
          include_product_summary: queryArg.includeProductSummary,
          names: queryArg.names,
          alike_item_id: queryArg.alikeItemId,
          license_plate_locked: queryArg.licensePlateLocked,
          pick_equipment_group_ids: queryArg.pickEquipmentGroupIds,
          has_cycle_count: queryArg.hasCycleCount,
          zone_ids: queryArg.zoneIds,
          product_ids: queryArg.productIds,
          has_max_capacity: queryArg.hasMaxCapacity,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getLocationsByLocationId: build.query<
      GetLocationsByLocationIdApiResponse,
      GetLocationsByLocationIdApiArg
    >({
      query: (queryArg) => ({ url: `/locations/${queryArg.locationId}` }),
    }),
    patchLocationsByLocationId: build.mutation<
      PatchLocationsByLocationIdApiResponse,
      PatchLocationsByLocationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/locations/${queryArg.locationId}`,
        method: 'PATCH',
        body: queryArg.location,
      }),
    }),
    getLocationsByLocationIdAlternate: build.query<
      GetLocationsByLocationIdAlternateApiResponse,
      GetLocationsByLocationIdAlternateApiArg
    >({
      query: (queryArg) => ({
        url: `/locations/${queryArg.locationId}/alternate`,
        params: {
          sku: queryArg.sku,
          shipper_id: queryArg.shipperId,
          alike_item_id: queryArg.alikeItemId,
          license_plate_locked: queryArg.licensePlateLocked,
          lot_number: queryArg.lotNumber,
          exact_lot_number_required: queryArg.exactLotNumberRequired,
          min_shelf_life_days: queryArg.minShelfLifeDays,
          outbound_id: queryArg.outboundId,
          work_order_id: queryArg.workOrderId,
          include_current_location: queryArg.includeCurrentLocation,
          search: queryArg.search,
          pick_from_location_strategy: queryArg.pickFromLocationStrategy,
          zone_id: queryArg.zoneId,
          available_capacity: queryArg.availableCapacity,
          has_max_capacity: queryArg.hasMaxCapacity,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getLocationsPicking: build.query<GetLocationsPickingApiResponse, GetLocationsPickingApiArg>({
      query: (queryArg) => ({
        url: `/locations/picking`,
        params: { product_id: queryArg.productId, quantity: queryArg.quantity },
      }),
    }),
    getLocationsDownload: build.query<GetLocationsDownloadApiResponse, GetLocationsDownloadApiArg>({
      query: (queryArg) => ({
        url: `/locations/download`,
        params: {
          active: queryArg.active,
          type: queryArg['type'],
          types: queryArg.types,
          subtypes: queryArg.subtypes,
          search: queryArg.search,
          location_ids: queryArg.locationIds,
          item_shipper_ids: queryArg.itemShipperIds,
          include_product_summary: queryArg.includeProductSummary,
          names: queryArg.names,
          alike_item_id: queryArg.alikeItemId,
          license_plate_locked: queryArg.licensePlateLocked,
          pick_equipment_group_ids: queryArg.pickEquipmentGroupIds,
          has_cycle_count: queryArg.hasCycleCount,
          zone_ids: queryArg.zoneIds,
          template: queryArg.template,
        },
      }),
    }),
    postLocationsUpload: build.mutation<PostLocationsUploadApiResponse, PostLocationsUploadApiArg>({
      query: (queryArg) => ({ url: `/locations/upload`, method: 'POST', body: queryArg.body }),
    }),
    patchLocationsBulkAssignZone: build.mutation<
      PatchLocationsBulkAssignZoneApiResponse,
      PatchLocationsBulkAssignZoneApiArg
    >({
      query: (queryArg) => ({
        url: `/locations/bulk-assign-zone`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    getLocationsEmptyPutawayLocations: build.query<
      GetLocationsEmptyPutawayLocationsApiResponse,
      GetLocationsEmptyPutawayLocationsApiArg
    >({
      query: (queryArg) => ({
        url: `/locations/empty-putaway-locations`,
        params: {
          shipper_id: queryArg.shipperId,
          putaway_location_strategy: queryArg.putawayLocationStrategy,
          putaway_location_zone_id: queryArg.putawayLocationZoneId,
          available_capacity: queryArg.availableCapacity,
          has_max_capacity: queryArg.hasMaxCapacity,
          page: queryArg.page,
          page_size: queryArg.pageSize,
        },
      }),
    }),
    postBroadcasts: build.mutation<PostBroadcastsApiResponse, PostBroadcastsApiArg>({
      query: (queryArg) => ({
        url: `/broadcasts`,
        method: 'POST',
        body: queryArg.broadcastNotification,
      }),
    }),
    getOutbounds: build.query<GetOutboundsApiResponse, GetOutboundsApiArg>({
      query: (queryArg) => ({
        url: `/outbounds`,
        params: {
          status: queryArg.status,
          shipment_type: queryArg.shipmentType,
          product_id: queryArg.productId,
          location_id: queryArg.locationId,
          outbound_ids: queryArg.outboundIds,
          item_ids: queryArg.itemIds,
          fulfillable: queryArg.fulfillable,
          allocatable: queryArg.allocatable,
          availability: queryArg.availability,
          shipper_ids: queryArg.shipperIds,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          shipped_after_datetime: queryArg.shippedAfterDatetime,
          shipped_before_datetime: queryArg.shippedBeforeDatetime,
          shipping_carrier: queryArg.shippingCarrier,
          expected_shipped_at_after_datetime: queryArg.expectedShippedAtAfterDatetime,
          expected_shipped_at_before_datetime: queryArg.expectedShippedAtBeforeDatetime,
          must_ship_by_datetime: queryArg.mustShipByDatetime,
          skus: queryArg.skus,
          tracking_number: queryArg.trackingNumber,
          search: queryArg.search,
          pick_strategy: queryArg.pickStrategy,
          pack_strategy: queryArg.packStrategy,
          assigned_user_id: queryArg.assignedUserId,
          tags: queryArg.tags,
          product_ids: queryArg.productIds,
          channel_name: queryArg.channelName,
          channel_names: queryArg.channelNames,
          channel_categories: queryArg.channelCategories,
          order_types: queryArg.orderTypes,
          requested_lot_number: queryArg.requestedLotNumber,
          picked_lot_number: queryArg.pickedLotNumber,
          picked_from_lpn: queryArg.pickedFromLpn,
          packed_from_lpn: queryArg.packedFromLpn,
          serial_numbers: queryArg.serialNumbers,
          serial_number_id: queryArg.serialNumberId,
          license_plate_ids: queryArg.licensePlateIds,
          has_unresolved_exception: queryArg.hasUnresolvedException,
          priority: queryArg.priority,
          priorities: queryArg.priorities,
          externally_created_after_datetime: queryArg.externallyCreatedAfterDatetime,
          externally_created_before_datetime: queryArg.externallyCreatedBeforeDatetime,
          number_of_lines: queryArg.numberOfLines,
          order_line_total: queryArg.orderLineTotal,
          order_line_total_quantity: queryArg.orderLineTotalQuantity,
          is_streamed: queryArg.isStreamed,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postOutbounds: build.mutation<PostOutboundsApiResponse, PostOutboundsApiArg>({
      query: (queryArg) => ({ url: `/outbounds`, method: 'POST', body: queryArg.body }),
    }),
    getOutboundsDownload: build.query<GetOutboundsDownloadApiResponse, GetOutboundsDownloadApiArg>({
      query: (queryArg) => ({
        url: `/outbounds/download`,
        params: {
          status: queryArg.status,
          shipment_type: queryArg.shipmentType,
          product_id: queryArg.productId,
          location_id: queryArg.locationId,
          outbound_ids: queryArg.outboundIds,
          item_ids: queryArg.itemIds,
          fulfillable: queryArg.fulfillable,
          shipper_ids: queryArg.shipperIds,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          shipped_after_datetime: queryArg.shippedAfterDatetime,
          shipped_before_datetime: queryArg.shippedBeforeDatetime,
          shipping_carrier: queryArg.shippingCarrier,
          expected_shipped_at_after_datetime: queryArg.expectedShippedAtAfterDatetime,
          expected_shipped_at_before_datetime: queryArg.expectedShippedAtBeforeDatetime,
          must_ship_by_datetime: queryArg.mustShipByDatetime,
          skus: queryArg.skus,
          tracking_number: queryArg.trackingNumber,
          search: queryArg.search,
          tags: queryArg.tags,
          product_ids: queryArg.productIds,
          channel_name: queryArg.channelName,
          channel_names: queryArg.channelNames,
          channel_categories: queryArg.channelCategories,
          order_types: queryArg.orderTypes,
          requested_lot_number: queryArg.requestedLotNumber,
          picked_lot_number: queryArg.pickedLotNumber,
          picked_from_lpn: queryArg.pickedFromLpn,
          packed_from_lpn: queryArg.packedFromLpn,
          serial_numbers: queryArg.serialNumbers,
          serial_number_id: queryArg.serialNumberId,
          license_plate_ids: queryArg.licensePlateIds,
          has_unresolved_exception: queryArg.hasUnresolvedException,
          priority: queryArg.priority,
          priorities: queryArg.priorities,
          externally_created_after_datetime: queryArg.externallyCreatedAfterDatetime,
          externally_created_before_datetime: queryArg.externallyCreatedBeforeDatetime,
          number_of_lines: queryArg.numberOfLines,
          order_line_total: queryArg.orderLineTotal,
          order_line_total_quantity: queryArg.orderLineTotalQuantity,
          is_streamed: queryArg.isStreamed,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getOutboundsByOrderId: build.query<
      GetOutboundsByOrderIdApiResponse,
      GetOutboundsByOrderIdApiArg
    >({
      query: (queryArg) => ({ url: `/outbounds/${queryArg.orderId}` }),
    }),
    patchOutboundsByOrderId: build.mutation<
      PatchOutboundsByOrderIdApiResponse,
      PatchOutboundsByOrderIdApiArg
    >({
      query: (queryArg) => ({
        url: `/outbounds/${queryArg.orderId}`,
        method: 'PATCH',
        body: queryArg.outboundOrder,
      }),
    }),
    deleteOutboundsByOrderId: build.mutation<
      DeleteOutboundsByOrderIdApiResponse,
      DeleteOutboundsByOrderIdApiArg
    >({
      query: (queryArg) => ({ url: `/outbounds/${queryArg.orderId}`, method: 'DELETE' }),
    }),
    postOutboundsByOrderIdShipments: build.mutation<
      PostOutboundsByOrderIdShipmentsApiResponse,
      PostOutboundsByOrderIdShipmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/outbounds/${queryArg.orderId}/shipments`,
        method: 'POST',
        body: queryArg.shipment,
      }),
    }),
    patchOutboundsByOrderIdShipmentsAndShipmentId: build.mutation<
      PatchOutboundsByOrderIdShipmentsAndShipmentIdApiResponse,
      PatchOutboundsByOrderIdShipmentsAndShipmentIdApiArg
    >({
      query: (queryArg) => ({
        url: `/outbounds/${queryArg.orderId}/shipments/${queryArg.shipmentId}`,
        method: 'PATCH',
        body: queryArg.shipment,
      }),
    }),
    getShippersByShipperNameOutboundsAndOrderNumber: build.query<
      GetShippersByShipperNameOutboundsAndOrderNumberApiResponse,
      GetShippersByShipperNameOutboundsAndOrderNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperName}/outbounds/${queryArg.orderNumber}`,
      }),
    }),
    patchShippersByShipperNameOutboundsAndOrderNumber: build.mutation<
      PatchShippersByShipperNameOutboundsAndOrderNumberApiResponse,
      PatchShippersByShipperNameOutboundsAndOrderNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperName}/outbounds/${queryArg.orderNumber}`,
        method: 'PATCH',
        body: queryArg.outboundOrder,
      }),
    }),
    deleteShippersByShipperNameOutboundsAndOrderNumber: build.mutation<
      DeleteShippersByShipperNameOutboundsAndOrderNumberApiResponse,
      DeleteShippersByShipperNameOutboundsAndOrderNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperName}/outbounds/${queryArg.orderNumber}`,
        method: 'DELETE',
      }),
    }),
    postOutboundsStage: build.mutation<PostOutboundsStageApiResponse, PostOutboundsStageApiArg>({
      query: (queryArg) => ({ url: `/outbounds/stage`, method: 'POST', body: queryArg.body }),
    }),
    getOutboundsByOrderIdLines: build.query<
      GetOutboundsByOrderIdLinesApiResponse,
      GetOutboundsByOrderIdLinesApiArg
    >({
      query: (queryArg) => ({
        url: `/outbounds/${queryArg.orderId}/lines`,
        params: { page: queryArg.page, page_size: queryArg.pageSize, sort: queryArg.sort },
      }),
    }),
    getShippersByShipperNameOutboundsAndExternalIdLines: build.query<
      GetShippersByShipperNameOutboundsAndExternalIdLinesApiResponse,
      GetShippersByShipperNameOutboundsAndExternalIdLinesApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperName}/outbounds/${queryArg.externalId}/lines`,
        params: { page: queryArg.page, page_size: queryArg.pageSize, sort: queryArg.sort },
      }),
    }),
    postShippersByShipperNameOutboundsAndExternalIdLines: build.mutation<
      PostShippersByShipperNameOutboundsAndExternalIdLinesApiResponse,
      PostShippersByShipperNameOutboundsAndExternalIdLinesApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperName}/outbounds/${queryArg.externalId}/lines`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getOutboundsLineItems: build.query<
      GetOutboundsLineItemsApiResponse,
      GetOutboundsLineItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/outbounds/line-items`,
        params: {
          batch_id: queryArg.batchId,
          location_ids: queryArg.locationIds,
          outbound_id: queryArg.outboundId,
          product_id: queryArg.productId,
          outbound_line_id: queryArg.outboundLineId,
          item_ids: queryArg.itemIds,
          license_plate_ids: queryArg.licensePlateIds,
          pick_ids: queryArg.pickIds,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getOutboundsByOrderIdLineItems: build.query<
      GetOutboundsByOrderIdLineItemsApiResponse,
      GetOutboundsByOrderIdLineItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/outbounds/${queryArg.orderId}/line-items`,
        params: {
          location_ids: queryArg.locationIds,
          outbound_line_id: queryArg.outboundLineId,
          item_ids: queryArg.itemIds,
          pick_ids: queryArg.pickIds,
          license_plate_ids: queryArg.licensePlateIds,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postOutboundsByOrderIdLinesAndOutboundLineIdPick: build.mutation<
      PostOutboundsByOrderIdLinesAndOutboundLineIdPickApiResponse,
      PostOutboundsByOrderIdLinesAndOutboundLineIdPickApiArg
    >({
      query: (queryArg) => ({
        url: `/outbounds/${queryArg.orderId}/lines/${queryArg.outboundLineId}/pick`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postOutboundsByOrderIdRepublish: build.mutation<
      PostOutboundsByOrderIdRepublishApiResponse,
      PostOutboundsByOrderIdRepublishApiArg
    >({
      query: (queryArg) => ({ url: `/outbounds/${queryArg.orderId}/republish`, method: 'POST' }),
    }),
    postOutboundsUnpick: build.mutation<PostOutboundsUnpickApiResponse, PostOutboundsUnpickApiArg>({
      query: (queryArg) => ({ url: `/outbounds/unpick`, method: 'POST', body: queryArg.body }),
    }),
    postOutboundsUnbatch: build.mutation<
      PostOutboundsUnbatchApiResponse,
      PostOutboundsUnbatchApiArg
    >({
      query: (queryArg) => ({ url: `/outbounds/unbatch`, method: 'POST', body: queryArg.body }),
    }),
    getOutboundsChannelNames: build.query<
      GetOutboundsChannelNamesApiResponse,
      GetOutboundsChannelNamesApiArg
    >({
      query: (queryArg) => ({
        url: `/outbounds/channel-names`,
        params: { shipper_ids: queryArg.shipperIds },
      }),
    }),
    getOutboundsShippingCarriers: build.query<
      GetOutboundsShippingCarriersApiResponse,
      GetOutboundsShippingCarriersApiArg
    >({
      query: (queryArg) => ({
        url: `/outbounds/shipping-carriers`,
        params: { shipper_ids: queryArg.shipperIds },
      }),
    }),
    getOutboundsTags: build.query<GetOutboundsTagsApiResponse, GetOutboundsTagsApiArg>({
      query: (queryArg) => ({
        url: `/outbounds/tags`,
        params: { shipper_ids: queryArg.shipperIds },
      }),
    }),
    getPicks: build.query<GetPicksApiResponse, GetPicksApiArg>({
      query: (queryArg) => ({
        url: `/picks`,
        params: {
          location_ids: queryArg.locationIds,
          product_ids: queryArg.productIds,
          is_completed: queryArg.isCompleted,
          pick_path_location_id: queryArg.pickPathLocationId,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getPickEquipmentGroups: build.query<
      GetPickEquipmentGroupsApiResponse,
      GetPickEquipmentGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/pick-equipment-groups`,
        params: { shipper_id: queryArg.shipperId, search: queryArg.search, sort: queryArg.sort },
      }),
    }),
    postPickEquipmentGroups: build.mutation<
      PostPickEquipmentGroupsApiResponse,
      PostPickEquipmentGroupsApiArg
    >({
      query: (queryArg) => ({ url: `/pick-equipment-groups`, method: 'POST', body: queryArg.body }),
    }),
    deletePickEquipmentGroupsByPickEquipmentGroupId: build.mutation<
      DeletePickEquipmentGroupsByPickEquipmentGroupIdApiResponse,
      DeletePickEquipmentGroupsByPickEquipmentGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/pick-equipment-groups/${queryArg.pickEquipmentGroupId}`,
        method: 'DELETE',
      }),
    }),
    getPickEquipmentGroupsByPickEquipmentGroupId: build.query<
      GetPickEquipmentGroupsByPickEquipmentGroupIdApiResponse,
      GetPickEquipmentGroupsByPickEquipmentGroupIdApiArg
    >({
      query: (queryArg) => ({ url: `/pick-equipment-groups/${queryArg.pickEquipmentGroupId}` }),
    }),
    patchPickEquipmentGroupsByPickEquipmentGroupId: build.mutation<
      PatchPickEquipmentGroupsByPickEquipmentGroupIdApiResponse,
      PatchPickEquipmentGroupsByPickEquipmentGroupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/pick-equipment-groups/${queryArg.pickEquipmentGroupId}`,
        method: 'PATCH',
        body: queryArg.pickEquipmentGroup,
      }),
    }),
    patchPickByPickId: build.mutation<PatchPickByPickIdApiResponse, PatchPickByPickIdApiArg>({
      query: (queryArg) => ({
        url: `/pick/${queryArg.pickId}`,
        method: 'PATCH',
        body: queryArg.pick,
      }),
    }),
    deletePickByPickId: build.mutation<DeletePickByPickIdApiResponse, DeletePickByPickIdApiArg>({
      query: (queryArg) => ({ url: `/pick/${queryArg.pickId}`, method: 'DELETE' }),
    }),
    getProducts: build.query<GetProductsApiResponse, GetProductsApiArg>({
      query: (queryArg) => ({
        url: `/products`,
        params: {
          skus: queryArg.skus,
          product_identifier: queryArg.productIdentifier,
          lot_number: queryArg.lotNumber,
          search: queryArg.search,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          shipper_ids: queryArg.shipperIds,
          product_ids: queryArg.productIds,
          include_location_summary: queryArg.includeLocationSummary,
          kit: queryArg.kit,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postProducts: build.mutation<PostProductsApiResponse, PostProductsApiArg>({
      query: (queryArg) => ({ url: `/products`, method: 'POST', body: queryArg.body }),
    }),
    patchProducts: build.mutation<PatchProductsApiResponse, PatchProductsApiArg>({
      query: (queryArg) => ({
        url: `/products`,
        method: 'PATCH',
        body: queryArg.product,
        params: { sku: queryArg.sku },
      }),
    }),
    getProductsByProductId: build.query<
      GetProductsByProductIdApiResponse,
      GetProductsByProductIdApiArg
    >({
      query: (queryArg) => ({ url: `/products/${queryArg.productId}` }),
    }),
    deleteProductsByProductId: build.mutation<
      DeleteProductsByProductIdApiResponse,
      DeleteProductsByProductIdApiArg
    >({
      query: (queryArg) => ({ url: `/products/${queryArg.productId}`, method: 'DELETE' }),
    }),
    patchProductsBySku: build.mutation<PatchProductsBySkuApiResponse, PatchProductsBySkuApiArg>({
      query: (queryArg) => ({
        url: `/products/${queryArg.sku}`,
        method: 'PATCH',
        body: queryArg.product,
        params: { shipper_id: queryArg.shipperId },
      }),
    }),
    getShippersByShipperNameProductsAndSku: build.query<
      GetShippersByShipperNameProductsAndSkuApiResponse,
      GetShippersByShipperNameProductsAndSkuApiArg
    >({
      query: (queryArg) => ({ url: `/shippers/${queryArg.shipperName}/products/${queryArg.sku}` }),
    }),
    patchShippersByShipperNameProductsAndSku: build.mutation<
      PatchShippersByShipperNameProductsAndSkuApiResponse,
      PatchShippersByShipperNameProductsAndSkuApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperName}/products/${queryArg.sku}`,
        method: 'PATCH',
        body: queryArg.product,
      }),
    }),
    getShippersByShipperNameProductsAndSkuLotNumbers: build.query<
      GetShippersByShipperNameProductsAndSkuLotNumbersApiResponse,
      GetShippersByShipperNameProductsAndSkuLotNumbersApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperName}/products/${queryArg.sku}/lot-numbers`,
        params: {
          product_ids: queryArg.productIds,
          lot_numbers: queryArg.lotNumbers,
          approved: queryArg.approved,
          shipper_ids: queryArg.shipperIds,
          user_id: queryArg.userId,
          updated_by_user_id: queryArg.updatedByUserId,
          inserted_by_user_id: queryArg.insertedByUserId,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          updated_after_datetime: queryArg.updatedAfterDatetime,
          updated_before_datetime: queryArg.updatedBeforeDatetime,
          sort: queryArg.sort,
        },
      }),
    }),
    postShippersByShipperNameProductsAndSkuLotNumbers: build.mutation<
      PostShippersByShipperNameProductsAndSkuLotNumbersApiResponse,
      PostShippersByShipperNameProductsAndSkuLotNumbersApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperName}/products/${queryArg.sku}/lot-numbers`,
        method: 'POST',
        body: queryArg.productLotNumber,
      }),
    }),
    patchShippersByShipperNameProductsAndSkuLotNumbersLotNumber: build.mutation<
      PatchShippersByShipperNameProductsAndSkuLotNumbersLotNumberApiResponse,
      PatchShippersByShipperNameProductsAndSkuLotNumbersLotNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperName}/products/${queryArg.sku}/lot-numbers/${queryArg.lotNumber}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    getProductLotNumbers: build.query<GetProductLotNumbersApiResponse, GetProductLotNumbersApiArg>({
      query: (queryArg) => ({
        url: `/product-lot-numbers`,
        params: {
          product_ids: queryArg.productIds,
          lot_numbers: queryArg.lotNumbers,
          approved: queryArg.approved,
          shipper_ids: queryArg.shipperIds,
          user_id: queryArg.userId,
          updated_by_user_id: queryArg.updatedByUserId,
          inserted_by_user_id: queryArg.insertedByUserId,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          updated_after_datetime: queryArg.updatedAfterDatetime,
          updated_before_datetime: queryArg.updatedBeforeDatetime,
          sort: queryArg.sort,
        },
      }),
    }),
    getSerialNumbers: build.query<GetSerialNumbersApiResponse, GetSerialNumbersApiArg>({
      query: (queryArg) => ({
        url: `/serial-numbers`,
        params: {
          item_ids: queryArg.itemIds,
          numbers: queryArg.numbers,
          parcel_ids: queryArg.parcelIds,
          product_ids: queryArg.productIds,
          license_plate_numbers: queryArg.licensePlateNumbers,
          search: queryArg.search,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postSerialNumbers: build.mutation<PostSerialNumbersApiResponse, PostSerialNumbersApiArg>({
      query: (queryArg) => ({
        url: `/serial-numbers`,
        method: 'POST',
        body: queryArg.serialNumber,
      }),
    }),
    deleteSerialNumbersBySerialNumberId: build.mutation<
      DeleteSerialNumbersBySerialNumberIdApiResponse,
      DeleteSerialNumbersBySerialNumberIdApiArg
    >({
      query: (queryArg) => ({
        url: `/serial-numbers/${queryArg.serialNumberId}`,
        method: 'DELETE',
      }),
    }),
    getSerialNumbersBySerialNumberId: build.query<
      GetSerialNumbersBySerialNumberIdApiResponse,
      GetSerialNumbersBySerialNumberIdApiArg
    >({
      query: (queryArg) => ({ url: `/serial-numbers/${queryArg.serialNumberId}` }),
    }),
    getAdjustments: build.query<GetAdjustmentsApiResponse, GetAdjustmentsApiArg>({
      query: (queryArg) => ({
        url: `/adjustments`,
        params: {
          item_id: queryArg.itemId,
          item_ids: queryArg.itemIds,
          product_id: queryArg.productId,
          license_plate_id: queryArg.licensePlateId,
          search: queryArg.search,
          product_sku: queryArg.productSku,
          shipper_id: queryArg.shipperId,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          reason_code_id: queryArg.reasonCodeId,
          user_id: queryArg.userId,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getProductsByProductIdAdjustments: build.query<
      GetProductsByProductIdAdjustmentsApiResponse,
      GetProductsByProductIdAdjustmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/products/${queryArg.productId}/adjustments`,
        params: {
          item_id: queryArg.itemId,
          item_ids: queryArg.itemIds,
          license_plate_id: queryArg.licensePlateId,
          search: queryArg.search,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          reason_code_id: queryArg.reasonCodeId,
          user_id: queryArg.userId,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getShippersByShipperNameProductsAndSkuAdjustments: build.query<
      GetShippersByShipperNameProductsAndSkuAdjustmentsApiResponse,
      GetShippersByShipperNameProductsAndSkuAdjustmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperName}/products/${queryArg.sku}/adjustments`,
        params: {
          item_id: queryArg.itemId,
          item_ids: queryArg.itemIds,
          product_id: queryArg.productId,
          license_plate_id: queryArg.licensePlateId,
          search: queryArg.search,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          reason_code_id: queryArg.reasonCodeId,
          user_id: queryArg.userId,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getProductsByProductIdMovements: build.query<
      GetProductsByProductIdMovementsApiResponse,
      GetProductsByProductIdMovementsApiArg
    >({
      query: (queryArg) => ({
        url: `/products/${queryArg.productId}/movements`,
        params: {
          user_id: queryArg.userId,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getShippersByShipperNameProductsAndSkuMovements: build.query<
      GetShippersByShipperNameProductsAndSkuMovementsApiResponse,
      GetShippersByShipperNameProductsAndSkuMovementsApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperName}/products/${queryArg.sku}/movements`,
        params: {
          user_id: queryArg.userId,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getShippersByShipperNameProductsAndSkuLocationSummary: build.query<
      GetShippersByShipperNameProductsAndSkuLocationSummaryApiResponse,
      GetShippersByShipperNameProductsAndSkuLocationSummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperName}/products/${queryArg.sku}/location-summary`,
        params: { page: queryArg.page, page_size: queryArg.pageSize, sort: queryArg.sort },
      }),
    }),
    getShippersByShipperNameWorkOrdersAndNumber: build.query<
      GetShippersByShipperNameWorkOrdersAndNumberApiResponse,
      GetShippersByShipperNameWorkOrdersAndNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperName}/work-orders/${queryArg['number']}`,
        params: { page: queryArg.page, page_size: queryArg.pageSize, sort: queryArg.sort },
      }),
    }),
    getProductsByProductIdOutbounds: build.query<
      GetProductsByProductIdOutboundsApiResponse,
      GetProductsByProductIdOutboundsApiArg
    >({
      query: (queryArg) => ({
        url: `/products/${queryArg.productId}/outbounds`,
        params: { page: queryArg.page, page_size: queryArg.pageSize, sort: queryArg.sort },
      }),
    }),
    getProductsDownload: build.query<GetProductsDownloadApiResponse, GetProductsDownloadApiArg>({
      query: (queryArg) => ({
        url: `/products/download`,
        params: {
          shipper_ids: queryArg.shipperIds,
          search: queryArg.search,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          kit: queryArg.kit,
          template: queryArg.template,
        },
      }),
    }),
    postProductsUpload: build.mutation<PostProductsUploadApiResponse, PostProductsUploadApiArg>({
      query: (queryArg) => ({ url: `/products/upload`, method: 'POST', body: queryArg.body }),
    }),
    getProductsByProductIdAdjustmentsDownload: build.query<
      GetProductsByProductIdAdjustmentsDownloadApiResponse,
      GetProductsByProductIdAdjustmentsDownloadApiArg
    >({
      query: (queryArg) => ({ url: `/products/${queryArg.productId}/adjustments/download` }),
    }),
    getProductUom: build.query<GetProductUomApiResponse, GetProductUomApiArg>({
      query: (queryArg) => ({
        url: `/product-uom`,
        params: {
          product_ids: queryArg.productIds,
          unit_of_measure_ids: queryArg.unitOfMeasureIds,
          shipper_ids: queryArg.shipperIds,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getProductsUomTemplate: build.query<
      GetProductsUomTemplateApiResponse,
      GetProductsUomTemplateApiArg
    >({
      query: () => ({ url: `/products/uom/template` }),
    }),
    postProductsUomUpload: build.mutation<
      PostProductsUomUploadApiResponse,
      PostProductsUomUploadApiArg
    >({
      query: (queryArg) => ({ url: `/products/uom/upload`, method: 'POST', body: queryArg.body }),
    }),
    getProductsByProductIdUom: build.query<
      GetProductsByProductIdUomApiResponse,
      GetProductsByProductIdUomApiArg
    >({
      query: (queryArg) => ({
        url: `/products/${queryArg.productId}/uom`,
        params: {
          unit_of_measure_ids: queryArg.unitOfMeasureIds,
          shipper_ids: queryArg.shipperIds,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postProductsByProductIdUom: build.mutation<
      PostProductsByProductIdUomApiResponse,
      PostProductsByProductIdUomApiArg
    >({
      query: (queryArg) => ({
        url: `/products/${queryArg.productId}/uom`,
        method: 'POST',
        body: queryArg.productUnitOfMeasure,
      }),
    }),
    patchProductsByProductIdUomAndProductUomId: build.mutation<
      PatchProductsByProductIdUomAndProductUomIdApiResponse,
      PatchProductsByProductIdUomAndProductUomIdApiArg
    >({
      query: (queryArg) => ({
        url: `/products/${queryArg.productId}/uom/${queryArg.productUomId}`,
        method: 'PATCH',
        body: queryArg.productUnitOfMeasure,
      }),
    }),
    deleteProductsByProductIdUomAndProductUomId: build.mutation<
      DeleteProductsByProductIdUomAndProductUomIdApiResponse,
      DeleteProductsByProductIdUomAndProductUomIdApiArg
    >({
      query: (queryArg) => ({
        url: `/products/${queryArg.productId}/uom/${queryArg.productUomId}`,
        method: 'DELETE',
        params: { cascade: queryArg.cascade },
      }),
    }),
    postProductsByProductIdBarcodes: build.mutation<
      PostProductsByProductIdBarcodesApiResponse,
      PostProductsByProductIdBarcodesApiArg
    >({
      query: (queryArg) => ({
        url: `/products/${queryArg.productId}/barcodes`,
        method: 'POST',
        body: queryArg.productBarcode,
      }),
    }),
    patchProductsByProductIdBarcodesAndProductBarcodeId: build.mutation<
      PatchProductsByProductIdBarcodesAndProductBarcodeIdApiResponse,
      PatchProductsByProductIdBarcodesAndProductBarcodeIdApiArg
    >({
      query: (queryArg) => ({
        url: `/products/${queryArg.productId}/barcodes/${queryArg.productBarcodeId}`,
        method: 'PATCH',
        body: queryArg.productBarcode,
      }),
    }),
    deleteProductsByProductIdBarcodesAndProductBarcodeId: build.mutation<
      DeleteProductsByProductIdBarcodesAndProductBarcodeIdApiResponse,
      DeleteProductsByProductIdBarcodesAndProductBarcodeIdApiArg
    >({
      query: (queryArg) => ({
        url: `/products/${queryArg.productId}/barcodes/${queryArg.productBarcodeId}`,
        method: 'DELETE',
      }),
    }),
    patchUsersByUserId: build.mutation<PatchUsersByUserIdApiResponse, PatchUsersByUserIdApiArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}`,
        method: 'PATCH',
        body: queryArg.userUpdate,
      }),
    }),
    getUsersByUserId: build.query<GetUsersByUserIdApiResponse, GetUsersByUserIdApiArg>({
      query: (queryArg) => ({ url: `/users/${queryArg.userId}` }),
    }),
    deleteUsersByUserIdBlock: build.mutation<
      DeleteUsersByUserIdBlockApiResponse,
      DeleteUsersByUserIdBlockApiArg
    >({
      query: (queryArg) => ({ url: `/users/${queryArg.userId}/block`, method: 'DELETE' }),
    }),
    getMe: build.query<GetMeApiResponse, GetMeApiArg>({
      query: () => ({ url: `/me` }),
    }),
    patchMe: build.mutation<PatchMeApiResponse, PatchMeApiArg>({
      query: (queryArg) => ({ url: `/me`, method: 'PATCH', body: queryArg.body }),
    }),
    getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
      query: (queryArg) => ({
        url: `/users`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          include_stord_employees: queryArg.includeStordEmployees,
          search: queryArg.search,
          active: queryArg.active,
          role: queryArg.role,
        },
      }),
    }),
    postUsers: build.mutation<PostUsersApiResponse, PostUsersApiArg>({
      query: (queryArg) => ({ url: `/users`, method: 'POST', body: queryArg.user }),
    }),
    getUsersDownload: build.query<GetUsersDownloadApiResponse, GetUsersDownloadApiArg>({
      query: (queryArg) => ({
        url: `/users/download`,
        params: {
          include_stord_employees: queryArg.includeStordEmployees,
          search: queryArg.search,
          active: queryArg.active,
          role: queryArg.role,
        },
      }),
    }),
    getBuildings: build.query<GetBuildingsApiResponse, GetBuildingsApiArg>({
      query: (queryArg) => ({
        url: `/buildings`,
        params: {
          name: queryArg.name,
          building_ids: queryArg.buildingIds,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postBuildings: build.mutation<PostBuildingsApiResponse, PostBuildingsApiArg>({
      query: (queryArg) => ({ url: `/buildings`, method: 'POST', body: queryArg.body }),
    }),
    getBuildingsByBuildingId: build.query<
      GetBuildingsByBuildingIdApiResponse,
      GetBuildingsByBuildingIdApiArg
    >({
      query: (queryArg) => ({ url: `/buildings/${queryArg.buildingId}` }),
    }),
    patchBuildingsByBuildingId: build.mutation<
      PatchBuildingsByBuildingIdApiResponse,
      PatchBuildingsByBuildingIdApiArg
    >({
      query: (queryArg) => ({
        url: `/buildings/${queryArg.buildingId}`,
        method: 'PATCH',
        body: queryArg.building,
      }),
    }),
    postBuildingsByBuildingIdInviaSync: build.mutation<
      PostBuildingsByBuildingIdInviaSyncApiResponse,
      PostBuildingsByBuildingIdInviaSyncApiArg
    >({
      query: (queryArg) => ({
        url: `/buildings/${queryArg.buildingId}/invia-sync`,
        method: 'POST',
      }),
    }),
    getPackingSlipTemplates: build.query<
      GetPackingSlipTemplatesApiResponse,
      GetPackingSlipTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/packing-slip-templates`,
        params: {
          default: queryArg['default'],
          shipper_id: queryArg.shipperId,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postPackingSlipTemplates: build.mutation<
      PostPackingSlipTemplatesApiResponse,
      PostPackingSlipTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/packing-slip-templates`,
        method: 'POST',
        body: queryArg.packingSlipTemplate,
      }),
    }),
    getPackingSlipTemplatesById: build.query<
      GetPackingSlipTemplatesByIdApiResponse,
      GetPackingSlipTemplatesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/packing-slip-templates/${queryArg.id}` }),
    }),
    patchPackingSlipTemplatesById: build.mutation<
      PatchPackingSlipTemplatesByIdApiResponse,
      PatchPackingSlipTemplatesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/packing-slip-templates/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.packingSlipTemplate,
      }),
    }),
    deletePackingSlipTemplatesById: build.mutation<
      DeletePackingSlipTemplatesByIdApiResponse,
      DeletePackingSlipTemplatesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/packing-slip-templates/${queryArg.id}`, method: 'DELETE' }),
    }),
    getPackingSlipTemplatesByIdLogo: build.query<
      GetPackingSlipTemplatesByIdLogoApiResponse,
      GetPackingSlipTemplatesByIdLogoApiArg
    >({
      query: (queryArg) => ({ url: `/packing-slip-templates/${queryArg.id}/logo` }),
    }),
    postPackingSlipTemplatesByIdLogo: build.mutation<
      PostPackingSlipTemplatesByIdLogoApiResponse,
      PostPackingSlipTemplatesByIdLogoApiArg
    >({
      query: (queryArg) => ({
        url: `/packing-slip-templates/${queryArg.id}/logo`,
        method: 'POST',
        body: queryArg.attachment,
      }),
    }),
    deletePackingSlipTemplatesByIdLogo: build.mutation<
      DeletePackingSlipTemplatesByIdLogoApiResponse,
      DeletePackingSlipTemplatesByIdLogoApiArg
    >({
      query: (queryArg) => ({
        url: `/packing-slip-templates/${queryArg.id}/logo`,
        method: 'DELETE',
      }),
    }),
    getPackingSlipTemplatesByPackingSlipTemplateIdChannels: build.query<
      GetPackingSlipTemplatesByPackingSlipTemplateIdChannelsApiResponse,
      GetPackingSlipTemplatesByPackingSlipTemplateIdChannelsApiArg
    >({
      query: (queryArg) => ({
        url: `/packing-slip-templates/${queryArg.packingSlipTemplateId}/channels`,
      }),
    }),
    postPackingSlipTemplatesByPackingSlipTemplateIdChannels: build.mutation<
      PostPackingSlipTemplatesByPackingSlipTemplateIdChannelsApiResponse,
      PostPackingSlipTemplatesByPackingSlipTemplateIdChannelsApiArg
    >({
      query: (queryArg) => ({
        url: `/packing-slip-templates/${queryArg.packingSlipTemplateId}/channels`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    deletePackingSlipTemplatesByPackingSlipTemplateIdChannelsAndId: build.mutation<
      DeletePackingSlipTemplatesByPackingSlipTemplateIdChannelsAndIdApiResponse,
      DeletePackingSlipTemplatesByPackingSlipTemplateIdChannelsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/packing-slip-templates/${queryArg.packingSlipTemplateId}/channels/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getCycleCounts: build.query<GetCycleCountsApiResponse, GetCycleCountsApiArg>({
      query: (queryArg) => ({
        url: `/cycle-counts`,
        params: {
          status: queryArg.status,
          shipper_ids: queryArg.shipperIds,
          available_for_user_id: queryArg.availableForUserId,
          created_user_id: queryArg.createdUserId,
          assigned_user_id: queryArg.assignedUserId,
          reviewed_by_user_id: queryArg.reviewedByUserId,
          canceled_by_user_id: queryArg.canceledByUserId,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          started_before_datetime: queryArg.startedBeforeDatetime,
          started_after_datetime: queryArg.startedAfterDatetime,
          submitted_before_datetime: queryArg.submittedBeforeDatetime,
          submitted_after_datetime: queryArg.submittedAfterDatetime,
          completed_before_datetime: queryArg.completedBeforeDatetime,
          completed_after_datetime: queryArg.completedAfterDatetime,
          canceled_before_datetime: queryArg.canceledBeforeDatetime,
          canceled_after_datetime: queryArg.canceledAfterDatetime,
          adjustment_outcome: queryArg.adjustmentOutcome,
          location_id: queryArg.locationId,
          license_plate_id: queryArg.licensePlateId,
          product_id: queryArg.productId,
          has_unresolved_exception: queryArg.hasUnresolvedException,
          search: queryArg.search,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postCycleCounts: build.mutation<PostCycleCountsApiResponse, PostCycleCountsApiArg>({
      query: (queryArg) => ({ url: `/cycle-counts`, method: 'POST', body: queryArg.body }),
    }),
    postCycleCountsBulk: build.mutation<PostCycleCountsBulkApiResponse, PostCycleCountsBulkApiArg>({
      query: (queryArg) => ({ url: `/cycle-counts/bulk`, method: 'POST', body: queryArg.body }),
    }),
    getCycleCountsByCycleCountId: build.query<
      GetCycleCountsByCycleCountIdApiResponse,
      GetCycleCountsByCycleCountIdApiArg
    >({
      query: (queryArg) => ({ url: `/cycle-counts/${queryArg.cycleCountId}` }),
    }),
    patchCycleCountsByCycleCountId: build.mutation<
      PatchCycleCountsByCycleCountIdApiResponse,
      PatchCycleCountsByCycleCountIdApiArg
    >({
      query: (queryArg) => ({
        url: `/cycle-counts/${queryArg.cycleCountId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    getCycleCountsByCycleCountIdLocationsAndCycleCountLocationId: build.query<
      GetCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdApiResponse,
      GetCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/cycle-counts/${queryArg.cycleCountId}/locations/${queryArg.cycleCountLocationId}`,
      }),
    }),
    postCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdResults: build.mutation<
      PostCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdResultsApiResponse,
      PostCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdResultsApiArg
    >({
      query: (queryArg) => ({
        url: `/cycle-counts/${queryArg.cycleCountId}/locations/${queryArg.cycleCountLocationId}/results`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    patchCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdResultsCycleCountResultId:
      build.mutation<
        PatchCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdResultsCycleCountResultIdApiResponse,
        PatchCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdResultsCycleCountResultIdApiArg
      >({
        query: (queryArg) => ({
          url: `/cycle-counts/${queryArg.cycleCountId}/locations/${queryArg.cycleCountLocationId}/results/${queryArg.cycleCountResultId}`,
          method: 'PATCH',
          body: queryArg.cycleCountResult,
        }),
      }),
    deleteCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdResultsCycleCountResultId:
      build.mutation<
        DeleteCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdResultsCycleCountResultIdApiResponse,
        DeleteCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdResultsCycleCountResultIdApiArg
      >({
        query: (queryArg) => ({
          url: `/cycle-counts/${queryArg.cycleCountId}/locations/${queryArg.cycleCountLocationId}/results/${queryArg.cycleCountResultId}`,
          method: 'DELETE',
        }),
      }),
    patchCycleCountsAssign: build.mutation<
      PatchCycleCountsAssignApiResponse,
      PatchCycleCountsAssignApiArg
    >({
      query: (queryArg) => ({ url: `/cycle-counts/assign`, method: 'PATCH', body: queryArg.body }),
    }),
    postOutboundsShip: build.mutation<PostOutboundsShipApiResponse, PostOutboundsShipApiArg>({
      query: (queryArg) => ({ url: `/outbounds/ship`, method: 'POST', body: queryArg.body }),
    }),
    getCycleCountExceptions: build.query<
      GetCycleCountExceptionsApiResponse,
      GetCycleCountExceptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/cycle-count-exceptions`,
        params: {
          categories: queryArg.categories,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          subcategories: queryArg.subcategories,
          cycle_count_ids: queryArg.cycleCountIds,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postCycleCountExceptions: build.mutation<
      PostCycleCountExceptionsApiResponse,
      PostCycleCountExceptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/cycle-count-exceptions`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getCycleCountExceptionsById: build.query<
      GetCycleCountExceptionsByIdApiResponse,
      GetCycleCountExceptionsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/cycle-count-exceptions/${queryArg.id}` }),
    }),
    postShippersByShipperIdOutboundsShip: build.mutation<
      PostShippersByShipperIdOutboundsShipApiResponse,
      PostShippersByShipperIdOutboundsShipApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperId}/outbounds/ship`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getLicensePlates: build.query<GetLicensePlatesApiResponse, GetLicensePlatesApiArg>({
      query: (queryArg) => ({
        url: `/license-plates`,
        params: {
          location_ids: queryArg.locationIds,
          product_ids: queryArg.productIds,
          license_plate_ids: queryArg.licensePlateIds,
          item_ids: queryArg.itemIds,
          shipper_ids: queryArg.shipperIds,
          active: queryArg.active,
          lot_number: queryArg.lotNumber,
          search: queryArg.search,
          license_plate_numbers: queryArg.licensePlateNumbers,
          location_types: queryArg.locationTypes,
          location_subtypes: queryArg.locationSubtypes,
          skus: queryArg.skus,
          empty: queryArg.empty,
          locked: queryArg.locked,
          inbound_ids: queryArg.inboundIds,
          outbound_ids: queryArg.outboundIds,
          replica: queryArg.replica,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postLicensePlatesLock: build.mutation<
      PostLicensePlatesLockApiResponse,
      PostLicensePlatesLockApiArg
    >({
      query: (queryArg) => ({ url: `/license-plates/lock`, method: 'POST', body: queryArg.body }),
    }),
    postLicensePlatesUnlock: build.mutation<
      PostLicensePlatesUnlockApiResponse,
      PostLicensePlatesUnlockApiArg
    >({
      query: (queryArg) => ({ url: `/license-plates/unlock`, method: 'POST', body: queryArg.body }),
    }),
    getLicensePlatesByLicensePlateId: build.query<
      GetLicensePlatesByLicensePlateIdApiResponse,
      GetLicensePlatesByLicensePlateIdApiArg
    >({
      query: (queryArg) => ({ url: `/license-plates/${queryArg.licensePlateId}` }),
    }),
    patchLicensePlatesByLicensePlateId: build.mutation<
      PatchLicensePlatesByLicensePlateIdApiResponse,
      PatchLicensePlatesByLicensePlateIdApiArg
    >({
      query: (queryArg) => ({
        url: `/license-plates/${queryArg.licensePlateId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    putLicensePlatesByLicensePlateIdMove: build.mutation<
      PutLicensePlatesByLicensePlateIdMoveApiResponse,
      PutLicensePlatesByLicensePlateIdMoveApiArg
    >({
      query: (queryArg) => ({
        url: `/license-plates/${queryArg.licensePlateId}/move`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getLicensePlatesByLicensePlateIdMovements: build.query<
      GetLicensePlatesByLicensePlateIdMovementsApiResponse,
      GetLicensePlatesByLicensePlateIdMovementsApiArg
    >({
      query: (queryArg) => ({
        url: `/license-plates/${queryArg.licensePlateId}/movements`,
        params: {
          user_id: queryArg.userId,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getLicensePlatesByLicensePlateIdAdjustments: build.query<
      GetLicensePlatesByLicensePlateIdAdjustmentsApiResponse,
      GetLicensePlatesByLicensePlateIdAdjustmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/license-plates/${queryArg.licensePlateId}/adjustments`,
        params: { page: queryArg.page, page_size: queryArg.pageSize, sort: queryArg.sort },
      }),
    }),
    postLicensePlatesByLicensePlateIdSplit: build.mutation<
      PostLicensePlatesByLicensePlateIdSplitApiResponse,
      PostLicensePlatesByLicensePlateIdSplitApiArg
    >({
      query: (queryArg) => ({
        url: `/license-plates/${queryArg.licensePlateId}/split`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postLicensePlatesByLicensePlateIdTransfer: build.mutation<
      PostLicensePlatesByLicensePlateIdTransferApiResponse,
      PostLicensePlatesByLicensePlateIdTransferApiArg
    >({
      query: (queryArg) => ({
        url: `/license-plates/${queryArg.licensePlateId}/transfer`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postLicensePlatesByLicensePlateIdReplace: build.mutation<
      PostLicensePlatesByLicensePlateIdReplaceApiResponse,
      PostLicensePlatesByLicensePlateIdReplaceApiArg
    >({
      query: (queryArg) => ({
        url: `/license-plates/${queryArg.licensePlateId}/replace`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getLicensePlatesNumberByLicensePlateNumber: build.query<
      GetLicensePlatesNumberByLicensePlateNumberApiResponse,
      GetLicensePlatesNumberByLicensePlateNumberApiArg
    >({
      query: (queryArg) => ({ url: `/license-plates/number/${queryArg.licensePlateNumber}` }),
    }),
    getUom: build.query<GetUomApiResponse, GetUomApiArg>({
      query: (queryArg) => ({
        url: `/uom`,
        params: {
          abbreviation: queryArg.abbreviation,
          name: queryArg.name,
          shipper_ids: queryArg.shipperIds,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postUom: build.mutation<PostUomApiResponse, PostUomApiArg>({
      query: (queryArg) => ({ url: `/uom`, method: 'POST', body: queryArg.unitOfMeasure }),
    }),
    patchUomByUomId: build.mutation<PatchUomByUomIdApiResponse, PatchUomByUomIdApiArg>({
      query: (queryArg) => ({
        url: `/uom/${queryArg.uomId}`,
        method: 'PATCH',
        body: queryArg.unitOfMeasure,
      }),
    }),
    deleteUomByUomId: build.mutation<DeleteUomByUomIdApiResponse, DeleteUomByUomIdApiArg>({
      query: (queryArg) => ({ url: `/uom/${queryArg.uomId}`, method: 'DELETE' }),
    }),
    postKits: build.mutation<PostKitsApiResponse, PostKitsApiArg>({
      query: (queryArg) => ({ url: `/kits`, method: 'POST', body: queryArg.body }),
    }),
    getKits: build.query<GetKitsApiResponse, GetKitsApiArg>({
      query: () => ({ url: `/kits` }),
    }),
    getKitsByKitId: build.query<GetKitsByKitIdApiResponse, GetKitsByKitIdApiArg>({
      query: (queryArg) => ({ url: `/kits/${queryArg.kitId}` }),
    }),
    deleteKitsByKitId: build.mutation<DeleteKitsByKitIdApiResponse, DeleteKitsByKitIdApiArg>({
      query: (queryArg) => ({ url: `/kits/${queryArg.kitId}`, method: 'DELETE' }),
    }),
    patchKitsByKitId: build.mutation<PatchKitsByKitIdApiResponse, PatchKitsByKitIdApiArg>({
      query: (queryArg) => ({
        url: `/kits/${queryArg.kitId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    getKitsByKitIdAttachments: build.query<
      GetKitsByKitIdAttachmentsApiResponse,
      GetKitsByKitIdAttachmentsApiArg
    >({
      query: (queryArg) => ({ url: `/kits/${queryArg.kitId}/attachments` }),
    }),
    postKitsByKitIdAttachments: build.mutation<
      PostKitsByKitIdAttachmentsApiResponse,
      PostKitsByKitIdAttachmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/kits/${queryArg.kitId}/attachments`,
        method: 'POST',
        body: queryArg.attachment,
      }),
    }),
    getShippers: build.query<GetShippersApiResponse, GetShippersApiArg>({
      query: (queryArg) => ({
        url: `/shippers`,
        params: {
          active: queryArg.active,
          invia_enabled: queryArg.inviaEnabled,
          name: queryArg.name,
          names: queryArg.names,
          shipper_ids: queryArg.shipperIds,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postShippers: build.mutation<PostShippersApiResponse, PostShippersApiArg>({
      query: (queryArg) => ({ url: `/shippers`, method: 'POST', body: queryArg.shipper }),
    }),
    getShippersByShipperId: build.query<
      GetShippersByShipperIdApiResponse,
      GetShippersByShipperIdApiArg
    >({
      query: (queryArg) => ({ url: `/shippers/${queryArg.shipperId}` }),
    }),
    deleteShippersByShipperId: build.mutation<
      DeleteShippersByShipperIdApiResponse,
      DeleteShippersByShipperIdApiArg
    >({
      query: (queryArg) => ({ url: `/shippers/${queryArg.shipperId}`, method: 'DELETE' }),
    }),
    patchShippersByShipperId: build.mutation<
      PatchShippersByShipperIdApiResponse,
      PatchShippersByShipperIdApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperId}`,
        method: 'PATCH',
        body: queryArg.shipper,
      }),
    }),
    getShippersAll: build.query<GetShippersAllApiResponse, GetShippersAllApiArg>({
      query: (queryArg) => ({
        url: `/shippers/all`,
        params: {
          name: queryArg.name,
          shipper_ids: queryArg.shipperIds,
          invia_enabled: queryArg.inviaEnabled,
          active: queryArg.active,
          building_ids: queryArg.buildingIds,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    getShippersByShipperIdConfig: build.query<
      GetShippersByShipperIdConfigApiResponse,
      GetShippersByShipperIdConfigApiArg
    >({
      query: (queryArg) => ({ url: `/shippers/${queryArg.shipperId}/config` }),
    }),
    postShippersByShipperIdConfig: build.mutation<
      PostShippersByShipperIdConfigApiResponse,
      PostShippersByShipperIdConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperId}/config`,
        method: 'POST',
        body: queryArg.shipperConfig,
      }),
    }),
    patchShippersByShipperIdConfig: build.mutation<
      PatchShippersByShipperIdConfigApiResponse,
      PatchShippersByShipperIdConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperId}/config`,
        method: 'PATCH',
        body: queryArg.shipperConfig,
      }),
    }),
    patchShippersByShipperIdConfigPremiumFeatures: build.mutation<
      PatchShippersByShipperIdConfigPremiumFeaturesApiResponse,
      PatchShippersByShipperIdConfigPremiumFeaturesApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperId}/config/premium-features`,
        method: 'PATCH',
        body: queryArg.shipperConfigPremiumFeatures,
      }),
    }),
    patchShippersByShipperIdConfigDeveloperFeatures: build.mutation<
      PatchShippersByShipperIdConfigDeveloperFeaturesApiResponse,
      PatchShippersByShipperIdConfigDeveloperFeaturesApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperId}/config/developer-features`,
        method: 'PATCH',
        body: queryArg.shipperConfigDeveloperFeatures,
      }),
    }),
    getShippersByShipperIdConfigPackingSlipLogo: build.query<
      GetShippersByShipperIdConfigPackingSlipLogoApiResponse,
      GetShippersByShipperIdConfigPackingSlipLogoApiArg
    >({
      query: (queryArg) => ({ url: `/shippers/${queryArg.shipperId}/config/packing-slip-logo` }),
    }),
    postShippersByShipperIdConfigPackingSlipLogo: build.mutation<
      PostShippersByShipperIdConfigPackingSlipLogoApiResponse,
      PostShippersByShipperIdConfigPackingSlipLogoApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperId}/config/packing-slip-logo`,
        method: 'POST',
        body: queryArg.attachment,
      }),
    }),
    deleteShippersByShipperIdConfigPackingSlipLogo: build.mutation<
      DeleteShippersByShipperIdConfigPackingSlipLogoApiResponse,
      DeleteShippersByShipperIdConfigPackingSlipLogoApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperId}/config/packing-slip-logo`,
        method: 'DELETE',
      }),
    }),
    getShippersByShipperIdAddresses: build.query<
      GetShippersByShipperIdAddressesApiResponse,
      GetShippersByShipperIdAddressesApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperId}/addresses`,
        params: {
          search: queryArg.search,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postShippersByShipperIdAddresses: build.mutation<
      PostShippersByShipperIdAddressesApiResponse,
      PostShippersByShipperIdAddressesApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperId}/addresses`,
        method: 'POST',
        body: queryArg.shipperAddress,
      }),
    }),
    patchShippersByShipperIdAddressesAndShipperAddressId: build.mutation<
      PatchShippersByShipperIdAddressesAndShipperAddressIdApiResponse,
      PatchShippersByShipperIdAddressesAndShipperAddressIdApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperId}/addresses/${queryArg.shipperAddressId}`,
        method: 'PATCH',
        body: queryArg.shipperAddress,
      }),
    }),
    deleteShippersByShipperIdAddressesAndShipperAddressId: build.mutation<
      DeleteShippersByShipperIdAddressesAndShipperAddressIdApiResponse,
      DeleteShippersByShipperIdAddressesAndShipperAddressIdApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperId}/addresses/${queryArg.shipperAddressId}`,
        method: 'DELETE',
      }),
    }),
    getShippersByShipperIdSpecialInstructions: build.query<
      GetShippersByShipperIdSpecialInstructionsApiResponse,
      GetShippersByShipperIdSpecialInstructionsApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperId}/special-instructions`,
        params: {
          default: queryArg['default'],
          search: queryArg.search,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postShippersByShipperIdSpecialInstructions: build.mutation<
      PostShippersByShipperIdSpecialInstructionsApiResponse,
      PostShippersByShipperIdSpecialInstructionsApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperId}/special-instructions`,
        method: 'POST',
        body: queryArg.specialInstruction,
      }),
    }),
    patchShippersByShipperIdSpecialInstructionsAndSpecialInstructionId: build.mutation<
      PatchShippersByShipperIdSpecialInstructionsAndSpecialInstructionIdApiResponse,
      PatchShippersByShipperIdSpecialInstructionsAndSpecialInstructionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperId}/special-instructions/${queryArg.specialInstructionId}`,
        method: 'PATCH',
        body: queryArg.specialInstruction,
      }),
    }),
    deleteShippersByShipperIdSpecialInstructionsAndSpecialInstructionId: build.mutation<
      DeleteShippersByShipperIdSpecialInstructionsAndSpecialInstructionIdApiResponse,
      DeleteShippersByShipperIdSpecialInstructionsAndSpecialInstructionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperId}/special-instructions/${queryArg.specialInstructionId}`,
        method: 'DELETE',
      }),
    }),
    postShippersByShipperIdObfuscate: build.mutation<
      PostShippersByShipperIdObfuscateApiResponse,
      PostShippersByShipperIdObfuscateApiArg
    >({
      query: (queryArg) => ({ url: `/shippers/${queryArg.shipperId}/obfuscate`, method: 'POST' }),
    }),
    postShippersByShipperIdPurgeInventory: build.mutation<
      PostShippersByShipperIdPurgeInventoryApiResponse,
      PostShippersByShipperIdPurgeInventoryApiArg
    >({
      query: (queryArg) => ({
        url: `/shippers/${queryArg.shipperId}/purge-inventory`,
        method: 'POST',
      }),
    }),
    getShipperConfigs: build.query<GetShipperConfigsApiResponse, GetShipperConfigsApiArg>({
      query: (queryArg) => ({
        url: `/shipper-configs`,
        params: {
          shipper_ids: queryArg.shipperIds,
          page: queryArg.page,
          page_size: queryArg.pageSize,
        },
      }),
    }),
    getAutomationConfigs: build.query<GetAutomationConfigsApiResponse, GetAutomationConfigsApiArg>({
      query: (queryArg) => ({
        url: `/automation-configs`,
        params: {
          shipper_ids: queryArg.shipperIds,
          active: queryArg.active,
          category: queryArg.category,
          name: queryArg.name,
          automation_config_ids: queryArg.automationConfigIds,
          pick_equipment_group_ids: queryArg.pickEquipmentGroupIds,
          putaway_location_strategies: queryArg.putawayLocationStrategies,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postAutomationConfigs: build.mutation<
      PostAutomationConfigsApiResponse,
      PostAutomationConfigsApiArg
    >({
      query: (queryArg) => ({
        url: `/automation-configs`,
        method: 'POST',
        body: queryArg.automationConfig,
      }),
    }),
    getAutomationConfigsByAutomationConfigId: build.query<
      GetAutomationConfigsByAutomationConfigIdApiResponse,
      GetAutomationConfigsByAutomationConfigIdApiArg
    >({
      query: (queryArg) => ({ url: `/automation-configs/${queryArg.automationConfigId}` }),
    }),
    patchAutomationConfigsByAutomationConfigId: build.mutation<
      PatchAutomationConfigsByAutomationConfigIdApiResponse,
      PatchAutomationConfigsByAutomationConfigIdApiArg
    >({
      query: (queryArg) => ({
        url: `/automation-configs/${queryArg.automationConfigId}`,
        method: 'PATCH',
        body: queryArg.automationConfig,
      }),
    }),
    deleteAutomationConfigsByAutomationConfigId: build.mutation<
      DeleteAutomationConfigsByAutomationConfigIdApiResponse,
      DeleteAutomationConfigsByAutomationConfigIdApiArg
    >({
      query: (queryArg) => ({
        url: `/automation-configs/${queryArg.automationConfigId}`,
        method: 'DELETE',
      }),
    }),
    postAutomationConfigsEvaluate: build.mutation<
      PostAutomationConfigsEvaluateApiResponse,
      PostAutomationConfigsEvaluateApiArg
    >({
      query: (queryArg) => ({
        url: `/automation-configs/evaluate`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postAutomationConfigsByAutomationConfigIdRestream: build.mutation<
      PostAutomationConfigsByAutomationConfigIdRestreamApiResponse,
      PostAutomationConfigsByAutomationConfigIdRestreamApiArg
    >({
      query: (queryArg) => ({
        url: `/automation-configs/${queryArg.automationConfigId}/restream`,
        method: 'POST',
      }),
    }),
    getAutomationConfigsPutawayLpnByLicensePlateId: build.query<
      GetAutomationConfigsPutawayLpnByLicensePlateIdApiResponse,
      GetAutomationConfigsPutawayLpnByLicensePlateIdApiArg
    >({
      query: (queryArg) => ({ url: `/automation-configs/putaway-lpn/${queryArg.licensePlateId}` }),
    }),
    getOrionWarehouseMappings: build.query<
      GetOrionWarehouseMappingsApiResponse,
      GetOrionWarehouseMappingsApiArg
    >({
      query: (queryArg) => ({
        url: `/orion-warehouse-mappings`,
        params: {
          shipper_id: queryArg.shipperId,
          building_id: queryArg.buildingId,
          warehouse_alias: queryArg.warehouseAlias,
          distribution_network_id: queryArg.distributionNetworkId,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postOrionWarehouseMappings: build.mutation<
      PostOrionWarehouseMappingsApiResponse,
      PostOrionWarehouseMappingsApiArg
    >({
      query: (queryArg) => ({
        url: `/orion-warehouse-mappings`,
        method: 'POST',
        body: queryArg.orionWarehouseMapping,
      }),
    }),
    patchOrionWarehouseMappingsByOrionWarehouseMappingId: build.mutation<
      PatchOrionWarehouseMappingsByOrionWarehouseMappingIdApiResponse,
      PatchOrionWarehouseMappingsByOrionWarehouseMappingIdApiArg
    >({
      query: (queryArg) => ({
        url: `/orion-warehouse-mappings/${queryArg.orionWarehouseMappingId}`,
        method: 'PATCH',
        body: queryArg.orionWarehouseMapping,
      }),
    }),
    getOrionWarehouseMappingsByOrionWarehouseMappingId: build.query<
      GetOrionWarehouseMappingsByOrionWarehouseMappingIdApiResponse,
      GetOrionWarehouseMappingsByOrionWarehouseMappingIdApiArg
    >({
      query: (queryArg) => ({
        url: `/orion-warehouse-mappings/${queryArg.orionWarehouseMappingId}`,
      }),
    }),
    postPackoutSession: build.mutation<PostPackoutSessionApiResponse, PostPackoutSessionApiArg>({
      query: (queryArg) => ({ url: `/packout/session`, method: 'POST', body: queryArg.body }),
    }),
    getPackoutSessionBySessionIdOutbounds: build.query<
      GetPackoutSessionBySessionIdOutboundsApiResponse,
      GetPackoutSessionBySessionIdOutboundsApiArg
    >({
      query: (queryArg) => ({ url: `/packout/session/${queryArg.sessionId}/outbounds` }),
    }),
    patchPackoutOutboundsPacked: build.mutation<
      PatchPackoutOutboundsPackedApiResponse,
      PatchPackoutOutboundsPackedApiArg
    >({
      query: (queryArg) => ({
        url: `/packout/outbounds/packed`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    patchPackoutOutboundsByOutboundIdPacked: build.mutation<
      PatchPackoutOutboundsByOutboundIdPackedApiResponse,
      PatchPackoutOutboundsByOutboundIdPackedApiArg
    >({
      query: (queryArg) => ({
        url: `/packout/outbounds/${queryArg.outboundId}/packed`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    postPackoutOutboundsShortage: build.mutation<
      PostPackoutOutboundsShortageApiResponse,
      PostPackoutOutboundsShortageApiArg
    >({
      query: (queryArg) => ({
        url: `/packout/outbounds/shortage`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postPackoutPrintApplyPackToLpnByOutboundId: build.mutation<
      PostPackoutPrintApplyPackToLpnByOutboundIdApiResponse,
      PostPackoutPrintApplyPackToLpnByOutboundIdApiArg
    >({
      query: (queryArg) => ({
        url: `/packout/print-apply/pack-to-lpn/${queryArg.outboundId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postWebsocketTickets: build.mutation<
      PostWebsocketTicketsApiResponse,
      PostWebsocketTicketsApiArg
    >({
      query: () => ({ url: `/websocket-tickets`, method: 'POST' }),
    }),
    getTimezones: build.query<GetTimezonesApiResponse, GetTimezonesApiArg>({
      query: () => ({ url: `/timezones` }),
    }),
    getTracking: build.query<GetTrackingApiResponse, GetTrackingApiArg>({
      query: () => ({ url: `/tracking` }),
    }),
    getAdjustmentsReasonCodes: build.query<
      GetAdjustmentsReasonCodesApiResponse,
      GetAdjustmentsReasonCodesApiArg
    >({
      query: (queryArg) => ({
        url: `/adjustments/reason-codes`,
        params: { active: queryArg.active, sort: queryArg.sort },
      }),
    }),
    getWorkOrders: build.query<GetWorkOrdersApiResponse, GetWorkOrdersApiArg>({
      query: (queryArg) => ({
        url: `/work-orders`,
        params: {
          category: queryArg.category,
          number: queryArg['number'],
          status: queryArg.status,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          completed_before_datetime: queryArg.completedBeforeDatetime,
          completed_after_datetime: queryArg.completedAfterDatetime,
          shipper_ids: queryArg.shipperIds,
          search: queryArg.search,
          created_by_user_id: queryArg.createdByUserId,
          assigned_user_id: queryArg.assignedUserId,
          available_for_user_id: queryArg.availableForUserId,
          product_id: queryArg.productId,
          location_id: queryArg.locationId,
          active_step: queryArg.activeStep,
          priority: queryArg.priority,
          output_lpns: queryArg.outputLpns,
          exclude_output_lpns: queryArg.excludeOutputLpns,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postWorkOrders: build.mutation<PostWorkOrdersApiResponse, PostWorkOrdersApiArg>({
      query: (queryArg) => ({ url: `/work-orders`, method: 'POST', body: queryArg.body }),
    }),
    getWorkOrdersReplenishmentLookAhead: build.query<
      GetWorkOrdersReplenishmentLookAheadApiResponse,
      GetWorkOrdersReplenishmentLookAheadApiArg
    >({
      query: (queryArg) => ({
        url: `/work-orders/replenishment-look-ahead`,
        params: {
          shipper_ids: queryArg.shipperIds,
          product_id: queryArg.productId,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    patchWorkOrdersAssign: build.mutation<
      PatchWorkOrdersAssignApiResponse,
      PatchWorkOrdersAssignApiArg
    >({
      query: (queryArg) => ({ url: `/work-orders/assign`, method: 'PATCH', body: queryArg.body }),
    }),
    getWorkOrdersByWorkOrderIdAttachments: build.query<
      GetWorkOrdersByWorkOrderIdAttachmentsApiResponse,
      GetWorkOrdersByWorkOrderIdAttachmentsApiArg
    >({
      query: (queryArg) => ({ url: `/work-orders/${queryArg.workOrderId}/attachments` }),
    }),
    postWorkOrdersByWorkOrderIdAttachments: build.mutation<
      PostWorkOrdersByWorkOrderIdAttachmentsApiResponse,
      PostWorkOrdersByWorkOrderIdAttachmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/work-orders/${queryArg.workOrderId}/attachments`,
        method: 'POST',
        body: queryArg.attachment,
      }),
    }),
    getWorkOrdersByWorkOrderId: build.query<
      GetWorkOrdersByWorkOrderIdApiResponse,
      GetWorkOrdersByWorkOrderIdApiArg
    >({
      query: (queryArg) => ({ url: `/work-orders/${queryArg.workOrderId}` }),
    }),
    patchWorkOrdersByWorkOrderId: build.mutation<
      PatchWorkOrdersByWorkOrderIdApiResponse,
      PatchWorkOrdersByWorkOrderIdApiArg
    >({
      query: (queryArg) => ({
        url: `/work-orders/${queryArg.workOrderId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    postWorkOrdersByWorkOrderIdMove: build.mutation<
      PostWorkOrdersByWorkOrderIdMoveApiResponse,
      PostWorkOrdersByWorkOrderIdMoveApiArg
    >({
      query: (queryArg) => ({
        url: `/work-orders/${queryArg.workOrderId}/move`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postWorkOrdersByWorkOrderIdAssembleKit: build.mutation<
      PostWorkOrdersByWorkOrderIdAssembleKitApiResponse,
      PostWorkOrdersByWorkOrderIdAssembleKitApiArg
    >({
      query: (queryArg) => ({
        url: `/work-orders/${queryArg.workOrderId}/assemble-kit`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postWorkOrdersByWorkOrderIdStage: build.mutation<
      PostWorkOrdersByWorkOrderIdStageApiResponse,
      PostWorkOrdersByWorkOrderIdStageApiArg
    >({
      query: (queryArg) => ({
        url: `/work-orders/${queryArg.workOrderId}/stage`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postWorkOrdersByWorkOrderIdPick: build.mutation<
      PostWorkOrdersByWorkOrderIdPickApiResponse,
      PostWorkOrdersByWorkOrderIdPickApiArg
    >({
      query: (queryArg) => ({
        url: `/work-orders/${queryArg.workOrderId}/pick`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postWorkOrdersByWorkOrderIdReplenish: build.mutation<
      PostWorkOrdersByWorkOrderIdReplenishApiResponse,
      PostWorkOrdersByWorkOrderIdReplenishApiArg
    >({
      query: (queryArg) => ({
        url: `/work-orders/${queryArg.workOrderId}/replenish`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postWorkOrdersStageReplenishments: build.mutation<
      PostWorkOrdersStageReplenishmentsApiResponse,
      PostWorkOrdersStageReplenishmentsApiArg
    >({
      query: (queryArg) => ({
        url: `/work-orders/stage-replenishments`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    patchWorkOrderStepsByWorkOrderStepIdAssign: build.mutation<
      PatchWorkOrderStepsByWorkOrderStepIdAssignApiResponse,
      PatchWorkOrderStepsByWorkOrderStepIdAssignApiArg
    >({
      query: (queryArg) => ({
        url: `/work-order-steps/${queryArg.workOrderStepId}/assign`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    getShippingPackaging: build.query<GetShippingPackagingApiResponse, GetShippingPackagingApiArg>({
      query: (queryArg) => ({
        url: `/shipping/packaging`,
        params: {
          shipper_id: queryArg.shipperId,
          default: queryArg['default'],
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postShippingPackaging: build.mutation<
      PostShippingPackagingApiResponse,
      PostShippingPackagingApiArg
    >({
      query: (queryArg) => ({
        url: `/shipping/packaging`,
        method: 'POST',
        body: queryArg.packaging,
      }),
    }),
    deleteShippingPackagingByPackagingId: build.mutation<
      DeleteShippingPackagingByPackagingIdApiResponse,
      DeleteShippingPackagingByPackagingIdApiArg
    >({
      query: (queryArg) => ({
        url: `/shipping/packaging/${queryArg.packagingId}`,
        method: 'DELETE',
      }),
    }),
    patchShippingPackagingByPackagingId: build.mutation<
      PatchShippingPackagingByPackagingIdApiResponse,
      PatchShippingPackagingByPackagingIdApiArg
    >({
      query: (queryArg) => ({
        url: `/shipping/packaging/${queryArg.packagingId}`,
        method: 'PATCH',
        body: queryArg.packaging,
      }),
    }),
    getShippingPackagingByPackagingId: build.query<
      GetShippingPackagingByPackagingIdApiResponse,
      GetShippingPackagingByPackagingIdApiArg
    >({
      query: (queryArg) => ({ url: `/shipping/packaging/${queryArg.packagingId}` }),
    }),
    getBarcodeLookup: build.query<GetBarcodeLookupApiResponse, GetBarcodeLookupApiArg>({
      query: (queryArg) => ({ url: `/barcode-lookup`, params: { barcode: queryArg.barcode } }),
    }),
    postAdminBulkPackedStream: build.mutation<
      PostAdminBulkPackedStreamApiResponse,
      PostAdminBulkPackedStreamApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/bulk-packed-stream`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postAdminProductByProductIdInventoryTotals: build.mutation<
      PostAdminProductByProductIdInventoryTotalsApiResponse,
      PostAdminProductByProductIdInventoryTotalsApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/product/${queryArg.productId}/inventory-totals`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getAdminInventoryTotals: build.query<
      GetAdminInventoryTotalsApiResponse,
      GetAdminInventoryTotalsApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/inventory-totals`,
        params: {
          product_id: queryArg.productId,
          shipper_id: queryArg.shipperId,
          storage_subtype: queryArg.storageSubtype,
          lot_number: queryArg.lotNumber,
          zone_id: queryArg.zoneId,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postInsightsDomoJwt: build.mutation<PostInsightsDomoJwtApiResponse, PostInsightsDomoJwtApiArg>({
      query: (queryArg) => ({ url: `/insights/domo/jwt`, method: 'POST', body: queryArg.body }),
    }),
    postInsightsDomoUrl: build.mutation<PostInsightsDomoUrlApiResponse, PostInsightsDomoUrlApiArg>({
      query: (queryArg) => ({ url: `/insights/domo/url`, method: 'POST', body: queryArg.body }),
    }),
    getProviders: build.query<GetProvidersApiResponse, GetProvidersApiArg>({
      query: (queryArg) => ({
        url: `/providers`,
        params: { active: queryArg.active, sort: queryArg.sort },
      }),
    }),
    getInboundExceptions: build.query<GetInboundExceptionsApiResponse, GetInboundExceptionsApiArg>({
      query: (queryArg) => ({
        url: `/inbound-exceptions`,
        params: {
          categories: queryArg.categories,
          inbound_ids: queryArg.inboundIds,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          shipper_ids: queryArg.shipperIds,
          subcategories: queryArg.subcategories,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postInboundExceptions: build.mutation<
      PostInboundExceptionsApiResponse,
      PostInboundExceptionsApiArg
    >({
      query: (queryArg) => ({ url: `/inbound-exceptions`, method: 'POST', body: queryArg.body }),
    }),
    getInboundExceptionsById: build.query<
      GetInboundExceptionsByIdApiResponse,
      GetInboundExceptionsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/inbound-exceptions/${queryArg.id}` }),
    }),
    getOutboundExceptions: build.query<
      GetOutboundExceptionsApiResponse,
      GetOutboundExceptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/outbound-exceptions`,
        params: {
          categories: queryArg.categories,
          shipper_ids: queryArg.shipperIds,
          inserted_after_datetime: queryArg.insertedAfterDatetime,
          inserted_before_datetime: queryArg.insertedBeforeDatetime,
          subcategories: queryArg.subcategories,
          outbound_ids: queryArg.outboundIds,
          outbound_line_ids: queryArg.outboundLineIds,
          batch_ids: queryArg.batchIds,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postOutboundExceptions: build.mutation<
      PostOutboundExceptionsApiResponse,
      PostOutboundExceptionsApiArg
    >({
      query: (queryArg) => ({ url: `/outbound-exceptions`, method: 'POST', body: queryArg.body }),
    }),
    getOutboundExceptionsById: build.query<
      GetOutboundExceptionsByIdApiResponse,
      GetOutboundExceptionsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/outbound-exceptions/${queryArg.id}` }),
    }),
    getSyncInventory: build.query<GetSyncInventoryApiResponse, GetSyncInventoryApiArg>({
      query: (queryArg) => ({
        url: `/sync/inventory`,
        params: { shipper_ids: queryArg.shipperIds },
      }),
    }),
    getZplExceptionsByOutboundId4X6: build.query<
      GetZplExceptionsByOutboundId4X6ApiResponse,
      GetZplExceptionsByOutboundId4X6ApiArg
    >({
      query: (queryArg) => ({
        url: `/zpl/exceptions/${queryArg.outboundId}/4x6`,
        params: { reason: queryArg.reason },
      }),
    }),
    getZplExceptionsByOutboundId2X2: build.query<
      GetZplExceptionsByOutboundId2X2ApiResponse,
      GetZplExceptionsByOutboundId2X2ApiArg
    >({
      query: (queryArg) => ({
        url: `/zpl/exceptions/${queryArg.outboundId}/2x2`,
        params: { reason: queryArg.reason },
      }),
    }),
    postCubiscanLabelRequest: build.mutation<
      PostCubiscanLabelRequestApiResponse,
      PostCubiscanLabelRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/cubiscan/label-request`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postCubiscanStatusUpdate: build.mutation<
      PostCubiscanStatusUpdateApiResponse,
      PostCubiscanStatusUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/cubiscan/status-update`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getZones: build.query<GetZonesApiResponse, GetZonesApiArg>({
      query: (queryArg) => ({
        url: `/zones`,
        params: {
          zone_ids: queryArg.zoneIds,
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
        },
      }),
    }),
    postZones: build.mutation<PostZonesApiResponse, PostZonesApiArg>({
      query: (queryArg) => ({ url: `/zones`, method: 'POST', body: queryArg.zone }),
    }),
    postZonesLock: build.mutation<PostZonesLockApiResponse, PostZonesLockApiArg>({
      query: (queryArg) => ({ url: `/zones/lock`, method: 'POST', body: queryArg.body }),
    }),
    postZonesUnlock: build.mutation<PostZonesUnlockApiResponse, PostZonesUnlockApiArg>({
      query: (queryArg) => ({ url: `/zones/unlock`, method: 'POST', body: queryArg.body }),
    }),
    deleteZonesByZoneId: build.mutation<DeleteZonesByZoneIdApiResponse, DeleteZonesByZoneIdApiArg>({
      query: (queryArg) => ({ url: `/zones/${queryArg.zoneId}`, method: 'DELETE' }),
    }),
    getZonesByZoneId: build.query<GetZonesByZoneIdApiResponse, GetZonesByZoneIdApiArg>({
      query: (queryArg) => ({ url: `/zones/${queryArg.zoneId}` }),
    }),
    patchZonesByZoneId: build.mutation<PatchZonesByZoneIdApiResponse, PatchZonesByZoneIdApiArg>({
      query: (queryArg) => ({
        url: `/zones/${queryArg.zoneId}`,
        method: 'PATCH',
        body: queryArg.zone,
      }),
    }),
    postZonesByZoneIdLock: build.mutation<
      PostZonesByZoneIdLockApiResponse,
      PostZonesByZoneIdLockApiArg
    >({
      query: (queryArg) => ({
        url: `/zones/${queryArg.zoneId}/lock`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postZonesByZoneIdUnlock: build.mutation<
      PostZonesByZoneIdUnlockApiResponse,
      PostZonesByZoneIdUnlockApiArg
    >({
      query: (queryArg) => ({ url: `/zones/${queryArg.zoneId}/unlock`, method: 'POST' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type GetActivitiesApiResponse = /** status 200 List Activity */ {
  meta?: {
    after?: string;
    before?: string;
    limit?: number;
    total_count?: number | null;
    total_count_cap_exceeded?: boolean | null;
  };
  data?: Activity[];
};
export type GetActivitiesApiArg = {
  /** User ID */
  userId?: string;
  /** Actions to filter by */
  actions?: string;
  /** Return orders inserted after Datetime (ISO-8601) */
  insertedAfterDatetime?: string;
  /** Return orders inserted before Datetime (ISO-8601) */
  insertedBeforeDatetime?: string;
  /** A partial to search for in the metadata */
  search?: string;
  /** The field the search should apply to */
  searchField?: string;
  /** The field the search should apply to */
  requestId?: string;
  /** Returns activities after the given cursor */
  after?: string;
  /** Returns activities before the given cursor */
  before?: string;
  /** Maximum number of activities requested */
  limit?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetAnnouncementsApiResponse = /** status 200 List announcements */ {
  data?: Announcement[];
  meta?: Pagination;
};
export type GetAnnouncementsApiArg = {
  /** comma separated announcement categories */
  categories?: string;
  /** Return items inserted after Datetime (ISO-8601) */
  insertedAfterDatetime?: string;
  /** Return items inserted before Datetime (ISO-8601) */
  insertedBeforeDatetime?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostAnnouncementsApiResponse = /** status 201 Created */ {
  data?: Announcement;
};
export type PostAnnouncementsApiArg = {
  /** Announcement to Create */
  body: {
    category?: AnnouncementCategory;
    message?: string;
  };
};
export type DeleteAnnouncementsByAnnouncementIdApiResponse =
  /** status 204 Delete Announcement */ undefined;
export type DeleteAnnouncementsByAnnouncementIdApiArg = {
  announcementId: string;
};
export type GetAnnouncementsByAnnouncementIdApiResponse = /** status 200 Get Announcement */ {
  data?: Announcement;
};
export type GetAnnouncementsByAnnouncementIdApiArg = {
  announcementId: string;
};
export type PatchAnnouncementsByAnnouncementIdApiResponse = /** status 200 Update Announcement */ {
  data?: Announcement;
};
export type PatchAnnouncementsByAnnouncementIdApiArg = {
  announcementId: string;
  /** Announcement to Update */
  body: {
    category?: AnnouncementCategory;
    message?: string;
  };
};
export type GetBatchesApiResponse = /** status 200 List existing Batches */ {
  meta?: Pagination;
  data?: Batch[];
};
export type GetBatchesApiArg = {
  /** Assigned User */
  assignedUserId?: string;
  /** Filter batches that are assigned */
  isAssigned?: boolean;
  /** Filter batches to those assigned to the user id and unassigned to any user */
  pickableForUserId?: string;
  /** Filter batches to those with the pick equipment group id or a nil pick equipment group id */
  pickableForPickEquipmentGroupId?: string;
  /** Filter batches to those created by the user id */
  createdUserId?: string;
  /** determination of whether batch has orders needing processing or not */
  status?: 'open';
  /** comma separated status filters */
  statuses?: string;
  /** filter results to those inserted before the given datetime */
  insertedBeforeDatetime?: string;
  /** filter results to those inserted after the given datetime */
  insertedAfterDatetime?: string;
  /** filter results to those completed before the given datetime */
  completedBeforeDatetime?: string;
  /** filter results to those completed after the given datetime */
  completedAfterDatetime?: string;
  /** search for batches on batch id, or outbound external number, external id, or custom reference */
  search?: string;
  /** comma separated list of shipper_ids */
  shipperIds?: string;
  /** Filter to batches with outbounds that have unresolved exceptions */
  hasUnresolvedException?: boolean;
  /** Filter to batches created with the given automation_config_id */
  automationConfigId?: string;
  /** Filter to batches containing outbounds with requested lot number */
  requestedLotNumber?: string;
  /** Filter to batches containing outbounds containing this product ID */
  productId?: string;
  /** Filters results only including the provided pick strategy */
  pickStrategy?: PickStrategy;
  /** Filters results only including the provided pack strategy */
  packStrategy?: PackStrategy;
  /** Filter to batches containing outbounds picked from given lot number */
  pickedLotNumber?: string;
  /** Comma separated list of pick equipment group ids */
  pickEquipmentGroupIds?: string;
  /** Filter batches that have a pick equipment group */
  hasPickEquipmentGroup?: boolean;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostBatchesApiResponse = /** status 201 Created */ {
  data?: Batch;
};
export type PostBatchesApiArg = {
  /** Batch to Create */
  body: {
    assigned_user_id?: string;
    pick_strategy?: PickStrategy;
    pick_totes_strategy?: PickTotesStrategy | null;
    pack_strategy?: PackStrategy;
    pick_location_strategy?: PickLocationStrategy;
    pick_equipment_group_id?: string;
    outbounds?: string[];
    zones?: string[];
  };
};
export type GetBatchesAssignedUsersApiResponse = /** status 200 List assigned users of batches */ {
  data?: User[];
};
export type GetBatchesAssignedUsersApiArg = {
  /** Assigned User */
  assignedUserId?: string;
  /** Filter batches to those assigned to the user id and unassigned to any user */
  pickableForUserId?: string;
  /** determination of whether batch has orders needing processing or not */
  status?: 'open';
  /** comma separated status filters */
  statuses?: string;
  /** filter results to those inserted before the given datetime */
  insertedBeforeDatetime?: string;
  /** filter results to those inserted after the given datetime */
  insertedAfterDatetime?: string;
  /** filter results to those completed before the given datetime */
  completedBeforeDatetime?: string;
  /** filter results to those completed after the given datetime */
  completedAfterDatetime?: string;
  /** search for batches on batch id, or outbound external number, external id, or custom reference */
  search?: string;
  /** comma separated list of shipper_ids */
  shipperIds?: string;
  /** Filter to batches with outbounds that have unresolved exceptions */
  hasUnresolvedException?: boolean;
};
export type PostBatchesBulkApiResponse = /** status 202 Accepted */ string;
export type PostBatchesBulkApiArg = {
  /** Outbound filters to create batches by. */
  body: {
    status?: string;
    shipment_type?: OutboundShipmentType;
    product_id?: string;
    location_id?: string;
    outbound_ids?: string;
    fulfillable: boolean;
    allocatable?: boolean;
    availability?: OutboundAvailability;
    shipper_ids: string;
    inserted_after_datetime?: string;
    inserted_before_datetime?: string;
    expected_shipped_at_after_datetime?: string;
    expected_shipped_at_before_datetime?: string;
    externally_created_after_datetime?: string;
    externally_created_before_datetime?: string;
    skus?: string;
    search?: string;
    pick_strategy?: any;
    pack_strategy?: any;
    tags?: string;
    channel_name?: string;
    channel_categories?: string;
    order_types?: string;
    serial_numbers?: string;
    serial_number_id?: string;
    requested_lot_number?: string;
    picked_lot_number?: string;
    license_plate_ids?: string;
    has_unresolved_exception?: boolean;
    product_ids?:
      | string
      | {
          incl?: string;
          excl?: string;
        };
    channel_names?:
      | string
      | {
          incl?: string;
          excl?: string;
        };
  };
};
export type GetBinLocationsApiResponse = /** status 200 List of Locations.
If include_product_summary query parameter is passed with "true",
then product summary will return, sorted by SKU.
 */ {
  meta?: Pagination;
  data?: BinLocation[];
};
export type GetBinLocationsApiArg = {
  active?: boolean;
  /** Comma separated list of zone ids */
  zoneIds?: string;
  /** A comma separated list of location types */
  types?: string;
  /** A comma separated list of location sub types */
  subtypes?: string;
  /** Location name, or partial name, or legacy barcode value to search for */
  search?: string;
  /** Comma separated Location IDs */
  locationIds?: string;
  /** Comma separated Shipper IDs. Returns only locations with inventory for the given shipper(s) */
  shipperIds?: string;
  /** Comma separated names of locations to return */
  names?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list). Can use `items_quantity` and `lpn_count` for sorting,
    in addition to the available sorting fields for locations
     */
  sort?: string;
};
export type GetBinLocationsDownloadApiResponse =
  /** status 200 Download a CSV of all bin locations */ string;
export type GetBinLocationsDownloadApiArg = {
  /** Comma separated list of location types to filter by */
  types?: string;
  /** Comma separated list of location subtypes to filter by */
  subtypes?: string;
  /** Active flag filter */
  active?: boolean;
  /** Location name, or partial name, to search for */
  search?: string;
  /** Filter for locations containing inventory from provided shipper(s) */
  shipperIds?: string;
};
export type GetBuildingAnnouncementsApiResponse = /** status 200 List building announcements */ {
  data?: BuildingAnnouncement[];
  meta?: Pagination;
};
export type GetBuildingAnnouncementsApiArg = {
  /** Building ID */
  buildingId?: string;
  /** Building Name */
  buildingName?: string;
  /** Announcement ID */
  announcementId?: string;
  /** active flag */
  active?: boolean;
  /** Return items inserted after Datetime (ISO-8601) */
  insertedAfterDatetime?: string;
  /** Return items inserted before Datetime (ISO-8601) */
  insertedBeforeDatetime?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostBuildingAnnouncementsApiResponse = /** status 201 Created */ BuildingAnnouncement;
export type PostBuildingAnnouncementsApiArg = {
  /** Building Announcement to upsert */
  buildingAnnouncement: BuildingAnnouncement;
};
export type DeleteBuildingAnnouncementsByBuildingAnnouncementIdApiResponse =
  /** status 204 No Content */ undefined;
export type DeleteBuildingAnnouncementsByBuildingAnnouncementIdApiArg = {
  buildingAnnouncementId: string;
};
export type PostBuildingAnnouncementsBulkApiResponse =
  /** status 201 Created */ BuildingAnnouncement[];
export type PostBuildingAnnouncementsBulkApiArg = {
  /** Building Announcements to Upsert */
  body: {
    building_announcements?: BuildingAnnouncement[];
  };
};
export type PostOutboundsByOutboundIdParcelsApiResponse = /** status 201 Created */ {
  data?: Parcel;
};
export type PostOutboundsByOutboundIdParcelsApiArg = {
  outboundId: string;
  /** Type of parcel to be created */
  type?: 'manual';
  /** Parcel to create */
  body: Parcel & {
    serial_numbers?: SerialNumber[];
  };
};
export type GetOutboundsByOutboundIdParcelsApiResponse = /** status 200 Listing parcels */ {
  meta?: Pagination;
  data?: Parcel[];
};
export type GetOutboundsByOutboundIdParcelsApiArg = {
  outboundId: string;
  page?: number;
  pageSize?: number;
};
export type DeleteOutboundsByOutboundIdParcelsInProgressApiResponse =
  /** status 204 Accepted */ string;
export type DeleteOutboundsByOutboundIdParcelsInProgressApiArg = {
  outboundId: string;
};
export type DeleteOutboundsByOutboundIdParcelsAndParcelIdApiResponse =
  /** status 204 Accepted */ string;
export type DeleteOutboundsByOutboundIdParcelsAndParcelIdApiArg = {
  outboundId: string;
  parcelId: string;
  /** Force delete parcel regardless of external void api result. Only usable by admin users */
  force?: boolean;
};
export type GetOutboundsByOutboundIdParcelsAndParcelIdApiResponse =
  /** status 200 Retrieve Parcel details */ {
    data?: Parcel;
  };
export type GetOutboundsByOutboundIdParcelsAndParcelIdApiArg = {
  outboundId: string;
  parcelId: string;
};
export type PostOutboundsByOutboundIdParcelsAndParcelIdGenerateLabelApiResponse =
  /** status 201 Created */ {
    data?: Parcel;
  };
export type PostOutboundsByOutboundIdParcelsAndParcelIdGenerateLabelApiArg = {
  outboundId: string;
  /** ID of parcel to generate a label for */
  parcelId: string;
  /** Parcel attributes to update */
  body: {
    height?: number;
    length?: number;
    packaging_id?: string | null;
    volume?: number;
    weight?: number;
    width?: number;
  };
};
export type GetOutboundsByOutboundIdParcelsAndParcelIdPackingSlipApiResponse =
  /** status 200 Either the ZPL or HTML packing slip for the requested parcel */ string;
export type GetOutboundsByOutboundIdParcelsAndParcelIdPackingSlipApiArg = {
  outboundId: string;
  parcelId: string;
  /** The format of the packing slip to return. Either '8.5x11' or '4x6'. If not provided, defaults to the shipper's configured format. */
  format?: '8.5x11' | '4x6';
};
export type PostShippersByShipperIdParcelsBulkApiResponse =
  /** status 202 Bulk creation job accepted */ {
    data?: BulkLabelCreationJob;
  };
export type PostShippersByShipperIdParcelsBulkApiArg = {
  shipperId: string;
  /** Parcels to create asynchronously */
  body: {
    parcels?: Parcel[];
  };
};
export type GetShippersByShipperIdParcelsBulkAndJobIdApiResponse =
  /** status 200 Status of bulk label creation job */ {
    data?: BulkLabelCreationJob;
  };
export type GetShippersByShipperIdParcelsBulkAndJobIdApiArg = {
  shipperId: string;
  jobId: string;
};
export type PutOutboundsByOutboundIdParcelsAndParcelIdItemsItemIdApiResponse =
  /** status 200 Updated */ {
    data?: ParcelItem;
  };
export type PutOutboundsByOutboundIdParcelsAndParcelIdItemsItemIdApiArg = {
  outboundId: string;
  parcelId: string;
  itemId: string;
  /** A ParcelItem */
  body: ParcelItem;
};
export type GetOutboundsByOutboundIdAttachmentsApiResponse = /** status 200 Listing attachments */ {
  meta?: Pagination;
  data?: OutboundAttachment[];
};
export type GetOutboundsByOutboundIdAttachmentsApiArg = {
  outboundId: string;
};
export type PostOutboundsByOutboundIdAttachmentsApiResponse = /** status 201 Created */ {
  data?: Attachment;
};
export type PostOutboundsByOutboundIdAttachmentsApiArg = {
  outboundId: string;
  /** Attachment to create */
  attachment: Attachment;
};
export type GetParcelsApiResponse = /** status 200 Listing parcels */ {
  meta?: Pagination;
  data?: Parcel[];
};
export type GetParcelsApiArg = {
  /** Search string for parcel */
  trackingNumber?: string;
  /** Search tracking numbers embeded in a shipping label scan */
  enhancedTrackingNumber?: string;
  /** Filter to parcels with or without tracking numbers */
  hasTrackingNumber?: boolean;
  /** Return orders inserted after Datetime (ISO-8601) */
  insertedAfterDatetime?: string;
  /** Return orders inserted before Datetime (ISO-8601) */
  insertedBeforeDatetime?: string;
  /** Return orders updated after Datetime (ISO-8601) */
  updatedAfterDatetime?: string;
  /** Return orders updated before Datetime (ISO-8601) */
  updatedBeforeDatetime?: string;
  /** Filter to parcels that are void or not */
  void?: boolean;
};
export type GetParcelsByParcelIdApiResponse = /** status 200 Retrieve Parcel details */ {
  data?: Parcel;
};
export type GetParcelsByParcelIdApiArg = {
  parcelId: string;
};
export type PostParcelsByParcelIdShipApiResponse = /** status 201 Created */ {
  data?: Parcel;
};
export type PostParcelsByParcelIdShipApiArg = {
  parcelId: string;
};
export type PostShippersByShipperNameOutboundsAndOrderNumberAttachmentsApiResponse =
  /** status 201 Created */ {
    data?: Attachment;
  };
export type PostShippersByShipperNameOutboundsAndOrderNumberAttachmentsApiArg = {
  /** Shipper Name */
  shipperName: string;
  /** Outbound Purchase Order Number */
  orderNumber: string;
  /** Attachment to create */
  attachment: Attachment;
};
export type GetAttachmentsByAttachmentIdApiResponse = /** status 200 Ok */ undefined;
export type GetAttachmentsByAttachmentIdApiArg = {
  attachmentId: string;
};
export type DeleteAttachmentsByAttachmentIdApiResponse = /** status 204 Accepted */ string;
export type DeleteAttachmentsByAttachmentIdApiArg = {
  attachmentId: string;
};
export type GetBatchesByBatchIdApiResponse = /** status 200 Retrieve Batch details */ {
  data?: Batch;
};
export type GetBatchesByBatchIdApiArg = {
  batchId: string;
};
export type PatchBatchesByBatchIdApiResponse = /** status 200 Updated */ {
  data?: Batch;
};
export type PatchBatchesByBatchIdApiArg = {
  batchId: string;
  /** A Batch */
  body: Batch & {
    assigned_user_id?: string;
  };
};
export type PostBatchesByBatchIdPickApiResponse =
  /** status 204 Successful without content */ string;
export type PostBatchesByBatchIdPickApiArg = {
  batchId: string;
  body: {
    source_lpn: string;
    destination_lpn: string;
    destination_location: string;
    product_id?: string;
    quantity?: number;
  };
};
export type GetBatchesByBatchIdPickPathApiResponse =
  /** status 200 List all pick path locations for this batch */ {
    data?: PickPathLocation[];
  };
export type GetBatchesByBatchIdPickPathApiArg = {
  batchId: string;
  /** Outbound order search (external_id, external_order_number, or custom_reference) */
  search?: string;
  /** Filter to picks that were on the original pick path */
  isOriginalPick?: boolean;
  /** Filter to picks that have an associated exception */
  hasException?: boolean;
  /** Filter to picks that are completed */
  isCompleted?: boolean;
  /** Comma separated list of product ids to filter by */
  productIds?: string;
  /** Comma separated list of location ids to filter by */
  locationIds?: string;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostBatchesByBatchIdPickPathApiResponse =
  /** status 200 List all pick path locations for this batch */ {
    data?: PickPathLocation[];
  };
export type PostBatchesByBatchIdPickPathApiArg = {
  batchId: string;
};
export type PostBatchesByBatchIdAlternatePickApiResponse = /** status 201 Created */ {
  data?: PickPathLocation;
};
export type PostBatchesByBatchIdAlternatePickApiArg = {
  batchId: string;
  body: {
    cascade?: boolean;
    preferred_location_id?: string;
    location_id: string;
    pick: Pick;
    shipper_id: string;
    sku: string;
  };
};
export type PostBatchesByBatchIdReleaseApiResponse =
  /** status 204 Successful without content */ string;
export type PostBatchesByBatchIdReleaseApiArg = {
  batchId: string;
};
export type PostBatchesByBatchIdShortSplitApiResponse =
  /** status 204 Successful without content */ string;
export type PostBatchesByBatchIdShortSplitApiArg = {
  batchId: string;
};
export type GetPrintersApiResponse = /** status 200 List existing Printers */ {
  meta?: Pagination;
  data?: Printer[];
};
export type GetPrintersApiArg = {
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostPrintersApiResponse = /** status 201 Created */ {
  data?: Printer;
};
export type PostPrintersApiArg = {
  /** Printer to add */
  printer: Printer;
};
export type DeletePrintersByPrinterIdApiResponse = /** status 202 Accepted */ string;
export type DeletePrintersByPrinterIdApiArg = {
  /** Printer ID */
  printerId: string;
};
export type PatchPrintersByPrinterIdApiResponse = /** status 200 Updated */ {
  data?: Printer;
};
export type PatchPrintersByPrinterIdApiArg = {
  printerId: string;
  /** A Printer */
  printer: Printer;
};
export type GetItemsApiResponse = /** status 200 List of Items */ {
  meta?: Pagination;
  data?: Item[];
};
export type GetItemsApiArg = {
  /** Filter by active or inactive LPNs */
  active?: boolean;
  /** A partial item ID or lot number to search for */
  search?: string;
  /** Comma Separated Location IDs */
  locationIds?: string;
  /** Comma Separated Location names */
  locationNames?: string;
  /** Comma Separated Location types */
  locationTypes?: string;
  /** Comma Separated Location subtypes */
  locationSubtypes?: string;
  /** Return items that are either locked (true) or unlocked (false) */
  locked?: boolean;
  /** Filter by inbound_id of order related to item */
  inboundId?: string;
  /** Filter by inbound_id of order related to item */
  outboundId?: string;
  /** Comma Separated Product IDs */
  productIds?: string;
  /** Comma Separated Item IDs */
  itemIds?: string;
  /** Comma Separated License Plate IDs */
  licensePlateIds?: string;
  /** Comma Separated License Plate numbers */
  licensePlateNumbers?: string;
  /** Comma Separated Inbound Line IDs */
  inboundLineIds?: string;
  /** Return items inserted after Datetime (ISO-8601) */
  insertedAfterDatetime?: string;
  /** Return items inserted before Datetime (ISO-8601) */
  insertedBeforeDatetime?: string;
  /** Comma Separated Shipper IDs */
  shipperIds?: string;
  /** Comma Separated Skus */
  skus?: string;
  /** Lot number to filter by */
  lotNumber?: string;
  /** Manufacturer date to filter by */
  manufacturerDate?: string;
  /** Expiration date to filter by */
  expirationDate?: string;
  /** Return items that have expiration_date set */
  hasExpirationDate?: boolean;
  /** Return items that have manufacturer_date set */
  hasManufacturerDate?: boolean;
  /** include items without an lpn */
  includeOrphanItems?: boolean;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostItemsApiResponse = /** status 201 Created */ {
  data?: Item;
};
export type PostItemsApiArg = {
  /** Item Details and reason code for creating inventory */
  body: Item & {
    reason_code_id?: string;
    product_id?: string;
    license_plate_number?: string;
    location_id?: string;
  };
};
export type GetItemsByItemIdApiResponse = /** status 200 Retrieve Item details */ {
  data?: Item;
};
export type GetItemsByItemIdApiArg = {
  itemId: string;
};
export type PatchItemsByItemIdApiResponse = /** status 200 Updated */ {
  data?: Item;
};
export type PatchItemsByItemIdApiArg = {
  itemId: string;
  /** An Item/LPN */
  body: Item & {
    reason_code_id?: string;
    notes?: string;
  };
};
export type GetItemsByItemIdMovementsApiResponse = /** status 200 returns an array of Movement */ {
  meta?: Pagination;
  data?: Movement[];
};
export type GetItemsByItemIdMovementsApiArg = {
  itemId: string;
  userId?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostItemsByItemIdMovementsApiResponse =
  /** status 204 Created without response */ undefined;
export type PostItemsByItemIdMovementsApiArg = {
  itemId: string;
  /** Movement to add */
  movement: Movement;
};
export type GetItemsByItemIdAdjustmentsApiResponse = /** status 200 List Adjustments */ {
  meta?: Pagination;
  data?: Adjustment[];
};
export type GetItemsByItemIdAdjustmentsApiArg = {
  /** Item ID */
  itemId: string;
  /** Product ID */
  productId?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostItemsByItemIdAdjustmentsApiResponse = /** status 201 Created */ {
  data?: Adjustment;
};
export type PostItemsByItemIdAdjustmentsApiArg = {
  /** Item ID */
  itemId: string;
  /** Adjustment to add */
  body: Adjustment & {
    reason_code_id?: string;
  };
};
export type GetItemsDownloadApiResponse = /** status 200 Download a CSV of all items */ string;
export type GetItemsDownloadApiArg = {
  /** Filter by active or inactive LPNs */
  active?: boolean;
  /** A partial item ID or lot number to search for */
  search?: string;
  /** Comma Separated Location IDs */
  locationIds?: string;
  /** Comma Separated Location names */
  locationNames?: string;
  /** Comma Separated Location types */
  locationTypes?: string;
  /** Comma Separated Location subtypes */
  locationSubtypes?: string;
  /** Return items that are either locked (true) or unlocked (false) */
  locked?: boolean;
  /** Filter by inbound_id of order related to item */
  inboundId?: string;
  /** Filter by inbound_id of order related to item */
  outboundId?: string;
  /** Comma Separated Product IDs */
  productIds?: string;
  /** Comma Separated Item IDs */
  itemIds?: string;
  /** Comma Separated License Plate IDs */
  licensePlateIds?: string;
  /** Comma Separated License Plate numbers */
  licensePlateNumbers?: string;
  /** Comma Separated Inbound Line IDs */
  inboundLineIds?: string;
  /** Return items inserted after Datetime (ISO-8601) */
  insertedAfterDatetime?: string;
  /** Return items inserted before Datetime (ISO-8601) */
  insertedBeforeDatetime?: string;
  /** Comma Separated Shipper IDs */
  shipperIds?: string;
  /** Comma Separated Skus */
  skus?: string;
  /** Lot number to filter by */
  lotNumber?: string;
  /** Manufacturer date to filter by */
  manufacturerDate?: string;
  /** Expiration date to filter by */
  expirationDate?: string;
  /** Return items that have expiration_date set */
  hasExpirationDate?: boolean;
  /** Return items that have manufacturer_date set */
  hasManufacturerDate?: boolean;
  /** include items without an lpn */
  includeOrphanItems?: boolean;
  /** indicates to download template */
  template?: boolean;
};
export type PostItemsUploadApiResponse =
  /** status 202 Upload Items CSV Successfully completed */ undefined;
export type PostItemsUploadApiArg = {
  /** Bulk Items CSV file */
  body: object;
};
export type GetItemsLotNumbersApiResponse = /** status 200 returns list of unique lot numbers */ {
  meta?: Pagination;
  data?: string[];
};
export type GetItemsLotNumbersApiArg = {
  /** Comma separated list of shipper ids */
  shipperIds?: string;
  /** Comma separated list of product ids */
  productIds?: string;
  /** Lot number to search */
  lotNumber?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetMovementsApiResponse = /** status 200 returns an array of Movement */ {
  meta?: Pagination;
  data?: Movement[];
};
export type GetMovementsApiArg = {
  itemId?: string;
  productId?: string;
  licensePlateId?: string;
  sku?: string;
  userId?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetInboundsApiResponse = /** status 200 List Inbound Orders */ {
  meta?: Pagination;
  data?: InboundOrder[];
};
export type GetInboundsApiArg = {
  /** Order Status */
  status?: string;
  /** Product ID */
  productId?: string;
  /** Product SKU */
  sku?: string;
  /** External ID */
  externalId?: string;
  /** Comma separated list of inbound line ids */
  inboundLineIds?: string;
  /** use license plate id to get its associated inbounds */
  licensePlateId?: string;
  /** Return orders inserted after Datetime (ISO-8601) */
  insertedAfterDatetime?: string;
  /** Return orders inserted before Datetime (ISO-8601) */
  insertedBeforeDatetime?: string;
  /** Return orders received after Datetime (ISO-8601) */
  receivedAfterDatetime?: string;
  /** Return orders received before Datetime (ISO-8601) */
  receivedBeforeDatetime?: string;
  /** Return orders expected after Date (ISO-8601) */
  expectedAfterDate?: string;
  /** Return orders expected before Date (ISO-8601) */
  expectedBeforeDate?: string;
  /** Return orders put away after Datetime (ISO-8601) */
  putAwayAfterDatetime?: string;
  /** Return orders put away before Datetime (ISO-8601) */
  putAwayBeforeDatetime?: string;
  /** Comma separated list of shipper ids */
  shipperIds?: string;
  /** Search across id, external_id, external_order_number, and custom_reference */
  search?: string;
  channelName?: string;
  lotNumber?: string;
  /** Comma Separated Order Types */
  orderTypes?: string;
  source?: string;
  /** Filter inbounds that have unresolved exceptions */
  hasUnresolvedException?: boolean;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostInboundsApiResponse = /** status 201 Created */ {
  data?: InboundOrder;
};
export type PostInboundsApiArg = {
  /** An Inbound Order / Purchase Order */
  body: {
    carrier_name?: string;
    channel_name?: string;
    custom_reference?: string;
    expected_at_date?: string;
    expected_at_time?: string;
    external_id: string;
    external_order_number?: string;
    notes?: string;
    order_type: InboundOrderType;
    pallet_count?: number;
    return_postage_price_cents?: number | null;
    rma_number?: string;
    shipper_id: string;
    source?: string;
    supplier_address?: Address;
    tracking_number?: string;
    lines: {
      sku: string;
      uom: string;
      quantity: number;
      lot_number?: string;
      return_metadata?: ReturnMetadata;
      items?: {
        lpn: string;
        location_id: string;
        lot_number?: string;
        manufacturer_date?: string;
        expiration_date?: string;
        return_type?: string;
        conditions?: string[];
        disposition?: string;
      }[];
    }[];
  };
};
export type GetInboundsByInboundOrderIdApiResponse = /** status 200 Show Inbound Order */ {
  data?: InboundOrder;
};
export type GetInboundsByInboundOrderIdApiArg = {
  inboundOrderId: string;
};
export type PatchInboundsByInboundOrderIdApiResponse = /** status 200 Updated */ {
  data?: InboundOrder;
};
export type PatchInboundsByInboundOrderIdApiArg = {
  inboundOrderId: string;
  /** An Inbound Order / Purchase Order */
  inboundOrder: InboundOrder;
};
export type DeleteInboundsByInboundOrderIdApiResponse =
  /** status 204 Accepted without body */ string;
export type DeleteInboundsByInboundOrderIdApiArg = {
  inboundOrderId: string;
};
export type GetInboundsDownloadApiResponse = /** status 200 Download a CSV of Inbounds */ string;
export type GetInboundsDownloadApiArg = {
  /** Order Status */
  status?: string;
  /** Product ID */
  productId?: string;
  /** Product SKU */
  sku?: string;
  /** External ID */
  externalId?: string;
  /** Comma separated list of inbound line ids */
  inboundLineIds?: string;
  /** use license plate id to get its associated inbounds */
  licensePlateId?: string;
  /** Return orders inserted after Datetime (ISO-8601) */
  insertedAfterDatetime?: string;
  /** Return orders inserted before Datetime (ISO-8601) */
  insertedBeforeDatetime?: string;
  /** Return orders received after Datetime (ISO-8601) */
  receivedAfterDatetime?: string;
  /** Return orders received before Datetime (ISO-8601) */
  receivedBeforeDatetime?: string;
  /** Return orders expected after Date (ISO-8601) */
  expectedAfterDate?: string;
  /** Return orders expected before Date (ISO-8601) */
  expectedBeforeDate?: string;
  /** Return orders put away after Datetime (ISO-8601) */
  putAwayAfterDatetime?: string;
  /** Return orders put away before Datetime (ISO-8601) */
  putAwayBeforeDatetime?: string;
  /** Comma separated list of shipper ids */
  shipperIds?: string;
  /** Search across id, external_id, external_order_number, and custom_reference */
  search?: string;
  channelName?: string;
  lotNumber?: string;
  /** Comma Separated Order Types */
  orderTypes?: string;
  source?: string;
};
export type GetFintechCustomersApiResponse = /** status 200 List Customers */ {
  meta?: Pagination;
  data?: Customer[];
};
export type GetFintechCustomersApiArg = {
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
  /** Filter by comma-separated IDs */
  ids?: string;
  /** Filter by company_id */
  companyId?: string;
  /** Filter by stord_managed */
  stordManaged?: boolean;
  /** Search against name and external_id */
  search?: string;
};
export type GetFintechContractsApiResponse = /** status 200 List Contracts */ {
  meta?: Pagination;
  data?: Contract[];
};
export type GetFintechContractsApiArg = {
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
  /** Comma-separated list of company IDs */
  companyIds?: string;
  /** Comma-separated list of customer IDs */
  customerIds?: string;
  /** Comma-separated list of category IDs */
  categoryIds?: string;
  /** Filter by effective_at when effective_at is after the given datetime */
  effectiveAfter?: string;
  /** Filter by effective_at when effective_at is before the given datetime */
  effectiveBefore?: string;
  /** Filter by expires_at when expires_at is after the given datetime */
  expiresAfter?: string;
  /** Filter by expires_at when expires_at is before the given datetime */
  expiresBefore?: string;
  /** Contract status to filter on */
  status?: ContractStatus;
};
export type GetFintechBillingCategoriesApiResponse = /** status 200 List Billing Categories */ {
  meta?: Pagination;
  data?: BillingCategory[];
};
export type GetFintechBillingCategoriesApiArg = {
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
  /** Search against name and external_id */
  search?: string;
  /** Filter Billing Categories by ID */
  ids?: string;
};
export type GetFintechBillingCodesApiResponse = /** status 200 List Billing Codes */ {
  meta?: Pagination;
  data?: BillingCode[];
};
export type GetFintechBillingCodesApiArg = {
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
  /** Filter by active */
  active?: boolean;
  /** Filter by billing_category_id */
  billingCategoryId?: string;
  /** Search against name and external_id */
  search?: string;
  /** Filter Billing Codes by IDs */
  ids?: string;
};
export type GetFintechContractsByContractIdApiResponse =
  /** status 200 Retrieve Contract details */ {
    data?: Contract;
  };
export type GetFintechContractsByContractIdApiArg = {
  contractId: string;
};
export type GetFintechInvoicesApiResponse = /** status 200 List Invoices */ {
  meta?: Pagination;
  data?: Invoice[];
};
export type GetFintechInvoicesApiArg = {
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
  /** Filter by company_id */
  companyId?: string;
  /** Filter by customer_id */
  customerId?: string;
  /** Filter by ends_at when ends_at is after the given datetime */
  endsAfter?: string;
  /** Filter by ends_at when ends_at is before the given datetime */
  endsBefore?: string;
  /** Search against notes */
  search?: string;
  /** Filter by starts_at when starts_at is after the given datetime */
  startsAfter?: string;
  /** Filter by starts_at when starts_at is before the given datetime */
  startsBefore?: string;
  /** Filter by status */
  status?: string;
  /** Filter by amounts greater than the given value */
  amountGt?: number;
  /** Filter by amounts less than the given value */
  amountLt?: number;
};
export type PostFintechInvoicesApiResponse = /** status 201 Created */ {
  data?: Invoice;
};
export type PostFintechInvoicesApiArg = {
  /** Invoice to add */
  invoice: Invoice;
};
export type GetFintechInvoicesByInvoiceIdApiResponse = /** status 200 Retrieve Invoice details */ {
  data?: Invoice;
};
export type GetFintechInvoicesByInvoiceIdApiArg = {
  invoiceId: string;
};
export type PatchFintechInvoicesByInvoiceIdApiResponse = /** status 200 Updated Invoice */ {
  data?: Invoice;
};
export type PatchFintechInvoicesByInvoiceIdApiArg = {
  invoiceId: string;
  /** An Invoice */
  invoice: Invoice;
};
export type GetFintechInvoicesByInvoiceIdItemsApiResponse = /** status 200 List Invoice Items */ {
  meta?: Pagination;
  data?: InvoiceItem[];
};
export type GetFintechInvoicesByInvoiceIdItemsApiArg = {
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
  invoiceId: string;
  /** Filter by charged_at when charged_at is after the given datetime */
  chargedAfter?: string;
  /** Filter by charged_at when charged_at is before the given datetime */
  chargedBefore?: string;
  /** Filter by company_id */
  companyId?: string;
  /** Filter by invoice_id */
  invoice_id?: string;
  /** Filter by price_id */
  priceId?: string;
  /** Search against name */
  search?: string;
  /** Filter by amounts greater than the given value */
  amountGt?: number;
  /** Filter by amounts less than the given value */
  amountLt?: number;
};
export type PostFintechInvoicesByInvoiceIdItemsApiResponse = /** status 201 Created */ {
  data?: InvoiceItem;
};
export type PostFintechInvoicesByInvoiceIdItemsApiArg = {
  invoiceId: string;
  /** Invoice Item to add */
  invoiceItem: InvoiceItem;
};
export type GetFintechPricesApiResponse = /** status 200 List Prices */ {
  meta?: Pagination;
  data?: Price[];
};
export type GetFintechPricesApiArg = {
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
  /** Filter by company_id */
  companyId?: string;
  /** Filter by billing_code_id */
  billingCodeId?: string;
  /** Filter by contract_id */
  contractId?: string;
  /** Filter by currency */
  currency?: string;
  /** Filter by amounts greater than the given value */
  amountGt?: number;
  /** Filter by amounts less than the given value */
  amountLt?: number;
};
export type GetShippersByShipperNameInboundsAndOrderNumberApiResponse =
  /** status 200 Get Inbound Order By Purchase Order Number */ {
    data?: InboundOrder;
  };
export type GetShippersByShipperNameInboundsAndOrderNumberApiArg = {
  /** Shipper Name */
  shipperName: string;
  /** Inbound Purchase Order Number */
  orderNumber: string;
};
export type PatchShippersByShipperNameInboundsAndOrderNumberApiResponse =
  /** status 200 Updated */ {
    data?: InboundOrder;
  };
export type PatchShippersByShipperNameInboundsAndOrderNumberApiArg = {
  /** Shipper Name */
  shipperName: string;
  /** Inbound Purchase Order Number */
  orderNumber: string;
  /** An Inbound Order / Purchase Order */
  inboundOrder: InboundOrder;
};
export type DeleteShippersByShipperNameInboundsAndOrderNumberApiResponse =
  /** status 204 Accepted without body */ string;
export type DeleteShippersByShipperNameInboundsAndOrderNumberApiArg = {
  /** Shipper Name */
  shipperName: string;
  /** Inbound Purchase Order Number */
  orderNumber: string;
};
export type GetInboundsByInboundOrderIdLinesApiResponse =
  /** status 200 List InboundOrderLines for inboundOrderId */ {
    meta?: Pagination;
    data?: InboundOrderLine[];
  };
export type GetInboundsByInboundOrderIdLinesApiArg = {
  /** Inbound id */
  inboundOrderId: string;
  /** Comma Separated Product IDs */
  productIds?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetInboundsByInboundOrderIdItemsApiResponse =
  /** status 200 List inbound items for inboundOrderId */ {
    meta?: Pagination;
    data?: Item[];
  };
export type GetInboundsByInboundOrderIdItemsApiArg = {
  /** Inbound id */
  inboundOrderId: string;
  /** A partial item ID or lot number to search for */
  search?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetInboundsReceiptsApiResponse = /** status 200 List Receipts */ {
  data?: Receipt[];
};
export type GetInboundsReceiptsApiArg = {
  /** Comma separated list of inbound IDs */
  inboundIds?: string;
  /** Receipt Status */
  status?: 'open' | 'closed';
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetInboundsByInboundOrderIdReceiptsApiResponse = /** status 200 List Receipts */ {
  data?: Receipt[];
};
export type GetInboundsByInboundOrderIdReceiptsApiArg = {
  /** Inbound Order ID */
  inboundOrderId: string;
  /** Receipt Line Ids */
  receiptLinesIds?: string;
  /** Flag to include receipt lines which behaves true by default */
  includeReceiptLines?: boolean;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetInboundsByInboundOrderIdReceiptsAndReceiptIdApiResponse =
  /** status 200 Show Receipt */ {
    data?: Receipt;
  };
export type GetInboundsByInboundOrderIdReceiptsAndReceiptIdApiArg = {
  inboundOrderId: string;
  receiptId: string;
};
export type PatchInboundsByInboundOrderIdReceiptsAndReceiptIdApiResponse =
  /** status 200 Show Receipt */ {
    data?: Receipt;
  };
export type PatchInboundsByInboundOrderIdReceiptsAndReceiptIdApiArg = {
  inboundOrderId: string;
  receiptId: string;
  /** A Receipt */
  receipt: Receipt;
};
export type GetReceiptsByReceiptIdReceiptLinesApiResponse = /** status 200 List Receipt Lines */ {
  meta?: Pagination;
  data?: ReceiptLine[];
};
export type GetReceiptsByReceiptIdReceiptLinesApiArg = {
  /** Receipt ID */
  receiptId: string;
  /** Product ID (Single) to filter */
  productId?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetShippersByShipperNameInboundsAndExternalIdLinesApiResponse =
  /** status 200 List InboundOrderLines for an order */ {
    meta?: Pagination;
    data?: OutboundOrderLine[];
  };
export type GetShippersByShipperNameInboundsAndExternalIdLinesApiArg = {
  /** Shipper Name */
  shipperName: string;
  /** Inbound external ID */
  externalId: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetInboundsByInboundOrderIdLinesAndInboundLineIdApiResponse =
  /** status 200 Inbound Order Line */ {
    data?: InboundOrderLine;
  };
export type GetInboundsByInboundOrderIdLinesAndInboundLineIdApiArg = {
  /** Inbound Order ID */
  inboundOrderId: string;
  /** Inbound Order Line ID */
  inboundLineId: string;
};
export type PatchInboundsByInboundOrderIdLinesAndInboundLineIdApiResponse =
  /** status 200 Success */ {
    data?: InboundOrderLine;
  };
export type PatchInboundsByInboundOrderIdLinesAndInboundLineIdApiArg = {
  /** Inbound Order ID */
  inboundOrderId: string;
  /** Inbound Order Line ID */
  inboundLineId: string;
  /** An Inbound Order Line */
  body: {
    notes?: string;
  };
};
export type PostInboundsByInboundOrderIdLinesAndInboundLineIdLpnsApiResponse =
  /** status 201 Created */ Item;
export type PostInboundsByInboundOrderIdLinesAndInboundLineIdLpnsApiArg = {
  /** Inbound Order ID */
  inboundOrderId: string;
  /** Inbound Order Line ID */
  inboundLineId: string;
  /** An Inbound Order Line */
  body: Item & {
    location_id?: string;
    number?: string;
    received_at_date?: string;
  };
};
export type PatchInboundsByInboundOrderIdLinesAndInboundLineIdLpnsLicensePlateIdItemsItemIdApiResponse =
  /** status 200 Updated */ {
    data?: Item;
  };
export type PatchInboundsByInboundOrderIdLinesAndInboundLineIdLpnsLicensePlateIdItemsItemIdApiArg =
  {
    /** Inbound Order ID */
    inboundOrderId: string;
    /** Inbound Order Line ID */
    inboundLineId: string;
    /** License Plate ID */
    licensePlateId: string;
    /** Item ID */
    itemId: string;
    /** Item Updates */
    item: Item;
  };
export type DeleteInboundsByInboundOrderIdLinesAndInboundLineIdLpnsLicensePlateIdItemsItemIdApiResponse =
  /** status 204 Accepted without body */ string;
export type DeleteInboundsByInboundOrderIdLinesAndInboundLineIdLpnsLicensePlateIdItemsItemIdApiArg =
  {
    /** Inbound Order ID */
    inboundOrderId: string;
    /** Inbound Order Line ID */
    inboundLineId: string;
    /** License Plate ID */
    licensePlateId: string;
    /** Item ID */
    itemId: string;
  };
export type PostInboundsByInboundOrderIdRepublishApiResponse =
  /** status 204 Accepted without body */ string;
export type PostInboundsByInboundOrderIdRepublishApiArg = {
  /** Inbound Order ID */
  inboundOrderId: string;
};
export type PostInboundsByInboundOrderIdRepublishReceiptsApiResponse =
  /** status 204 Accepted without body */ string;
export type PostInboundsByInboundOrderIdRepublishReceiptsApiArg = {
  /** Inbound Order ID */
  inboundOrderId: string;
};
export type GetOverviewInboundApiResponse =
  /** status 200 Display overview stats for inbound orders */ {
    data?: InboundOverviewStats;
  };
export type GetOverviewInboundApiArg = {
  /** Comma separated list of shipper ids */
  shipperIds?: string;
};
export type GetOverviewOutboundApiResponse =
  /** status 200 Display overview stats for inbound orders */ {
    data?: OutboundOverviewStats;
  };
export type GetOverviewOutboundApiArg = {
  /** Comma separated list of shipper ids */
  shipperIds?: string;
  from: string;
  to: string;
};
export type PostLocationsApiResponse = /** status 201 Created */ {
  data?: Location;
};
export type PostLocationsApiArg = {
  /** Location to add */
  location: Location;
};
export type GetLocationsApiResponse = /** status 200 List of Locations.
If include_product_summary query parameter is passed with "true",
then product summary will return, sorted by SKU.
 */ {
  meta?: Pagination;
  data?: Location[];
};
export type GetLocationsApiArg = {
  active?: boolean;
  /** (DEPRECATED) A comma separated list of location types - use `types` instead */
  type?: string;
  /** A comma separated list of location types */
  types?: string;
  /** A comma separated list of location sub types */
  subtypes?: string;
  /** Location name, or partial name, or legacy barcode value to search for */
  search?: string;
  /** Comma separated Location IDs */
  locationIds?: string;
  /** Comma separated Shipper IDs associated with the items within locations */
  itemShipperIds?: string;
  /** Flag to include product summary for each location in response */
  includeProductSummary?: boolean;
  /** Comma separated names of locations to return */
  names?: string;
  /** Locations that have items that have the same associated product AND tracking fields */
  alikeItemId?: string;
  /** Filter for locations that have any LPNs based on locked status. */
  licensePlateLocked?: boolean;
  /** Comma separated list of pick equipment group ids */
  pickEquipmentGroupIds?: string;
  /** Filter for the location if it has cycle count */
  hasCycleCount?: boolean;
  /** Comma separated list of zone ids */
  zoneIds?: string;
  /** Comma separated list of product ids */
  productIds?: string;
  /** Whether product has a max capacity set */
  hasMaxCapacity?: boolean;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetLocationsByLocationIdApiResponse = /** status 200 Get Location Details */ {
  data?: Location;
};
export type GetLocationsByLocationIdApiArg = {
  /** Get Location by ID or Name */
  locationId: string;
};
export type PatchLocationsByLocationIdApiResponse = /** status 200 Update a Location */ Location;
export type PatchLocationsByLocationIdApiArg = {
  /** Location ID */
  locationId: string;
  /** A Location */
  location: Location;
};
export type GetLocationsByLocationIdAlternateApiResponse = /** status 200 Get Closest Locations */ {
  meta?: Pagination;
  data?: Location[];
};
export type GetLocationsByLocationIdAlternateApiArg = {
  /** Location ID */
  locationId: string;
  /** Product sku to filter by */
  sku?: string;
  /** Product shipper ID */
  shipperId?: string;
  /** Use item's associated product AND items' tracking fields to get alternate locations */
  alikeItemId?: string;
  /** Filter for locations that have any LPNs based on locked status. */
  licensePlateLocked?: boolean;
  /** Lot Number to filter by */
  lotNumber?: string;
  /** Should the lot number be an exact match */
  exactLotNumberRequired?: boolean;
  /** Minimum number of days until product expires */
  minShelfLifeDays?: number;
  /** outbound you are requesting an alternate pick for */
  outboundId?: string;
  /** work order you are requesting an alternate pick for */
  workOrderId?: string;
  /** Boolean to include current location in results */
  includeCurrentLocation?: boolean;
  /** Location name, or partial name, to search for */
  search?: string;
  /** Pick from location strategy to use when looking up locations */
  pickFromLocationStrategy?: PickLocationStrategy | PutawayLocationStrategy;
  /** Optional Zone ID to use when looking up locations */
  zoneId?: string;
  /** Only show locations that have at least the given capacity available */
  availableCapacity?: number;
  /** Filter results to locations that have a max capacity set (true) or unset (false) */
  hasMaxCapacity?: boolean;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetLocationsPickingApiResponse = /** status 200 Get Picking Location */ {
  data?: Location;
};
export type GetLocationsPickingApiArg = {
  /** Product Id */
  productId: string;
  /** Quantity required for pick */
  quantity: number;
};
export type GetLocationsDownloadApiResponse = /** status 200 Download a CSV of Locations */ string;
export type GetLocationsDownloadApiArg = {
  active?: boolean;
  /** (DEPRECATED) A comma separated list of location types - use `types` instead */
  type?: string;
  /** A comma separated list of location types */
  types?: string;
  /** A comma separated list of location sub types */
  subtypes?: string;
  /** Location name, or partial name, or legacy barcode value to search for */
  search?: string;
  /** Comma separated Location IDs */
  locationIds?: string;
  /** Comma separated Shipper IDs associated with the items within locations */
  itemShipperIds?: string;
  /** Flag to include product summary for each location in response */
  includeProductSummary?: boolean;
  /** Comma separated names of locations to return */
  names?: string;
  /** Locations that have items that have the same associated product AND tracking fields */
  alikeItemId?: string;
  /** Filter for locations that have any LPNs based on locked status. */
  licensePlateLocked?: boolean;
  /** Comma separated list of pick equipment group ids */
  pickEquipmentGroupIds?: string;
  /** Filter for the location if it has cycle count */
  hasCycleCount?: boolean;
  /** Comma separated list of zone ids */
  zoneIds?: string;
  /** indicates to download a template */
  template?: boolean;
};
export type PostLocationsUploadApiResponse = /** status 202 Upload Locations CSV */ undefined;
export type PostLocationsUploadApiArg = {
  /** CSV file */
  body: object;
};
export type PatchLocationsBulkAssignZoneApiResponse =
  /** status 204 Assigned zone to locations */ undefined;
export type PatchLocationsBulkAssignZoneApiArg = {
  /** Bulk assign a zone to multiple locations */
  body: {
    zone_id?: string | null;
    location_ids?: string[];
  };
};
export type GetLocationsEmptyPutawayLocationsApiResponse =
  /** status 200 Get Empty Putaway Locations based on */ {
    meta?: Pagination;
    data?: Location[];
  };
export type GetLocationsEmptyPutawayLocationsApiArg = {
  /** Shipper ID */
  shipperId?: string;
  /** Filter results by location strategy */
  putawayLocationStrategy?: PutawayLocationStrategy;
  /** Filter by zone_id if putaway_location_strategy is zone_specific */
  putawayLocationZoneId?: string;
  /** Only show locations that have at least the given capacity available */
  availableCapacity?: number;
  /** Filter results to locations that have a max capacity set (true) or unset (false) */
  hasMaxCapacity?: boolean;
  page?: number;
  pageSize?: number;
};
export type PostBroadcastsApiResponse = /** status 201 Created */ {
  data?: {
    success?: boolean;
  };
};
export type PostBroadcastsApiArg = {
  /** A broadcast notification to send */
  broadcastNotification: BroadcastNotification;
};
export type GetOutboundsApiResponse = /** status 200 List Outbound Orders */ {
  meta?: Pagination;
  data?: OutboundOrder[];
};
export type GetOutboundsApiArg = {
  /** Order Status */
  status?: string;
  /** Shipment Type (i.e freight, parcel) */
  shipmentType?: OutboundShipmentType;
  /** Product ID; use product_ids instead */
  productId?: string;
  /** Location ID */
  locationId?: string;
  /** Comma separated list of outbound IDs */
  outboundIds?: string;
  /** Comma separated list of item IDs */
  itemIds?: string;
  /** Fulfillable */
  fulfillable?: boolean;
  /** allocatable */
  allocatable?: boolean;
  /** Filters by a combination of allocatable and fulfillable */
  availability?: OutboundAvailability;
  /** Comma separated list of shipper ids */
  shipperIds?: string;
  /** Return orders inserted after Datetime (ISO-8601) */
  insertedAfterDatetime?: string;
  /** Return orders inserted before Datetime (ISO-8601) */
  insertedBeforeDatetime?: string;
  /** Return orders shipped after Datetime (ISO-8601) */
  shippedAfterDatetime?: string;
  /** Return orders shipped before Datetime (ISO-8601) */
  shippedBeforeDatetime?: string;
  /** Carrier that shipped the outbound order */
  shippingCarrier?: string;
  /** Return orders expected to ship after datetime (ISO-8601) */
  expectedShippedAtAfterDatetime?: string;
  /** Return orders expected to ship before datetime (ISO-8601) */
  expectedShippedAtBeforeDatetime?: string;
  /** Returns orders that must ship by the specified datetime. */
  mustShipByDatetime?: string;
  /** Comma Separated Skus */
  skus?: string;
  /** Filter orders by parcel tracking number */
  trackingNumber?: string;
  /** Search across id, external_id, external_order_number, and custom_reference */
  search?: string;
  /** Filter outbounds by provided picking strategy */
  pickStrategy?: any;
  /** Filter outbounds by provided packing strategy */
  packStrategy?: any;
  /** Assigned User */
  assignedUserId?: string;
  /** Tags to filter orders by */
  tags?:
    | string
    | {
        incl?: string;
        excl?: string;
        eq?: string;
      };
  /** Product Ids to filter orders by */
  productIds?:
    | string
    | {
        incl?: string;
        excl?: string;
      };
  channelName?: string;
  /** Channel Names to filter by (exact match) */
  channelNames?:
    | string
    | {
        incl?: string;
        excl?: string;
      };
  /** Comma Separated Channel Categories */
  channelCategories?: string;
  /** Comma Separated Order Types */
  orderTypes?: string;
  /** Return orders with order lines with requested lot numbers matching search string */
  requestedLotNumber?: string;
  /** Return orders that were picked from this lot matching search string */
  pickedLotNumber?: string;
  /** Return orders that were picked from this lpn */
  pickedFromLpn?: string;
  /** Return orders that were packed from this lpn */
  packedFromLpn?: string;
  /** Comma separated serial numbers to filter outbounds by */
  serialNumbers?: string;
  serialNumberId?: string;
  /** comma separated license plate ids to filter outbounds by */
  licensePlateIds?: string;
  /** Filter outbounds that have unresolved exceptions */
  hasUnresolvedException?: boolean;
  /** Returns outbounds matching the provided priority */
  priority?: number;
  /** Comma separated list of outbounds priorities */
  priorities?: string;
  /** Return orders externally created after Datetime (ISO-8601) */
  externallyCreatedAfterDatetime?: string;
  /** Return orders externally created before Datetime (ISO-8601) */
  externallyCreatedBeforeDatetime?: string;
  /** Returns orders with the specified number of lines */
  numberOfLines?:
    | number
    | {
        gte?: number;
        lte?: number;
        eq?: number;
      };
  /** Returns orders with the specified total quantity across all lines (DEPRECATED: see order_line_total_quantity) */
  orderLineTotal?:
    | number
    | {
        gte?: number;
        lte?: number;
        eq?: number;
      };
  /** Returns orders with the specified total quantity across all lines */
  orderLineTotalQuantity?:
    | number
    | {
        gte?: number;
        lte?: number;
        eq?: number;
      };
  /** Filter outbounds that have been streamed */
  isStreamed?: boolean;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostOutboundsApiResponse = /** status 201 Created */ {
  data?: OutboundOrder;
};
export type PostOutboundsApiArg = {
  /** An Outbound Order to be created */
  body: {
    bill_to?: Address;
    business_days_in_transit?: number;
    carrier_processing_external_id?: string;
    carrier_service_method?: string;
    carrier_ship_option?: string;
    desired_delivery_date?: string;
    channel_category?: OutboundOrderChannelCategory;
    channel_name?: string;
    custom_reference?: string;
    expected_shipped_at_date?: string;
    expected_shipped_at_time?: string;
    external_bol_number?: string;
    external_id: string;
    external_order_number?: string;
    gift_message?: string;
    notes?: string;
    order_type: OutboundOrderType;
    planned_carrier_service?: string;
    priority?: number;
    scac?: string;
    shipment_type: OutboundShipmentType;
    shipping_address: Address;
    shipping_carrier?: string;
    shipping_code?: string;
    tags?: string[];
    shipper_id: string;
    lines: {
      sku: string;
      uom: string;
      quantity: number;
    }[];
  };
};
export type GetOutboundsDownloadApiResponse =
  /** status 200 Download a CSV of outbound orders */ string;
export type GetOutboundsDownloadApiArg = {
  /** Order Status */
  status?: string;
  /** Shipment Type (i.e freight, parcel) */
  shipmentType?: OutboundShipmentType;
  /** Product ID; use product_ids instead */
  productId?: string;
  /** Location ID */
  locationId?: string;
  /** Comma separated list of outbound IDs */
  outboundIds?: string;
  /** Comma separated list of item IDs */
  itemIds?: string;
  /** Fulfillable */
  fulfillable?: boolean;
  /** Comma separated list of shipper ids */
  shipperIds?: string;
  /** Return orders inserted after Datetime (ISO-8601) */
  insertedAfterDatetime?: string;
  /** Return orders inserted before Datetime (ISO-8601) */
  insertedBeforeDatetime?: string;
  /** Return orders shipped after Datetime (ISO-8601) */
  shippedAfterDatetime?: string;
  /** Return orders shipped before Datetime (ISO-8601) */
  shippedBeforeDatetime?: string;
  /** Carrier that shipped the outbound order */
  shippingCarrier?: string;
  /** Return orders expected to ship after datetime (ISO-8601) */
  expectedShippedAtAfterDatetime?: string;
  /** Return orders expected to ship before datetime (ISO-8601) */
  expectedShippedAtBeforeDatetime?: string;
  /** Returns orders that must ship by the specified datetime. */
  mustShipByDatetime?: string;
  /** Comma Separated Skus */
  skus?: string;
  /** Filter orders by parcel tracking number */
  trackingNumber?: string;
  /** Search across id, external_id, external_order_number, and custom_reference */
  search?: string;
  /** Tags to filter orders by */
  tags?:
    | string
    | {
        incl?: string;
        excl?: string;
        eq?: string;
      };
  /** Product Ids to filter orders by */
  productIds?:
    | string
    | {
        incl?: string;
        excl?: string;
      };
  channelName?: string;
  /** Channel Names to filter by (exact match) */
  channelNames?:
    | string
    | {
        incl?: string;
        excl?: string;
      };
  /** Comma Separated Channel Categories */
  channelCategories?: string;
  /** Comma Separated Order Types */
  orderTypes?: string;
  /** Return orders with order lines with requested lot numbers matching search string */
  requestedLotNumber?: string;
  /** Return orders that were picked from this lot matching search string */
  pickedLotNumber?: string;
  /** Return orders that were picked from this lpn */
  pickedFromLpn?: string;
  /** Return orders that were packed from this lpn */
  packedFromLpn?: string;
  /** Comma separated serial numbers to filter outbounds by */
  serialNumbers?: string;
  serialNumberId?: string;
  /** comma separated license plate ids to filter outbounds by */
  licensePlateIds?: string;
  /** Filter outbounds that have unresolved exceptions */
  hasUnresolvedException?: boolean;
  /** Returns outbounds matching the provided priority */
  priority?: number;
  /** Comma separated list of outbounds priorities */
  priorities?: string;
  /** Return orders externally created after Datetime (ISO-8601) */
  externallyCreatedAfterDatetime?: string;
  /** Return orders externally created before Datetime (ISO-8601) */
  externallyCreatedBeforeDatetime?: string;
  /** Returns orders with the specified number of lines */
  numberOfLines?:
    | number
    | {
        gte?: number;
        lte?: number;
        eq?: number;
      };
  /** Returns orders with the specified total quantity across all lines (DEPRECATED: see order_line_total_quantity) */
  orderLineTotal?:
    | number
    | {
        gte?: number;
        lte?: number;
        eq?: number;
      };
  /** Returns orders with the specified total quantity across all lines */
  orderLineTotalQuantity?:
    | number
    | {
        gte?: number;
        lte?: number;
        eq?: number;
      };
  /** Filter outbounds that have been streamed */
  isStreamed?: boolean;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetOutboundsByOrderIdApiResponse = /** status 200 Show Outbound Order */ {
  data?: OutboundOrder;
};
export type GetOutboundsByOrderIdApiArg = {
  orderId: string;
};
export type PatchOutboundsByOrderIdApiResponse = /** status 200 Updated */ {
  data?: OutboundOrder;
};
export type PatchOutboundsByOrderIdApiArg = {
  orderId: string;
  /** Outbound Order to be updated */
  outboundOrder: OutboundOrder;
};
export type DeleteOutboundsByOrderIdApiResponse = /** status 204 Accepted without body */ string;
export type DeleteOutboundsByOrderIdApiArg = {
  orderId: string;
};
export type PostOutboundsByOrderIdShipmentsApiResponse = /** status 201 Created */ {
  data?: Shipment;
};
export type PostOutboundsByOrderIdShipmentsApiArg = {
  orderId: string;
  /** Shipment to create */
  shipment: Shipment;
};
export type PatchOutboundsByOrderIdShipmentsAndShipmentIdApiResponse = /** status 200 Updated */ {
  data?: Shipment;
};
export type PatchOutboundsByOrderIdShipmentsAndShipmentIdApiArg = {
  orderId: string;
  shipmentId: string;
  /** A Shipment */
  shipment: Shipment;
};
export type GetShippersByShipperNameOutboundsAndOrderNumberApiResponse =
  /** status 200 Get Outbound Order By Order Number */ {
    data?: OutboundOrder;
  };
export type GetShippersByShipperNameOutboundsAndOrderNumberApiArg = {
  /** Shipper Name */
  shipperName: string;
  /** Outbound Order Number */
  orderNumber: string;
};
export type PatchShippersByShipperNameOutboundsAndOrderNumberApiResponse =
  /** status 200 Updated */ {
    data?: OutboundOrder;
  };
export type PatchShippersByShipperNameOutboundsAndOrderNumberApiArg = {
  /** Shipper Name */
  shipperName: string;
  /** Outbound Order Number */
  orderNumber: string;
  /** Outbound Order to be updated */
  outboundOrder: OutboundOrder;
};
export type DeleteShippersByShipperNameOutboundsAndOrderNumberApiResponse =
  /** status 204 Accepted without body */ string;
export type DeleteShippersByShipperNameOutboundsAndOrderNumberApiArg = {
  /** Shipper Name */
  shipperName: string;
  /** Outbound Order Number */
  orderNumber: string;
};
export type PostOutboundsStageApiResponse = /** status 204 Stage request accepted */ undefined;
export type PostOutboundsStageApiArg = {
  /** Stage data */
  body: {
    original_location_id?: string;
    destination_location_id?: string;
  };
};
export type GetOutboundsByOrderIdLinesApiResponse =
  /** status 200 List OutboundOrderLines for orderId */ {
    meta?: Pagination;
    data?: OutboundOrderLine[];
  };
export type GetOutboundsByOrderIdLinesApiArg = {
  /** Outbound id */
  orderId: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetShippersByShipperNameOutboundsAndExternalIdLinesApiResponse =
  /** status 200 List OutboundOrderLines for an order */ {
    meta?: Pagination;
    data?: OutboundOrderLine[];
  };
export type GetShippersByShipperNameOutboundsAndExternalIdLinesApiArg = {
  /** Shipper Name */
  shipperName: string;
  /** Outbound external ID */
  externalId: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostShippersByShipperNameOutboundsAndExternalIdLinesApiResponse =
  /** status 204 Lines Updated */ undefined;
export type PostShippersByShipperNameOutboundsAndExternalIdLinesApiArg = {
  /** Shipper Name */
  shipperName: string;
  /** Outbound external ID */
  externalId: string;
  /** Lines Data */
  body: {
    external_id?: string;
    shipper_id?: string;
    lines?: {
      qyt_delta?: number;
      sku?: string;
      uom?: string;
      external_line_id?: string;
      lot_number?: string;
    }[];
  };
};
export type GetOutboundsLineItemsApiResponse =
  /** status 200 List Outbound Line Items for Outbound Id */ {
    meta?: Pagination;
    data?: OutboundOrderLineItem[];
  };
export type GetOutboundsLineItemsApiArg = {
  /** Batch Id */
  batchId?: string;
  /** A space separated list of location ids */
  locationIds?: string;
  /** Outbound Id */
  outboundId?: string;
  /** Product Id */
  productId?: string;
  /** Outbound Line Id */
  outboundLineId?: string;
  /** A space separated list of item ids */
  itemIds?: string;
  /** A comma separated list of license plate ids */
  licensePlateIds?: string;
  /** A space separated list of pick ids */
  pickIds?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetOutboundsByOrderIdLineItemsApiResponse =
  /** status 200 List Outbound Line Items for Outbound Id */ {
    meta?: Pagination;
    data?: OutboundOrderLineItem[];
  };
export type GetOutboundsByOrderIdLineItemsApiArg = {
  /** Outbound id */
  orderId: string;
  /** A space separated list of location ids */
  locationIds?: string;
  /** Outbound Line Id */
  outboundLineId?: string;
  /** A space separated list of item ids */
  itemIds?: string;
  /** A space separated list of pick ids */
  pickIds?: string;
  /** A comma separated list of license plate ids */
  licensePlateIds?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostOutboundsByOrderIdLinesAndOutboundLineIdPickApiResponse =
  /** status 204 Pick completed */ undefined;
export type PostOutboundsByOrderIdLinesAndOutboundLineIdPickApiArg = {
  /** Outbound id */
  orderId: string;
  /** Outbound line id */
  outboundLineId: string;
  /** Pick data */
  body: {
    new_lpn?: string;
    donor_license_plate_id?: string;
    donor_license_plate_number?: string;
    location_id?: string;
    location_name?: string;
    quantity?: number;
    pick_id?: string;
    product_uom_id?: string;
  };
};
export type PostOutboundsByOrderIdRepublishApiResponse =
  /** status 204 Accepted without body */ string;
export type PostOutboundsByOrderIdRepublishApiArg = {
  /** Outbound id */
  orderId: string;
};
export type PostOutboundsUnpickApiResponse = /** status 204 Accepted without body */ string;
export type PostOutboundsUnpickApiArg = {
  /** Unpick LPN from Outbound Order */
  body: {
    license_plate_id?: string;
    donor_item_ids?: string;
    quantity?: number;
    destination_location_id: string;
    destination_lpn?: string;
  };
};
export type PostOutboundsUnbatchApiResponse = /** status 204 Accepted without body */ string;
export type PostOutboundsUnbatchApiArg = {
  /** Bulk Unbatch for Outbounds */
  body: {
    ids?: string[];
  };
};
export type GetOutboundsChannelNamesApiResponse = /** status 200 List Channel Names */ {
  data?: string[];
};
export type GetOutboundsChannelNamesApiArg = {
  /** Comma separated list of shipper ids */
  shipperIds?: string;
};
export type GetOutboundsShippingCarriersApiResponse = /** status 200 List Shipping Carriers */ {
  data?: string[];
};
export type GetOutboundsShippingCarriersApiArg = {
  /** Comma separated list of shipper ids */
  shipperIds?: string;
};
export type GetOutboundsTagsApiResponse = /** status 200 List Tags */ {
  data?: string[];
};
export type GetOutboundsTagsApiArg = {
  /** Comma separated list of shipper ids */
  shipperIds?: string;
};
export type GetPicksApiResponse = /** status 200 List existing Picks */ {
  meta?: Pagination;
  data?: Pick[];
};
export type GetPicksApiArg = {
  /** Comma separated list of location ids */
  locationIds?: string;
  /** Comma separated list of product ids */
  productIds?: string;
  /** Filter by completed status */
  isCompleted?: boolean;
  /** Filter by pick path location entity (this is NOT the location id) */
  pickPathLocationId?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetPickEquipmentGroupsApiResponse =
  /** status 200 List existing picking equipment groups */ {
    data?: PickEquipmentGroup[];
  };
export type GetPickEquipmentGroupsApiArg = {
  /** Filter by shipper_id or equipment groups that apply to all shippers */
  shipperId?: string;
  /** Search by name */
  search?: string;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostPickEquipmentGroupsApiResponse = /** status 201 Created */ {
  data?: PickEquipmentGroup;
};
export type PostPickEquipmentGroupsApiArg = {
  /** pick equipment group to add */
  body: {
    description?: string;
    name: string;
    is_enabled_for_all_shippers: boolean;
    shipper_ids: string[];
  };
};
export type DeletePickEquipmentGroupsByPickEquipmentGroupIdApiResponse =
  /** status 204 Accepted without body */ string;
export type DeletePickEquipmentGroupsByPickEquipmentGroupIdApiArg = {
  pickEquipmentGroupId: string;
};
export type GetPickEquipmentGroupsByPickEquipmentGroupIdApiResponse =
  /** status 200 Show Pick Equipment Group */ {
    data?: PickEquipmentGroup;
  };
export type GetPickEquipmentGroupsByPickEquipmentGroupIdApiArg = {
  pickEquipmentGroupId: string;
};
export type PatchPickEquipmentGroupsByPickEquipmentGroupIdApiResponse = /** status 200 Updated */ {
  data?: PickEquipmentGroup;
};
export type PatchPickEquipmentGroupsByPickEquipmentGroupIdApiArg = {
  pickEquipmentGroupId: string;
  /** pick equipment group updates */
  pickEquipmentGroup: PickEquipmentGroup;
};
export type PatchPickByPickIdApiResponse = /** status 200 Updated */ {
  data?: Pick;
};
export type PatchPickByPickIdApiArg = {
  /** Pick Id */
  pickId: string;
  /** pick updates */
  pick: Pick;
};
export type DeletePickByPickIdApiResponse = /** status 204 Accepted without body */ string;
export type DeletePickByPickIdApiArg = {
  pickId: string;
};
export type GetProductsApiResponse = /** status 200 List existing Products */ {
  meta?: Pagination;
  data?: Product[];
};
export type GetProductsApiArg = {
  /** A comma separated list of product skus */
  skus?: string;
  /** Query by various product identifiers for an exact match (upc, vpn, associated barcodes) */
  productIdentifier?: string;
  /** A lot number search string to identify products with inventory in a given lot */
  lotNumber?: string;
  /** A partial sku, name, or upc/barcode to search for */
  search?: string;
  /** Return orders inserted after Datetime (ISO-8601) */
  insertedAfterDatetime?: string;
  /** Return orders inserted before Datetime (ISO-8601) */
  insertedBeforeDatetime?: string;
  /** Comma Separated Shipper IDs */
  shipperIds?: string;
  /** Comma Separated Product IDs */
  productIds?: string;
  /** Flag to include location summary for each product in response */
  includeLocationSummary?: boolean;
  /** Flag to show only kits, or only products without kits in response */
  kit?: boolean;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostProductsApiResponse = /** status 201 Created */ {
  data?: Product;
};
export type PostProductsApiArg = {
  /** Product to add */
  body: Product & {
    unit_of_measure_id?: string;
  };
};
export type PatchProductsApiResponse = /** status 200 Updated */ {
  data?: Product;
};
export type PatchProductsApiArg = {
  /** A product sku */
  sku?: string;
  /** Product updates */
  product: Product;
};
export type GetProductsByProductIdApiResponse = /** status 200 Retrieve Product details */ {
  data?: Product;
};
export type GetProductsByProductIdApiArg = {
  /** Product ID */
  productId: string;
};
export type DeleteProductsByProductIdApiResponse = /** status 204 Accepted without body */ string;
export type DeleteProductsByProductIdApiArg = {
  productId: string;
};
export type PatchProductsBySkuApiResponse = /** status 200 Retrieve Product details */ {
  data?: Product;
};
export type PatchProductsBySkuApiArg = {
  /** Product SKU */
  sku: string;
  /** shipper id */
  shipperId: string;
  /** Product updates */
  product: Product;
};
export type GetShippersByShipperNameProductsAndSkuApiResponse =
  /** status 200 Retrieve Product details */ {
    data?: Product;
  };
export type GetShippersByShipperNameProductsAndSkuApiArg = {
  /** Shipper Name */
  shipperName: string;
  /** Product Sku */
  sku: string;
};
export type PatchShippersByShipperNameProductsAndSkuApiResponse = /** status 200 Updated */ {
  data?: Product;
};
export type PatchShippersByShipperNameProductsAndSkuApiArg = {
  /** A product sku */
  sku: string;
  /** The shipper name */
  shipperName: string;
  /** Product updates */
  product: Product;
};
export type GetShippersByShipperNameProductsAndSkuLotNumbersApiResponse =
  /** status 200 Retrieve Product Lot Number details */ {
    meta?: Pagination;
    data?: ProductLotNumber[];
  };
export type GetShippersByShipperNameProductsAndSkuLotNumbersApiArg = {
  /** Shipper Name */
  shipperName: string;
  /** Product Sku */
  sku: string;
  /** comma seperated product_ids to filter by */
  productIds?: string;
  /** case sensitive lot numbers to filter by */
  lotNumbers?: string;
  /** whether lot number is approved */
  approved?: boolean;
  /** comma seperated shipper_ids to filter by */
  shipperIds?: string;
  /** inserted or updated user id to filter by */
  userId?: string;
  /** updated user id to filter by */
  updatedByUserId?: string;
  /** inserted user id to filter by */
  insertedByUserId?: string;
  /** Return lot numbers inserted after Datetime (ISO-8601) */
  insertedAfterDatetime?: string;
  /** Return lot numbers inserted before Datetime (ISO-8601) */
  insertedBeforeDatetime?: string;
  /** Return lot numbers updated after Datetime (ISO-8601) */
  updatedAfterDatetime?: string;
  /** Return lot numbers updated before Datetime (ISO-8601) */
  updatedBeforeDatetime?: string;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostShippersByShipperNameProductsAndSkuLotNumbersApiResponse =
  /** status 201 Created */ {
    data?: Product;
  };
export type PostShippersByShipperNameProductsAndSkuLotNumbersApiArg = {
  /** A product sku */
  sku: string;
  /** The shipper name */
  shipperName: string;
  /** Managed Product Lot Number */
  productLotNumber: ProductLotNumber;
};
export type PatchShippersByShipperNameProductsAndSkuLotNumbersLotNumberApiResponse =
  /** status 200 Product Lot Number details */ {
    data?: ProductLotNumber;
  };
export type PatchShippersByShipperNameProductsAndSkuLotNumbersLotNumberApiArg = {
  /** Shipper Name */
  shipperName: string;
  /** Product Sku */
  sku: string;
  /** Product Lot Number */
  lotNumber: string;
  /** api to approve/unapprove lot numbers */
  body: {
    approved?: boolean;
  };
};
export type GetProductLotNumbersApiResponse =
  /** status 200 Retrieve Product Lot Number details */ {
    meta?: Pagination;
    data?: ProductLotNumber[];
  };
export type GetProductLotNumbersApiArg = {
  /** comma seperated product_ids to filter by */
  productIds?: string;
  /** case sensitive lot numbers to filter by */
  lotNumbers?: string;
  /** whether lot number is approved */
  approved?: boolean;
  /** comma seperated shipper_ids to filter by */
  shipperIds?: string;
  /** inserted or updated user id to filter by */
  userId?: string;
  /** updated user id to filter by */
  updatedByUserId?: string;
  /** inserted user id to filter by */
  insertedByUserId?: string;
  /** Return lot numbers inserted after Datetime (ISO-8601) */
  insertedAfterDatetime?: string;
  /** Return lot numbers inserted before Datetime (ISO-8601) */
  insertedBeforeDatetime?: string;
  /** Return lot numbers updated after Datetime (ISO-8601) */
  updatedAfterDatetime?: string;
  /** Return lot numbers updated before Datetime (ISO-8601) */
  updatedBeforeDatetime?: string;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetSerialNumbersApiResponse = /** status 200 List Serial Numbers */ {
  meta?: Pagination;
  data?: SerialNumber[];
};
export type GetSerialNumbersApiArg = {
  /** A comma separated list of item ids to filter by */
  itemIds?: string;
  /** A comma separated list of numbers to filter by */
  numbers?: string;
  /** A comma separated list of parcel ids to filter by */
  parcelIds?: string;
  /** A comma separated list of product ids to filter by */
  productIds?: string;
  /** A comma separated list of LPNs to filter by */
  licensePlateNumbers?: string;
  /** A partial serial number to search for */
  search?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostSerialNumbersApiResponse = /** status 201 Created */ {
  data?: SerialNumber;
};
export type PostSerialNumbersApiArg = {
  /** Serial Number to Add */
  serialNumber: SerialNumber;
};
export type DeleteSerialNumbersBySerialNumberIdApiResponse =
  /** status 204 Accepted without body */ string;
export type DeleteSerialNumbersBySerialNumberIdApiArg = {
  serialNumberId: string;
};
export type GetSerialNumbersBySerialNumberIdApiResponse =
  /** status 200 Retrieved Serial Number */ {
    data?: SerialNumber;
  };
export type GetSerialNumbersBySerialNumberIdApiArg = {
  serialNumberId: string;
};
export type GetAdjustmentsApiResponse = /** status 200 List Adjustments */ {
  meta?: Pagination;
  data?: Adjustment[];
};
export type GetAdjustmentsApiArg = {
  /** Item ID */
  itemId?: string;
  /** Item IDs */
  itemIds?: string;
  /** Product ID */
  productId?: string;
  /** LicensePlate ID */
  licensePlateId?: string;
  /** Search term for sku or license plate number */
  search?: string;
  /** Product SKU */
  productSku?: string;
  /** Shipper id */
  shipperId?: string;
  /** Return orders inserted after Datetime (ISO-8601) */
  insertedAfterDatetime?: string;
  /** Return orders inserted before Datetime (ISO-8601) */
  insertedBeforeDatetime?: string;
  /** Reason Code ID */
  reasonCodeId?: string;
  /** User ID */
  userId?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetProductsByProductIdAdjustmentsApiResponse =
  /** status 200 List Product Adjustments */ {
    meta?: Pagination;
    data?: Adjustment[];
  };
export type GetProductsByProductIdAdjustmentsApiArg = {
  /** Product ID */
  productId: string;
  /** (DEPRECATED) Item Id */
  itemId?: string;
  /** A comma separated list of item IDs */
  itemIds?: string;
  /** LicensePlate ID */
  licensePlateId?: string;
  /** Search term for sku or license plate number */
  search?: string;
  /** Return orders inserted after Datetime (ISO-8601) */
  insertedAfterDatetime?: string;
  /** Return orders inserted before Datetime (ISO-8601) */
  insertedBeforeDatetime?: string;
  /** Reason Code ID */
  reasonCodeId?: string;
  /** User ID */
  userId?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetShippersByShipperNameProductsAndSkuAdjustmentsApiResponse =
  /** status 200 List Product Adjustments */ {
    meta?: Pagination;
    data?: Adjustment[];
  };
export type GetShippersByShipperNameProductsAndSkuAdjustmentsApiArg = {
  /** Shipper Name */
  shipperName: string;
  /** Product SKU */
  sku: string;
  /** (DEPRECATED) Item Id */
  itemId?: string;
  /** A comma separated list of item IDs */
  itemIds?: string;
  /** Product ID */
  productId?: string;
  /** LicensePlate ID */
  licensePlateId?: string;
  /** Search term for sku or license plate number */
  search?: string;
  /** Return orders inserted after Datetime (ISO-8601) */
  insertedAfterDatetime?: string;
  /** Return orders inserted before Datetime (ISO-8601) */
  insertedBeforeDatetime?: string;
  /** Reason Code ID */
  reasonCodeId?: string;
  /** User ID */
  userId?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetProductsByProductIdMovementsApiResponse =
  /** status 200 returns an array of Movement */ {
    meta?: Pagination;
    data?: Movement[];
  };
export type GetProductsByProductIdMovementsApiArg = {
  productId: string;
  userId?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetShippersByShipperNameProductsAndSkuMovementsApiResponse =
  /** status 200 returns an array of Movement */ {
    meta?: Pagination;
    data?: Movement[];
  };
export type GetShippersByShipperNameProductsAndSkuMovementsApiArg = {
  /** Shipper Name */
  shipperName: string;
  /** Product SKU */
  sku: string;
  userId?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetShippersByShipperNameProductsAndSkuLocationSummaryApiResponse =
  /** status 200 Retrieve Product Location Summary data */ {
    data?: ProductLocationSummary;
  };
export type GetShippersByShipperNameProductsAndSkuLocationSummaryApiArg = {
  /** Shipper Name */
  shipperName: string;
  /** Product SKU */
  sku: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetShippersByShipperNameWorkOrdersAndNumberApiResponse =
  /** status 200 A Work Order */ {
    data?: WorkOrder;
  };
export type GetShippersByShipperNameWorkOrdersAndNumberApiArg = {
  /** Shipper Name */
  shipperName: string;
  /** Work order number */
  number: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetProductsByProductIdOutboundsApiResponse =
  /** status 200 returns an array of Outbounds filtered by product */ {
    meta?: Pagination;
    data?: OutboundOrder[];
  };
export type GetProductsByProductIdOutboundsApiArg = {
  productId: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetProductsDownloadApiResponse = /** status 200 Download a CSV of products */ string;
export type GetProductsDownloadApiArg = {
  /** Comma separated list of shipper IDs to filter by */
  shipperIds?: string;
  /** a partial sku or upc to search for */
  search?: string;
  /** Return orders inserted after Datetime (ISO-8601) */
  insertedAfterDatetime?: string;
  /** Return orders inserted before Datetime (ISO-8601) */
  insertedBeforeDatetime?: string;
  /** Flag to show only kits, or only products without kits in response */
  kit?: boolean;
  /** indicates to download a template */
  template?: boolean;
};
export type PostProductsUploadApiResponse =
  /** status 202 Upload Products CSV Successfully completed */ undefined;
export type PostProductsUploadApiArg = {
  /** Bulk Products CSV file */
  body: object;
};
export type GetProductsByProductIdAdjustmentsDownloadApiResponse =
  /** status 200 Download a CSV of a product's adjustments */ string;
export type GetProductsByProductIdAdjustmentsDownloadApiArg = {
  /** Product ID */
  productId: string;
};
export type GetProductUomApiResponse = /** status 200 List of Product Units of Measure */ {
  meta?: Pagination;
  data?: ProductUnitOfMeasure[];
};
export type GetProductUomApiArg = {
  productIds?: string;
  unitOfMeasureIds?: string;
  shipperIds?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetProductsUomTemplateApiResponse =
  /** status 200 Download a CSV template with available columns for product uom upload */ string;
export type GetProductsUomTemplateApiArg = void;
export type PostProductsUomUploadApiResponse =
  /** status 202 Upload Product UOMs CSV Successfully Completed */ undefined;
export type PostProductsUomUploadApiArg = {
  /** Bulk Product UOMs CSV File */
  body: object;
};
export type GetProductsByProductIdUomApiResponse =
  /** status 200 List of Product Units of Measure */ {
    meta?: Pagination;
    data?: ProductUnitOfMeasure[];
  };
export type GetProductsByProductIdUomApiArg = {
  productId: string;
  unitOfMeasureIds?: string;
  shipperIds?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostProductsByProductIdUomApiResponse = /** status 201 Created */ {
  data?: ProductUnitOfMeasure;
};
export type PostProductsByProductIdUomApiArg = {
  productId: string;
  /** Product Unit Of Measure to Create */
  productUnitOfMeasure: ProductUnitOfMeasure;
};
export type PatchProductsByProductIdUomAndProductUomIdApiResponse = /** status 200 Updated */ {
  data?: ProductUnitOfMeasure;
};
export type PatchProductsByProductIdUomAndProductUomIdApiArg = {
  productId: string;
  productUomId: string;
  /** Product Unit Of Measure to Update */
  productUnitOfMeasure: ProductUnitOfMeasure;
};
export type DeleteProductsByProductIdUomAndProductUomIdApiResponse =
  /** status 204 Accepted without body */ string;
export type DeleteProductsByProductIdUomAndProductUomIdApiArg = {
  productId: string;
  productUomId: string;
  /** Whether to delete other product units of measure associated with the product. Only supported for primary units of measure. */
  cascade?: boolean;
};
export type PostProductsByProductIdBarcodesApiResponse = /** status 201 Barcode created */ {
  data?: ProductBarcode;
};
export type PostProductsByProductIdBarcodesApiArg = {
  /** User ID */
  productId: string;
  /** Barcode updates */
  productBarcode: ProductBarcode;
};
export type PatchProductsByProductIdBarcodesAndProductBarcodeIdApiResponse =
  /** status 200 Barcode updated */ {
    data?: ProductBarcode;
  };
export type PatchProductsByProductIdBarcodesAndProductBarcodeIdApiArg = {
  /** product id */
  productId: string;
  /** barcode id */
  productBarcodeId: string;
  /** Barcode updates */
  productBarcode: ProductBarcode;
};
export type DeleteProductsByProductIdBarcodesAndProductBarcodeIdApiResponse =
  /** status 204 Accepted */ string;
export type DeleteProductsByProductIdBarcodesAndProductBarcodeIdApiArg = {
  /** product id */
  productId: string;
  /** barcode id */
  productBarcodeId: string;
};
export type PatchUsersByUserIdApiResponse = /** status 200 User updated */ {
  data?: User;
};
export type PatchUsersByUserIdApiArg = {
  /** User ID */
  userId: string;
  /** User updates */
  userUpdate: UserUpdate;
};
export type GetUsersByUserIdApiResponse = /** status 200 User data */ {
  data?: User;
};
export type GetUsersByUserIdApiArg = {
  /** User ID */
  userId: string;
};
export type DeleteUsersByUserIdBlockApiResponse = /** status 204 User unblocked */ undefined;
export type DeleteUsersByUserIdBlockApiArg = {
  /** User ID */
  userId: string;
};
export type GetMeApiResponse = /** status 200 User data */ {
  data?: User;
};
export type GetMeApiArg = void;
export type PatchMeApiResponse = /** status 200 User updated */ {
  data?: User;
};
export type PatchMeApiArg = {
  /** Update the currently logged in user */
  body: {
    name?: string;
    password?: string;
    language?: string;
  };
};
export type GetUsersApiResponse = /** status 200 List Users */ {
  meta?: Pagination;
  data?: User[];
};
export type GetUsersApiArg = {
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
  /** Include stord employee users in response if authenticated as a stord_employee */
  includeStordEmployees?: boolean;
  /** Search users by name or email */
  search?: string;
  active?: string;
  /** Comma separated list of roles to filter by */
  role?: string;
};
export type PostUsersApiResponse = /** status 201 Created */ {
  data?: User;
};
export type PostUsersApiArg = {
  /** User to add */
  user: User;
};
export type GetUsersDownloadApiResponse = /** status 200 Download a CSV of all users */ string;
export type GetUsersDownloadApiArg = {
  /** Include stord employee users in response if authenticated as a stord_employee */
  includeStordEmployees?: boolean;
  /** Search users by name or email */
  search?: string;
  active?: string;
  /** Comma separated list of roles to filter by */
  role?: string;
};
export type GetBuildingsApiResponse = /** status 200 List existing buildings */ {
  meta?: Pagination;
  data?: Building[];
};
export type GetBuildingsApiArg = {
  /** building name to search on (contains/starts with/ends with) */
  name?: string;
  /** comma separated list of building ids to filter by */
  buildingIds?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostBuildingsApiResponse = /** status 201 Created */ {
  data?: Building;
};
export type PostBuildingsApiArg = {
  /** Building to add */
  body: Building & {
    provider_id?: string;
  };
};
export type GetBuildingsByBuildingIdApiResponse = /** status 200 Show existing building */ {
  data?: Building;
};
export type GetBuildingsByBuildingIdApiArg = {
  /** Building ID */
  buildingId: string;
};
export type PatchBuildingsByBuildingIdApiResponse = /** status 200 Updated */ {
  data?: Building;
};
export type PatchBuildingsByBuildingIdApiArg = {
  buildingId: string;
  /** Building to update */
  building: Building;
};
export type PostBuildingsByBuildingIdInviaSyncApiResponse =
  /** status 204 Syncing inventory to inVia */ string;
export type PostBuildingsByBuildingIdInviaSyncApiArg = {
  /** Building ID */
  buildingId: string;
};
export type GetPackingSlipTemplatesApiResponse =
  /** status 200 List existing packing slip templates */ {
    meta?: Pagination;
    data?: PackingSlipTemplate[];
  };
export type GetPackingSlipTemplatesApiArg = {
  /** is default packing slip template */
  default?: boolean;
  /** Shippper ID */
  shipperId?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostPackingSlipTemplatesApiResponse = /** status 201 Created */ {
  data?: PackingSlipTemplate;
};
export type PostPackingSlipTemplatesApiArg = {
  /** Packing Slip Template to add */
  packingSlipTemplate: PackingSlipTemplate;
};
export type GetPackingSlipTemplatesByIdApiResponse =
  /** status 200 Show existing packing-slip-template */ {
    data?: PackingSlipTemplate;
  };
export type GetPackingSlipTemplatesByIdApiArg = {
  /** packing-slip-template id */
  id: string;
};
export type PatchPackingSlipTemplatesByIdApiResponse = /** status 200 Updated */ {
  data?: PackingSlipTemplate;
};
export type PatchPackingSlipTemplatesByIdApiArg = {
  id: string;
  /** packing-slip-template to update */
  packingSlipTemplate: PackingSlipTemplate;
};
export type DeletePackingSlipTemplatesByIdApiResponse = /** status 200 Deleted */ {
  data?: PackingSlipTemplate;
};
export type DeletePackingSlipTemplatesByIdApiArg = {
  id: string;
};
export type GetPackingSlipTemplatesByIdLogoApiResponse =
  /** status 200 Download packing slip template logo */ undefined;
export type GetPackingSlipTemplatesByIdLogoApiArg = {
  id: string;
};
export type PostPackingSlipTemplatesByIdLogoApiResponse = /** status 200 Uploaded */ {
  data?: PackingSlipTemplate;
};
export type PostPackingSlipTemplatesByIdLogoApiArg = {
  id: string;
  /** Image file to set as custom packing slip logo */
  attachment: Attachment;
};
export type DeletePackingSlipTemplatesByIdLogoApiResponse = /** status 204 Accepted */ string;
export type DeletePackingSlipTemplatesByIdLogoApiArg = {
  id: string;
};
export type GetPackingSlipTemplatesByPackingSlipTemplateIdChannelsApiResponse =
  /** status 200 Show existing packing-slip-template channels */ {
    data?: PackingSlipTemplateChannel[];
  };
export type GetPackingSlipTemplatesByPackingSlipTemplateIdChannelsApiArg = {
  /** packing-slip-template id */
  packingSlipTemplateId: string;
};
export type PostPackingSlipTemplatesByPackingSlipTemplateIdChannelsApiResponse =
  /** status 201 Created */ {
    data?: PackingSlipTemplateChannel;
  };
export type PostPackingSlipTemplatesByPackingSlipTemplateIdChannelsApiArg = {
  /** packing-slip-template id */
  packingSlipTemplateId: string;
  /** Packing Slip Template Channel to add */
  body: {
    channel_name?: string;
    shipper_id?: string;
  };
};
export type DeletePackingSlipTemplatesByPackingSlipTemplateIdChannelsAndIdApiResponse =
  /** status 204 Deleted */ string;
export type DeletePackingSlipTemplatesByPackingSlipTemplateIdChannelsAndIdApiArg = {
  packingSlipTemplateId: string;
  id: string;
};
export type GetCycleCountsApiResponse = /** status 200 List existing cycle counts */ {
  meta?: Pagination;
  data?: CycleCount[];
};
export type GetCycleCountsApiArg = {
  /** Filters cycle counts by status */
  status?: string;
  /** Filters cycle counts by locations containing inventory from shippers */
  shipperIds?: string;
  /** Filters cycle counts to those assigned to the user or unassigned */
  availableForUserId?: string;
  /** Filters cycle counts by created user */
  createdUserId?: string;
  /** Filters cycle counts by assigned user */
  assignedUserId?: string;
  /** Filters cycle counts by reviewed user */
  reviewedByUserId?: string;
  /** Filters cycle counts by canceled user */
  canceledByUserId?: string;
  /** Filters cycle counts by inserted_at before datetime */
  insertedBeforeDatetime?: string;
  /** Filters cycle counts by inserted_at after datetime */
  insertedAfterDatetime?: string;
  /** Filters cycle counts by started_at before datetime */
  startedBeforeDatetime?: string;
  /** Filters cycle counts by started_at after datetime */
  startedAfterDatetime?: string;
  /** Filters cycle counts by submitted_at before datetime */
  submittedBeforeDatetime?: string;
  /** Filters cycle counts by submitted_at after datetime */
  submittedAfterDatetime?: string;
  /** Filters cycle counts by completed_at before datetime */
  completedBeforeDatetime?: string;
  /** Filters cycle counts by completed_at after datetime */
  completedAfterDatetime?: string;
  /** Filters cycle counts by canceled_at before datetime */
  canceledBeforeDatetime?: string;
  /** Filters cycle counts by canceled_at after datetime */
  canceledAfterDatetime?: string;
  /** Filters cycle counts by adjustment_outcome */
  adjustmentOutcome?: string;
  /** Filters cycle counts by location_id */
  locationId?: string;
  /** Filters cycle counts by license_plate_id */
  licensePlateId?: string;
  /** Filters cycle counts by product_id */
  productId?: string;
  /** Filters cycle counts that have unresolved exceptions */
  hasUnresolvedException?: boolean;
  /** Searches for cycle counts by id */
  search?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostCycleCountsApiResponse = /** status 201 Created */ {
  data?: CycleCount;
};
export type PostCycleCountsApiArg = {
  /** CycleCount to add */
  body: {
    assigned_user_id?: string;
    locations?: string[];
  };
};
export type PostCycleCountsBulkApiResponse = /** status 202 Accepted */ string;
export type PostCycleCountsBulkApiArg = {
  /** Locations to create cycle counts for */
  body: {
    assigned_user_id?: string;
    location_ids?: string[];
  };
};
export type GetCycleCountsByCycleCountIdApiResponse = /** status 200 Show Cycle Count */ {
  data?: CycleCount;
};
export type GetCycleCountsByCycleCountIdApiArg = {
  cycleCountId: string;
};
export type PatchCycleCountsByCycleCountIdApiResponse = /** status 200 Updated */ {
  data?: CycleCount;
};
export type PatchCycleCountsByCycleCountIdApiArg = {
  cycleCountId: string;
  /** CycleCount to update */
  body: CycleCount & {
    assigned_user_id?: string;
    approved_adjustment_ids?: string[];
  };
};
export type GetCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdApiResponse =
  /** status 200 Show Cycle Count Location */ {
    data?: CycleCountLocation;
  };
export type GetCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdApiArg = {
  cycleCountId: string;
  cycleCountLocationId: string;
};
export type PostCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdResultsApiResponse =
  /** status 201 Created */ {
    data?: CycleCountResult;
  };
export type PostCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdResultsApiArg = {
  cycleCountId: string;
  cycleCountLocationId: string;
  /** A Cycle Count Result */
  body: CycleCountResult & {
    item_metadata?: CycleCountItemMetadata;
  };
};
export type PatchCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdResultsCycleCountResultIdApiResponse =
  /** status 200 Updated */ {
    data?: CycleCountResult;
  };
export type PatchCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdResultsCycleCountResultIdApiArg =
  {
    cycleCountId: string;
    cycleCountLocationId: string;
    cycleCountResultId: string;
    /** Cycle Count Result to update */
    cycleCountResult: CycleCountResult;
  };
export type DeleteCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdResultsCycleCountResultIdApiResponse =
  /** status 204 Updated */ {
    data?: CycleCountResult;
  };
export type DeleteCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdResultsCycleCountResultIdApiArg =
  {
    cycleCountId: string;
    cycleCountLocationId: string;
    cycleCountResultId: string;
  };
export type PatchCycleCountsAssignApiResponse = /** status 204 Updated */ undefined;
export type PatchCycleCountsAssignApiArg = {
  /** Cycle counts to assign user to */
  body: {
    assigned_user_id?: string;
    cycle_count_ids?: string[];
  };
};
export type PostOutboundsShipApiResponse = /** status 204 Updated */ undefined;
export type PostOutboundsShipApiArg = {
  /** Ship Outbounds by IDs */
  body: {
    ids?: string[];
  };
};
export type GetCycleCountExceptionsApiResponse = /** status 200 List existing Exceptions */ {
  meta?: Pagination;
  data?: CycleCountException[];
};
export type GetCycleCountExceptionsApiArg = {
  /** filter results by comma-separated exception categories */
  categories?: string;
  /** Return exceptions inserted after Datetime (ISO-8601) */
  insertedAfterDatetime?: string;
  /** Return exceptoins inserted before Datetime (ISO-8601) */
  insertedBeforeDatetime?: string;
  /** filter results by comma-separated exception subcategories */
  subcategories?: string;
  /** filter results by comma-separated cycle_count_ids */
  cycleCountIds?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostCycleCountExceptionsApiResponse = /** status 201 Created */ {
  data?: CycleCountException;
};
export type PostCycleCountExceptionsApiArg = {
  /** Parameters to create cycle count exception */
  body: {
    category?: CycleCountExceptionCategory;
    subcategory?: CycleCountExceptionSubCategory;
    cycle_count_id?: string;
    exception?: Exception;
    metadata?: CycleCountLpnExceptionMetadata | CycleCountProductExceptionMetadata;
  };
};
export type GetCycleCountExceptionsByIdApiResponse = /** status 200 An exception */ {
  data?: CycleCountException;
};
export type GetCycleCountExceptionsByIdApiArg = {
  id: string;
};
export type PostShippersByShipperIdOutboundsShipApiResponse = /** status 204 Updated */ undefined;
export type PostShippersByShipperIdOutboundsShipApiArg = {
  shipperId: string;
  /** Ship Outbounds by External Id (segmented by shipper) */
  body: {
    external_ids?: string[];
  };
};
export type GetLicensePlatesApiResponse = /** status 200 List of License Plates */ {
  meta?: Pagination;
  data?: LicensePlate[];
};
export type GetLicensePlatesApiArg = {
  /** Comma separated Location IDs */
  locationIds?: string;
  /** Comma separated Product IDs */
  productIds?: string;
  /** Comma separated License Plate IDs */
  licensePlateIds?: string;
  /** Comma separated Item IDs */
  itemIds?: string;
  /** Comma separated Shipper IDs */
  shipperIds?: string;
  active?: string;
  lotNumber?: string;
  /** A partial LPN number or lot number or sku to search for */
  search?: string;
  /** Comma Separated License Plate numbers */
  licensePlateNumbers?: string;
  /** A comma separated list of location types */
  locationTypes?: string;
  /** A comma separated list of location subtypes */
  locationSubtypes?: string;
  /** A comma separated list of (case insensitive) skus */
  skus?: string;
  /** Return LPNs that only have deleted items (true) or filter those out (false) or return both (all). */
  empty?: 'true' | 'false' | 'all';
  /** Return LPNs that are either locked (true) or unlocked (false) */
  locked?: boolean;
  /** a comma separated list of inbound ids */
  inboundIds?: string;
  /** a comma separated list of outbound ids */
  outboundIds?: string;
  /** set to false to skip the read replica and get the good stuff */
  replica?: boolean;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostLicensePlatesLockApiResponse = /** status 200 List of License Plates */ {
  data?: LicensePlate[];
};
export type PostLicensePlatesLockApiArg = {
  body:
    | LicensePlateLockableSearchFields
    | {
        locked_reason_code_id?: string;
        lpns?: string[];
      };
};
export type PostLicensePlatesUnlockApiResponse = /** status 200 List of License Plates */ {
  data?: LicensePlate[];
};
export type PostLicensePlatesUnlockApiArg = {
  /** Unlocks all given license plates numbers that are in storage locations */
  body:
    | LicensePlateLockableSearchFields
    | {
        lpns?: string[];
      };
};
export type GetLicensePlatesByLicensePlateIdApiResponse = /** status 200 Show License Plate */ {
  data?: LicensePlate;
};
export type GetLicensePlatesByLicensePlateIdApiArg = {
  licensePlateId: string;
};
export type PatchLicensePlatesByLicensePlateIdApiResponse = /** status 200 Updated */ {
  data?: LicensePlate;
};
export type PatchLicensePlatesByLicensePlateIdApiArg = {
  licensePlateId: string;
  /** License plate to update */
  body: LicensePlate & {
    locked?: boolean;
  };
};
export type PutLicensePlatesByLicensePlateIdMoveApiResponse = /** status 200 Show License Plate */ {
  data?: LicensePlate;
};
export type PutLicensePlatesByLicensePlateIdMoveApiArg = {
  licensePlateId: string;
  /** Location to Move LPN to */
  body: {
    destination_location_id?: string;
  };
};
export type GetLicensePlatesByLicensePlateIdMovementsApiResponse =
  /** status 200 array of Movements */ {
    meta?: Pagination;
    data?: Movement[];
  };
export type GetLicensePlatesByLicensePlateIdMovementsApiArg = {
  licensePlateId: string;
  userId?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetLicensePlatesByLicensePlateIdAdjustmentsApiResponse =
  /** status 200 List Adjustments */ {
    meta?: Pagination;
    data?: Adjustment[];
  };
export type GetLicensePlatesByLicensePlateIdAdjustmentsApiArg = {
  /** License Plate ID */
  licensePlateId: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostLicensePlatesByLicensePlateIdSplitApiResponse = /** status 200 License Plate */ {
  data?: LicensePlate;
};
export type PostLicensePlatesByLicensePlateIdSplitApiArg = {
  licensePlateId: string;
  body: {
    quantity: number;
    lpn: string;
    item_id: string;
    location_id?: string;
  };
};
export type PostLicensePlatesByLicensePlateIdTransferApiResponse =
  /** status 204 Accepted */ string;
export type PostLicensePlatesByLicensePlateIdTransferApiArg = {
  licensePlateId: string;
  body: {
    number: string;
    location_id?: string;
  };
};
export type PostLicensePlatesByLicensePlateIdReplaceApiResponse = /** status 200 License Plate */ {
  data?: LicensePlate;
};
export type PostLicensePlatesByLicensePlateIdReplaceApiArg = {
  licensePlateId: string;
  body: {
    new_license_plate_number: string;
  };
};
export type GetLicensePlatesNumberByLicensePlateNumberApiResponse =
  /** status 200 Show License Plate by Number */ {
    data?: LicensePlate;
  };
export type GetLicensePlatesNumberByLicensePlateNumberApiArg = {
  licensePlateNumber: string;
};
export type GetUomApiResponse = /** status 200 List of Units of Measure */ {
  meta?: Pagination;
  data?: UnitOfMeasure[];
};
export type GetUomApiArg = {
  abbreviation?: string;
  name?: string;
  /** Comma Separated Shipper IDs */
  shipperIds?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostUomApiResponse = /** status 201 Created */ {
  data?: UnitOfMeasure;
};
export type PostUomApiArg = {
  /** Unit Of Measure to Create */
  unitOfMeasure: UnitOfMeasure;
};
export type PatchUomByUomIdApiResponse = /** status 200 Updated */ {
  data?: UnitOfMeasure;
};
export type PatchUomByUomIdApiArg = {
  uomId: string;
  /** Unit Of Measure to Update */
  unitOfMeasure: UnitOfMeasure;
};
export type DeleteUomByUomIdApiResponse = /** status 204 Accepted without body */ string;
export type DeleteUomByUomIdApiArg = {
  uomId: string;
};
export type PostKitsApiResponse = /** status 201 Created */ {
  data?: Kit;
};
export type PostKitsApiArg = {
  /** Kit to create */
  body: Product &
    Kit & {
      unit_of_measure_id?: string;
    };
};
export type GetKitsApiResponse = /** status 200 Listing kits */ {
  meta?: Pagination;
  data?: Kit[];
};
export type GetKitsApiArg = void;
export type GetKitsByKitIdApiResponse = /** status 200 Retrieve Kit details */ {
  data?: Kit;
};
export type GetKitsByKitIdApiArg = {
  /** Kit ID */
  kitId: string;
};
export type DeleteKitsByKitIdApiResponse = /** status 200 Deleted */ {
  data?: Kit;
};
export type DeleteKitsByKitIdApiArg = {
  kitId: string;
};
export type PatchKitsByKitIdApiResponse = /** status 200 Updated */ {
  data?: Kit;
};
export type PatchKitsByKitIdApiArg = {
  kitId: string;
  /** Kit to update */
  body: Product &
    Kit & {
      unit_of_measure_id?: string;
    };
};
export type GetKitsByKitIdAttachmentsApiResponse = /** status 200 Listing attachments */ {
  meta?: Pagination;
  data?: KitAttachment[];
};
export type GetKitsByKitIdAttachmentsApiArg = {
  kitId: string;
};
export type PostKitsByKitIdAttachmentsApiResponse = /** status 201 Created */ {
  data?: Attachment;
};
export type PostKitsByKitIdAttachmentsApiArg = {
  kitId: string;
  /** Attachment to create */
  attachment: Attachment;
};
export type GetShippersApiResponse = /** status 200 List existing Shippers */ {
  meta?: Pagination;
  data?: Shipper[];
};
export type GetShippersApiArg = {
  /** Boolean flag for active or inactive */
  active?: boolean;
  /** boolean flag for invia integration */
  inviaEnabled?: boolean;
  /** (DEPRECATED) Name of shipper */
  name?: string;
  /** Comma Separated Names of shippers */
  names?: string;
  /** Comma Separated Shipper IDs */
  shipperIds?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostShippersApiResponse = /** status 201 Created */ {
  data?: Shipper;
};
export type PostShippersApiArg = {
  /** Shipper to add */
  shipper: Shipper;
};
export type GetShippersByShipperIdApiResponse = /** status 200 Show Shipper */ {
  data?: Shipper;
};
export type GetShippersByShipperIdApiArg = {
  shipperId: string;
};
export type DeleteShippersByShipperIdApiResponse = /** status 202 Accepted */ string;
export type DeleteShippersByShipperIdApiArg = {
  /** Shipper ID */
  shipperId: string;
};
export type PatchShippersByShipperIdApiResponse = /** status 200 Updated */ {
  data?: Shipper;
};
export type PatchShippersByShipperIdApiArg = {
  shipperId: string;
  /** A Shipper */
  shipper: Shipper;
};
export type GetShippersAllApiResponse = /** status 200 List existing Shippers */ {
  meta?: Pagination;
  data?: Shipper[];
};
export type GetShippersAllApiArg = {
  /** Name of shipper */
  name?: string;
  /** Comma Separated Shipper IDs */
  shipperIds?: string;
  /** boolean flag for invia integration */
  inviaEnabled?: boolean;
  /** boolean flag for active or inactive */
  active?: boolean;
  /** Comma Separated building IDs */
  buildingIds?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetShippersByShipperIdConfigApiResponse = /** status 200 Show ShipperConfig */ {
  data?: ShipperConfig;
};
export type GetShippersByShipperIdConfigApiArg = {
  shipperId: string;
};
export type PostShippersByShipperIdConfigApiResponse = /** status 201 Created */ {
  data?: ShipperConfig;
};
export type PostShippersByShipperIdConfigApiArg = {
  shipperId: string;
  /** A ShipperConfig */
  shipperConfig: ShipperConfig;
};
export type PatchShippersByShipperIdConfigApiResponse = /** status 200 Updated */ {
  data?: ShipperConfig;
};
export type PatchShippersByShipperIdConfigApiArg = {
  shipperId: string;
  /** A ShipperConfig */
  shipperConfig: ShipperConfig;
};
export type PatchShippersByShipperIdConfigPremiumFeaturesApiResponse = /** status 200 Updated */ {
  data?: ShipperConfig;
};
export type PatchShippersByShipperIdConfigPremiumFeaturesApiArg = {
  shipperId: string;
  /** ShipperConfig premium features */
  shipperConfigPremiumFeatures: ShipperConfigPremiumFeatures;
};
export type PatchShippersByShipperIdConfigDeveloperFeaturesApiResponse = /** status 200 Updated */ {
  data?: ShipperConfig;
};
export type PatchShippersByShipperIdConfigDeveloperFeaturesApiArg = {
  shipperId: string;
  /** ShipperConfig developer features */
  shipperConfigDeveloperFeatures: ShipperConfigDeveloperFeatures;
};
export type GetShippersByShipperIdConfigPackingSlipLogoApiResponse =
  /** status 200 Download packing slip logo */ undefined;
export type GetShippersByShipperIdConfigPackingSlipLogoApiArg = {
  shipperId: string;
};
export type PostShippersByShipperIdConfigPackingSlipLogoApiResponse = /** status 200 Uploaded */ {
  data?: ShipperConfig;
};
export type PostShippersByShipperIdConfigPackingSlipLogoApiArg = {
  shipperId: string;
  /** Image file to set as custom packing slip logo */
  attachment: Attachment;
};
export type DeleteShippersByShipperIdConfigPackingSlipLogoApiResponse =
  /** status 204 Accepted */ string;
export type DeleteShippersByShipperIdConfigPackingSlipLogoApiArg = {
  shipperId: string;
};
export type GetShippersByShipperIdAddressesApiResponse =
  /** status 200 List of shipper addresses */ {
    meta?: Pagination;
    data?: ShipperAddress[];
  };
export type GetShippersByShipperIdAddressesApiArg = {
  shipperId: string;
  search?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostShippersByShipperIdAddressesApiResponse = /** status 201 Created */ {
  data?: ShipperAddress;
};
export type PostShippersByShipperIdAddressesApiArg = {
  shipperId: string;
  /** Shipper address to create */
  shipperAddress: ShipperAddress;
};
export type PatchShippersByShipperIdAddressesAndShipperAddressIdApiResponse =
  /** status 200 Updated */ {
    data?: ShipperAddress;
  };
export type PatchShippersByShipperIdAddressesAndShipperAddressIdApiArg = {
  shipperId: string;
  shipperAddressId: string;
  /** A Shipper Address */
  shipperAddress: ShipperAddress;
};
export type DeleteShippersByShipperIdAddressesAndShipperAddressIdApiResponse =
  /** status 204 Accepted */ string;
export type DeleteShippersByShipperIdAddressesAndShipperAddressIdApiArg = {
  shipperId: string;
  shipperAddressId: string;
};
export type GetShippersByShipperIdSpecialInstructionsApiResponse =
  /** status 200 List of special instructions */ {
    meta?: Pagination;
    data?: SpecialInstruction[];
  };
export type GetShippersByShipperIdSpecialInstructionsApiArg = {
  shipperId: string;
  /** Default */
  default?: boolean;
  search?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostShippersByShipperIdSpecialInstructionsApiResponse = /** status 201 Created */ {
  data?: SpecialInstruction;
};
export type PostShippersByShipperIdSpecialInstructionsApiArg = {
  shipperId: string;
  /** Special Instruction to create */
  specialInstruction: SpecialInstruction;
};
export type PatchShippersByShipperIdSpecialInstructionsAndSpecialInstructionIdApiResponse =
  /** status 200 Updated */ {
    data?: SpecialInstruction;
  };
export type PatchShippersByShipperIdSpecialInstructionsAndSpecialInstructionIdApiArg = {
  shipperId: string;
  specialInstructionId: string;
  /** A Special Instruction */
  specialInstruction: SpecialInstruction;
};
export type DeleteShippersByShipperIdSpecialInstructionsAndSpecialInstructionIdApiResponse =
  /** status 204 Accepted */ string;
export type DeleteShippersByShipperIdSpecialInstructionsAndSpecialInstructionIdApiArg = {
  shipperId: string;
  specialInstructionId: string;
};
export type PostShippersByShipperIdObfuscateApiResponse =
  /** status 202 An async job has started obfuscating personally identifiable data */ undefined;
export type PostShippersByShipperIdObfuscateApiArg = {
  shipperId: string;
};
export type PostShippersByShipperIdPurgeInventoryApiResponse =
  /** status 202 An async job has started adjusting the shippers inventory to 0 */ undefined;
export type PostShippersByShipperIdPurgeInventoryApiArg = {
  shipperId: string;
};
export type GetShipperConfigsApiResponse = /** status 200 List shipper configs */ {
  meta?: Pagination;
  data?: ShipperConfig[];
};
export type GetShipperConfigsApiArg = {
  /** Comma-separated shipper IDs */
  shipperIds?: string;
  page?: number;
  pageSize?: number;
};
export type GetAutomationConfigsApiResponse = /** status 200 List automation configs */ {
  meta?: Pagination;
  data?: AutomationConfig[];
};
export type GetAutomationConfigsApiArg = {
  shipperIds?: string;
  active?: string;
  category?: AutomationConfigCategory;
  name?: string;
  automationConfigIds?: string;
  /** Comma separated list of pick equipment group ids */
  pickEquipmentGroupIds?: string;
  /** Comma separated list of Putaway Location Strategies */
  putawayLocationStrategies?: PutawayLocationStrategy;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostAutomationConfigsApiResponse = /** status 201 Automation config created */ {
  data?: AutomationConfig;
};
export type PostAutomationConfigsApiArg = {
  /** Automation config updates */
  automationConfig: AutomationConfig;
};
export type GetAutomationConfigsByAutomationConfigIdApiResponse =
  /** status 200 Show automation config */ {
    data?: AutomationConfig;
  };
export type GetAutomationConfigsByAutomationConfigIdApiArg = {
  automationConfigId: string;
};
export type PatchAutomationConfigsByAutomationConfigIdApiResponse = /** status 200 Updated */ {
  data?: AutomationConfig;
};
export type PatchAutomationConfigsByAutomationConfigIdApiArg = {
  automationConfigId: string;
  /** Automation config updates */
  automationConfig: AutomationConfig;
};
export type DeleteAutomationConfigsByAutomationConfigIdApiResponse =
  /** status 204 Accepted */ string;
export type DeleteAutomationConfigsByAutomationConfigIdApiArg = {
  automationConfigId: string;
};
export type PostAutomationConfigsEvaluateApiResponse =
  /** status 200 Automation config evaluated */ {
    data?: {
      sum?: number;
    };
  };
export type PostAutomationConfigsEvaluateApiArg = {
  /** Given conditions and a daterange, determine how many outbounds fit the conditions */
  body: {
    category?: AutomationConfigCategory;
    inserted_after_datetime?: string;
    inserted_before_datetime?: string;
    shipper_id?: string;
    conditions?: object;
  };
};
export type PostAutomationConfigsByAutomationConfigIdRestreamApiResponse =
  /** status 204 Accepted */ string;
export type PostAutomationConfigsByAutomationConfigIdRestreamApiArg = {
  automationConfigId: string;
};
export type GetAutomationConfigsPutawayLpnByLicensePlateIdApiResponse =
  /** status 200 Return the putaway location strategy for a given license_plate */ {
    data?: {
      putaway_location_strategy?: PutawayLocationStrategy;
      putaway_location_zone_id?: string;
      automation_config?: AutomationConfig;
    };
  };
export type GetAutomationConfigsPutawayLpnByLicensePlateIdApiArg = {
  licensePlateId: string;
};
export type GetOrionWarehouseMappingsApiResponse = /** status 200 List Orion Warehouse Mappings */ {
  meta?: Pagination;
  data?: OrionWarehouseMapping[];
};
export type GetOrionWarehouseMappingsApiArg = {
  /** Shipper id */
  shipperId?: string;
  /** Building id */
  buildingId?: string;
  /** Warehouse alias */
  warehouseAlias?: string;
  /** distribution network id */
  distributionNetworkId?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostOrionWarehouseMappingsApiResponse = /** status 201 Created */ {
  data?: OrionWarehouseMapping;
};
export type PostOrionWarehouseMappingsApiArg = {
  /** Warehouse Mapping to add */
  orionWarehouseMapping: OrionWarehouseMapping;
};
export type PatchOrionWarehouseMappingsByOrionWarehouseMappingIdApiResponse =
  /** status 200 Updated */ {
    data?: OrionWarehouseMapping;
  };
export type PatchOrionWarehouseMappingsByOrionWarehouseMappingIdApiArg = {
  orionWarehouseMappingId: string;
  /** Warehouse Mapping to update */
  orionWarehouseMapping: OrionWarehouseMapping;
};
export type GetOrionWarehouseMappingsByOrionWarehouseMappingIdApiResponse =
  /** status 200 Updated */ {
    data?: OrionWarehouseMapping;
  };
export type GetOrionWarehouseMappingsByOrionWarehouseMappingIdApiArg = {
  orionWarehouseMappingId: string;
};
export type PostPackoutSessionApiResponse = /** status 201 Created */ {
  data?: PackoutSession;
};
export type PostPackoutSessionApiArg = {
  /** License plate from which to create a packout session */
  body: {
    license_plate_id?: string;
  };
};
export type GetPackoutSessionBySessionIdOutboundsApiResponse =
  /** status 200 List Outbound Orders in Packout Session */ {
    data?: OutboundOrder[];
  };
export type GetPackoutSessionBySessionIdOutboundsApiArg = {
  sessionId: string;
};
export type PatchPackoutOutboundsPackedApiResponse = /** status 200 Updated outbound orders */ {
  data?: OutboundOrder[];
};
export type PatchPackoutOutboundsPackedApiArg = {
  body: {
    outbound_ids: string[];
    lpns?: string[];
  };
};
export type PatchPackoutOutboundsByOutboundIdPackedApiResponse =
  /** status 200 Updated outbound order */ {
    data?: OutboundOrder;
  };
export type PatchPackoutOutboundsByOutboundIdPackedApiArg = {
  /** ID of the outbound to mark as packed */
  outboundId: string;
  body: {
    lpns?: string[];
  };
};
export type PostPackoutOutboundsShortageApiResponse = /** status 202 Accepted */ string;
export type PostPackoutOutboundsShortageApiArg = {
  body: {
    shortages: {
      outbound_id: string;
      tag?: string;
    }[];
  };
};
export type PostPackoutPrintApplyPackToLpnByOutboundIdApiResponse =
  /** status 201 Pending parcel with LPN that the items were packed into */ {
    data?: Parcel;
  };
export type PostPackoutPrintApplyPackToLpnByOutboundIdApiArg = {
  /** ID of the outbound to pack */
  outboundId: string;
  body: {
    outbound_id: string;
    location_id: string;
    parcel: Parcel;
    serial_numbers?: SerialNumber[];
  };
};
export type PostWebsocketTicketsApiResponse = /** status 201 Ticket response */ {
  data?: {
    websocket_ticket?: string;
  };
};
export type PostWebsocketTicketsApiArg = void;
export type GetTimezonesApiResponse = /** status 200 List of canonical IANA timezone names */ {
  data?: string[];
};
export type GetTimezonesApiArg = void;
export type GetTrackingApiResponse =
  /** status 200 User-specific signatures for client-side tracking */ {
    data?: {
      userflow_signature?: string;
    };
  };
export type GetTrackingApiArg = void;
export type GetAdjustmentsReasonCodesApiResponse = /** status 200 List Adjustment Reason Codes */ {
  data?: AdjustmentReasonCode[];
};
export type GetAdjustmentsReasonCodesApiArg = {
  /** indicates whether reason code is in current use */
  active?: boolean;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetWorkOrdersApiResponse = /** status 200 List existing Work Orders */ {
  meta?: Pagination;
  data?: WorkOrder[];
};
export type GetWorkOrdersApiArg = {
  /** Work Order category */
  category?: string;
  /** Use the search parameter instead. Filters by the WO number */
  number?: string;
  /** Comma separated list of work Order statuses */
  status?: string;
  /** filter results to those inserted before the given datetime */
  insertedBeforeDatetime?: string;
  /** filter results to those inserted after the given datetime */
  insertedAfterDatetime?: string;
  /** filter results to those completed before the given datetime */
  completedBeforeDatetime?: string;
  /** filter results to those completed after the given datetime */
  completedAfterDatetime?: string;
  /** comma separated list of shipper_ids */
  shipperIds?: string;
  /** filter results across lpns, skus, and WO numbers */
  search?: string;
  /** filter results to those created by the given user id */
  createdByUserId?: string;
  /** filter results to those assigned to the given user id */
  assignedUserId?: string;
  /** filter results to those available for the given user id */
  availableForUserId?: string;
  productId?: string;
  locationId?: string;
  /** active step type - one of pick_inventory, assemble_kit, move_inventory */
  activeStep?: WorkOrderStepType;
  /** filter results for given priority */
  priority?:
    | number
    | {
        gte?: number;
        eq?: number;
      };
  /** filter results to those with any of the given lpns in the output step */
  outputLpns?: string;
  /** filter out results with any of the given lpns in the output step */
  excludeOutputLpns?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostWorkOrdersApiResponse = /** status 201 Created Work Order */ {
  data?: WorkOrder;
};
export type PostWorkOrdersApiArg = {
  /** specs for work order to create */
  body: {
    category: string;
    shipper_id: string;
    notes?: string;
    metadata: KittingWorkOrderMetadata | ReplenishmentWorkOrderMetadata;
  };
};
export type GetWorkOrdersReplenishmentLookAheadApiResponse =
  /** status 200 Paginated list of replenishment look-ahead results */ {
    meta?: Pagination;
    data?: ReplenishmentLookAheadResult[];
  };
export type GetWorkOrdersReplenishmentLookAheadApiArg = {
  /** Comma-separated shipper IDs */
  shipperIds?: string;
  /** Product ID (Single) to filter */
  productId?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PatchWorkOrdersAssignApiResponse = /** status 204 Updated */ undefined;
export type PatchWorkOrdersAssignApiArg = {
  /** Work orders to assign user to */
  body:
    | WorkOrderSearchFields
    | {
        assign_to_user_id?: string;
        work_order_ids?: string[];
      };
};
export type GetWorkOrdersByWorkOrderIdAttachmentsApiResponse =
  /** status 200 Paginated list of work order attachments */ {
    meta?: Pagination;
    data?: WorkOrderAttachment[];
  };
export type GetWorkOrdersByWorkOrderIdAttachmentsApiArg = {
  workOrderId: string;
};
export type PostWorkOrdersByWorkOrderIdAttachmentsApiResponse = /** status 201 Created */ {
  data?: WorkOrderAttachment;
};
export type PostWorkOrdersByWorkOrderIdAttachmentsApiArg = {
  workOrderId: string;
  attachment: Attachment;
};
export type GetWorkOrdersByWorkOrderIdApiResponse = /** status 200 A Work Order */ {
  data?: WorkOrder;
};
export type GetWorkOrdersByWorkOrderIdApiArg = {
  workOrderId: string;
};
export type PatchWorkOrdersByWorkOrderIdApiResponse = /** status 200 Updated */ {
  data?: WorkOrder;
};
export type PatchWorkOrdersByWorkOrderIdApiArg = {
  workOrderId: string;
  /** Updatable fields for Work Orders */
  body: {
    status?: string;
    notes?: string;
  };
};
export type PostWorkOrdersByWorkOrderIdMoveApiResponse = /** status 204 Accepted */ string;
export type PostWorkOrdersByWorkOrderIdMoveApiArg = {
  workOrderId: string;
  /** LPN and location to stage to */
  body: {
    destination_location_id: string;
    license_plate_number: string;
  };
};
export type PostWorkOrdersByWorkOrderIdAssembleKitApiResponse = /** status 204 Accepted */ string;
export type PostWorkOrdersByWorkOrderIdAssembleKitApiArg = {
  workOrderId: string;
  /** LPN and amount of kits to assemble */
  body: {
    kit_lpn: string;
    delta: number;
    lot_number?: string;
    manufacturer_date?: string;
    expiration_date?: string;
  };
};
export type PostWorkOrdersByWorkOrderIdStageApiResponse = /** status 204 Accepted */ string;
export type PostWorkOrdersByWorkOrderIdStageApiArg = {
  workOrderId: string;
  /** LPN and location to stage to */
  body: {
    destination_location_id?: string;
    origin_location_id?: string;
  };
};
export type PostWorkOrdersByWorkOrderIdPickApiResponse = /** status 204 Accepted */ string;
export type PostWorkOrdersByWorkOrderIdPickApiArg = {
  workOrderId: string;
  /** pick params */
  body: {
    destination_location_id?: string;
    quantity?: number;
    new_lpn?: string;
    donor_license_plate_number?: string;
    sku?: string;
    origin_location_id?: string;
  };
};
export type PostWorkOrdersByWorkOrderIdReplenishApiResponse = /** status 204 Accepted */ string;
export type PostWorkOrdersByWorkOrderIdReplenishApiArg = {
  workOrderId: string;
  /** pick params */
  body: {
    destination_lpn?: string;
    original_lpn?: string;
  };
};
export type PostWorkOrdersStageReplenishmentsApiResponse = /** status 204 Accepted */ string;
export type PostWorkOrdersStageReplenishmentsApiArg = {
  /** pick params */
  body: {
    equipment_location_id?: string;
    staging_location_id?: string;
  };
};
export type PatchWorkOrderStepsByWorkOrderStepIdAssignApiResponse =
  /** status 204 Accepted */ string;
export type PatchWorkOrderStepsByWorkOrderStepIdAssignApiArg = {
  workOrderStepId: string;
  /** Updatable fields for Work Orders */
  body: {
    assigned_user_id?: string;
  };
};
export type GetShippingPackagingApiResponse = /** status 200 List existing Packaging */ {
  meta?: Pagination;
  data?: Packaging[];
};
export type GetShippingPackagingApiArg = {
  /** Shipper ID */
  shipperId?: string;
  /** Filter by default packaging */
  default?: boolean;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostShippingPackagingApiResponse = /** status 201 Created */ {
  data?: Packaging;
};
export type PostShippingPackagingApiArg = {
  /** Packaging to add */
  packaging: Packaging;
};
export type DeleteShippingPackagingByPackagingIdApiResponse = /** status 204 Accepted */ string;
export type DeleteShippingPackagingByPackagingIdApiArg = {
  /** Packaging ID */
  packagingId: string;
};
export type PatchShippingPackagingByPackagingIdApiResponse = /** status 200 Updated */ {
  data?: Packaging;
};
export type PatchShippingPackagingByPackagingIdApiArg = {
  packagingId: string;
  /** A Packaging */
  packaging: Packaging;
};
export type GetShippingPackagingByPackagingIdApiResponse = /** status 200 Packaging object */ {
  data?: Packaging;
};
export type GetShippingPackagingByPackagingIdApiArg = {
  packagingId: string;
};
export type GetBarcodeLookupApiResponse = /** status 200 Retrieve barcode details */ {
  data?: BarcodeDetails;
};
export type GetBarcodeLookupApiArg = {
  /** Barcode to look up */
  barcode: string;
};
export type PostAdminBulkPackedStreamApiResponse = /** status 201 Created */ {
  data?: Batch;
};
export type PostAdminBulkPackedStreamApiArg = {
  /** Parameters to create bulk packed stream for testing */
  body: {
    automation_config_id?: string;
    status?: OutboundStatus;
    options?: {
      equipment_location_id?: string;
      prefix?: string;
      outbound_params?: OutboundOrder;
    };
  };
};
export type PostAdminProductByProductIdInventoryTotalsApiResponse =
  /** status 204 Accepted */ string;
export type PostAdminProductByProductIdInventoryTotalsApiArg = {
  productId: string;
  /** Parameters to insert an inventory delta to clickhouse */
  body: {
    storage_subtype: string;
    physical_quantity?: number;
    pending_allocation_quantity?: number;
    released_allocation_quantity?: number;
    lot_number?: string;
    zone_id?: string;
  };
};
export type GetAdminInventoryTotalsApiResponse =
  /** status 200 Retrieve list of inventory totals */ {
    data?: InventoryTotal[];
    meta?: Pagination;
  };
export type GetAdminInventoryTotalsApiArg = {
  /** product to view inventory total for */
  productId?: string;
  /** shipper to view inventory total for */
  shipperId?: string;
  /** storage location subtype */
  storageSubtype?: string;
  /** lot_number to view inventory total for */
  lotNumber?: string;
  /** zone to view inventory total for */
  zoneId?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostInsightsDomoJwtApiResponse =
  /** status 200 a JSON web token (JWT) string to pass to Domo via iframe src attribute. */ {
    data?: {
      jwt?: string;
    };
  };
export type PostInsightsDomoJwtApiArg = {
  /** (DEPRECATED) Uses POST method but no need to populate the body. Necessary info for building JWT for Domo is from Bearer and header (for building_id) */
  body: object;
};
export type PostInsightsDomoUrlApiResponse =
  /** status 200 a Domo URL that includes a JSON web token (JWT) to embed via iframe src attribute. */ {
    data?: {
      url?: string;
    };
  };
export type PostInsightsDomoUrlApiArg = {
  /** Uses POST method but no need to populate the body. Necessary info for building URL is from Bearer and header(for building_id) */
  body: object;
};
export type GetProvidersApiResponse = /** status 200 Retrieve list of providers */ {
  data?: Provider[];
  meta?: Pagination;
};
export type GetProvidersApiArg = {
  /** filter by active */
  active?: boolean;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type GetInboundExceptionsApiResponse = /** status 200 List of inbound exceptions */ {
  meta?: Pagination;
  data?: InboundException[];
};
export type GetInboundExceptionsApiArg = {
  /** filter results by comma-separated exception categories */
  categories?: string;
  /** filter results by comma-separated inbound order IDs */
  inboundIds?: string;
  /** return results inserted after datetime (ISO-8601) */
  insertedAfterDatetime?: string;
  /** return results inserted before datetime (ISO-8601) */
  insertedBeforeDatetime?: string;
  /** filter results by comma-separated shipper IDs */
  shipperIds?: string;
  /** filter results by comma-separated exception subcategories */
  subcategories?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostInboundExceptionsApiResponse = /** status 201 Created */ {
  data?: InboundException;
};
export type PostInboundExceptionsApiArg = {
  /** Parameters to create exception */
  body: {
    category?: InboundExceptionCategory;
    subcategory?: InboundExceptionSubcategory;
    inbound_id?: string;
    shipper_id?: string;
    metadata?: PutawayExceptionMetadata;
    exception?: Exception;
  };
};
export type GetInboundExceptionsByIdApiResponse = /** status 200 An inbound exception */ {
  data?: InboundException;
};
export type GetInboundExceptionsByIdApiArg = {
  id: string;
};
export type GetOutboundExceptionsApiResponse = /** status 200 List existing Exceptions */ {
  meta?: Pagination;
  data?: OutboundException[];
};
export type GetOutboundExceptionsApiArg = {
  /** filter results by comma-separated exception categories */
  categories?: string;
  /** filter results by comma-separated shipper_ids */
  shipperIds?: string;
  /** Return exceptions inserted after Datetime (ISO-8601) */
  insertedAfterDatetime?: string;
  /** Return exceptions inserted before Datetime (ISO-8601) */
  insertedBeforeDatetime?: string;
  /** filter results by comma-separated exception subcategories */
  subcategories?: string;
  /** filter results by comma-separated outbound_ids */
  outboundIds?: string;
  /** filter results by comma-separated outbound_line_ids */
  outboundLineIds?: string;
  /** filter results by comma-separated batch_ids */
  batchIds?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostOutboundExceptionsApiResponse = /** status 201 Created */ {
  data?: OutboundException;
};
export type PostOutboundExceptionsApiArg = {
  /** Parameters to create exception */
  body: {
    cascade?: boolean;
    category?: OutboundExceptionCategory;
    subcategory?: OutboundExceptionSubCategory;
    batch_id?: string;
    outbound_id?: string;
    outbound_line_id?: string;
    shipper_id?: string;
    metadata?:
      | InviaPickExceptionMetadata
      | OutboundPickExceptionMetadata
      | ParcelLabelExceptionMetadata
      | PackingExceptionMetadata
      | BatchExceptionMetadata;
    exception?: Exception;
  };
};
export type GetOutboundExceptionsByIdApiResponse = /** status 200 An exception */ {
  data?: OutboundException;
};
export type GetOutboundExceptionsByIdApiArg = {
  id: string;
};
export type GetSyncInventoryApiResponse = /** status 200 Streams inventory for the building. */ {
  id?: string;
  building_id?: string;
  location_name?: string;
  lpn?: string;
  handling_unit?: string;
  lot_number?: string;
  expiration_date?: string;
  manufacturer_date?: string;
  product_length?: number;
  product_description?: string;
  product_height?: number;
  product_name?: string;
  product_picture_url?: string;
  product_sku?: string;
  product_upc?: string;
  product_vpn?: string;
  product_weight?: number;
  product_weight_unit?: string;
  product_width?: number;
  quantity?: number;
  shipper_id?: string;
  shipper_name?: string;
  uom?: string;
  license_plate_locked?: boolean;
  location_type?: string;
  location_subtype?: string;
  outbound_line_item_id?: string;
  location_billing_type_name?: string;
}[];
export type GetSyncInventoryApiArg = {
  /** optional. A list of comma separated shipper ids */
  shipperIds?: string;
};
export type GetZplExceptionsByOutboundId4X6ApiResponse =
  /** status 200 Base-64 encoded ZPL string for a 4x6 exception label */ {
    zpl?: string;
  };
export type GetZplExceptionsByOutboundId4X6ApiArg = {
  /** The ID of the outbound associated with the exception label */
  outboundId: string;
  /** An optional exception reason */
  reason?: string;
};
export type GetZplExceptionsByOutboundId2X2ApiResponse =
  /** status 200 Base-64 encoded ZPL string for a 2x2 exception label */ {
    zpl?: string;
  };
export type GetZplExceptionsByOutboundId2X2ApiArg = {
  /** The ID of the outbound associated with the exception label */
  outboundId: string;
  /** An optional exception reason */
  reason?: string;
};
export type PostCubiscanLabelRequestApiResponse = /** status 200 Created */ {
  data?: CubiscanLabelResponse;
};
export type PostCubiscanLabelRequestApiArg = {
  /** Generate a label for an existing parcel */
  body: {
    Command?: string;
    CartonID?: string;
    Sequence?: number;
    Length?: string;
    Width?: string;
    Height?: string;
    Weight?: string;
    DimUOM?: string;
    WeightUOM?: string;
    SiteID?: string;
  };
};
export type PostCubiscanStatusUpdateApiResponse = /** status 200 Created */ {
  data?: CubiscanStatusUpdateResponse;
};
export type PostCubiscanStatusUpdateApiArg = {
  /** Label verification update from Cubiscan */
  body: {
    Command?: string;
    CartonID?: string;
    Sequence?: number;
    StatusCode?: number;
    TrackingCode?: string;
    ReturnTrackingCode?: string;
    DivertLane?: number;
    ShipViaCode?: string;
    TotalCharge?: string;
    SiteID?: string;
  };
};
export type GetZonesApiResponse = /** status 200 List existing zones */ {
  meta?: Pagination;
  data?: Zone[];
};
export type GetZonesApiArg = {
  /** Comma separated Zone IDs */
  zoneIds?: string;
  page?: number;
  pageSize?: number;
  /** a list of params and directions to sort by (comma separated list) */
  sort?: string;
};
export type PostZonesApiResponse = /** status 201 Created */ {
  data?: Zone;
};
export type PostZonesApiArg = {
  /** Zone to add */
  zone: Zone;
};
export type PostZonesLockApiResponse = /** status 200 Locked zones */ {
  data?: Zone[];
};
export type PostZonesLockApiArg = {
  body: {
    zone_ids?: string[];
    locked_reason_code_id?: string;
  };
};
export type PostZonesUnlockApiResponse = /** status 200 Unlocked zones */ {
  data?: Zone[];
};
export type PostZonesUnlockApiArg = {
  body: {
    zone_ids?: string[];
  };
};
export type DeleteZonesByZoneIdApiResponse = /** status 204 Accepted without body */ string;
export type DeleteZonesByZoneIdApiArg = {
  zoneId: string;
};
export type GetZonesByZoneIdApiResponse = /** status 200 Show zone */ {
  data?: Zone;
};
export type GetZonesByZoneIdApiArg = {
  zoneId: string;
};
export type PatchZonesByZoneIdApiResponse = /** status 200 Updated */ {
  data?: Zone;
};
export type PatchZonesByZoneIdApiArg = {
  zoneId: string;
  /** zone updates */
  zone: Zone;
};
export type PostZonesByZoneIdLockApiResponse = /** status 200 Locked zone */ {
  data?: Zone;
};
export type PostZonesByZoneIdLockApiArg = {
  zoneId: string;
  body: {
    locked_reason_code_id?: string;
  };
};
export type PostZonesByZoneIdUnlockApiResponse = /** status 200 Unlocked zone */ {
  data?: Zone;
};
export type PostZonesByZoneIdUnlockApiArg = {
  zoneId: string;
};
export type ActivityAction =
  | 'activity_created'
  | 'adjustment_created'
  | 'announcement_created'
  | 'announcement_deleted'
  | 'announcement_updated'
  | 'attachment_deleted'
  | 'automation_config_created'
  | 'automation_config_deleted'
  | 'automation_config_updated'
  | 'batch_created'
  | 'batch_completed'
  | 'batch_contents_removed'
  | 'batch_deleted'
  | 'batch_in_progress'
  | 'batch_pick_executed'
  | 'batch_ready'
  | 'batch_released'
  | 'batch_short_split'
  | 'batch_updated'
  | 'building_announcement_created'
  | 'building_announcement_deleted'
  | 'building_announcement_updated'
  | 'building_created'
  | 'building_updated'
  | 'bulk_item_update'
  | 'bulk_location_update'
  | 'bulk_product_update'
  | 'bulk_product_barcode_update'
  | 'bulk_product_units_of_measure_update'
  | 'create_batches_bulk'
  | 'cycle_count_canceled'
  | 'cycle_count_completed'
  | 'cycle_count_created'
  | 'cycle_count_exception_created'
  | 'cycle_count_started'
  | 'cycle_count_review'
  | 'cycle_count_result_created'
  | 'cycle_count_result_deleted'
  | 'cycle_count_result_updated'
  | 'cycle_count_updated'
  | 'exception_created'
  | 'exception_resolved'
  | 'inbound_arrived'
  | 'inbound_canceled'
  | 'inbound_created'
  | 'inbound_exception_created'
  | 'inbound_line_item_deleted'
  | 'inbound_line_updated'
  | 'inbound_line_put_away'
  | 'inbound_partially_received'
  | 'inbound_put_away'
  | 'inbound_received'
  | 'inbound_updated'
  | 'item_created'
  | 'item_updated'
  | 'kit_created'
  | 'kit_deleted'
  | 'kit_attachment_created'
  | 'kits_assembled'
  | 'license_plate_created'
  | 'license_plate_item_transfer'
  | 'license_plate_item_unpicked'
  | 'license_plate_locked'
  | 'license_plate_moved'
  | 'license_plate_replaced'
  | 'license_plate_split'
  | 'license_plate_transfer'
  | 'license_plate_unlocked'
  | 'license_plate_unpicked'
  | 'license_plate_updated'
  | 'location_created'
  | 'location_locked'
  | 'location_unlocked'
  | 'location_updated'
  | 'movement_created'
  | 'outbound_canceled'
  | 'outbound_created'
  | 'outbound_deleted'
  | 'outbound_exception_created'
  | 'outbound_item_picked'
  | 'outbound_line_created'
  | 'outbound_line_updated'
  | 'outbound_line_deleted'
  | 'outbound_lines_allocatable'
  | 'outbound_lines_unallocatable'
  | 'outbound_attachment_created'
  | 'outbound_line_item_created'
  | 'outbound_line_item_deleted'
  | 'outbound_lines_updated'
  | 'outbound_shipped'
  | 'outbound_staged'
  | 'outbound_streamed'
  | 'outbound_unbatched'
  | 'outbound_updated'
  | 'outbound_picking'
  | 'outbound_picked'
  | 'outbound_packed'
  | 'outbounds_restreamed'
  | 'parcel_created'
  | 'parcel_deleted'
  | 'parcel_shipped'
  | 'parcel_updated'
  | 'parcel_verified'
  | 'parcel_voided'
  | 'parcel_item_created'
  | 'parcel_item_deleted'
  | 'parcel_item_updated'
  | 'pick_deleted'
  | 'pick_equipment_group_created'
  | 'pick_equipment_group_deleted'
  | 'pick_equipment_group_updated'
  | 'pick_path_created'
  | 'pick_path_location_created'
  | 'pick_path_location_updated'
  | 'pick_path_location_deleted'
  | 'picked'
  | 'pick_updated'
  | 'packing_slip_template_created'
  | 'packing_slip_template_deleted'
  | 'packing_slip_template_updated'
  | 'packing_slip_template_channel_created'
  | 'packing_slip_template_channel_deleted'
  | 'printer_created'
  | 'printer_updated'
  | 'product_created'
  | 'product_deleted'
  | 'product_updated'
  | 'product_barcode_created'
  | 'product_barcode_updated'
  | 'product_barcode_deleted'
  | 'product_lot_number_created'
  | 'product_lot_number_approved'
  | 'product_lot_number_unapproved'
  | 'product_unit_of_measure_created'
  | 'product_unit_of_measure_updated'
  | 'product_unit_of_measure_deleted'
  | 'receipt_closed'
  | 'receipt_created'
  | 'receipt_updated'
  | 'receipt_line_created'
  | 'receipt_line_deleted'
  | 'receipt_line_updated'
  | 'return_line_received'
  | 'serial_number_created'
  | 'serial_number_updated'
  | 'serial_number_deleted'
  | 'shipment_created'
  | 'shipment_updated'
  | 'shipper_config_created'
  | 'shipper_config_updated'
  | 'shipper_created'
  | 'shipper_deleted'
  | 'shipper_updated'
  | 'staged'
  | 'sync_building_inventory_to_invia'
  | 'unit_of_measure_created'
  | 'unit_of_measure_updated'
  | 'unit_of_measure_deleted'
  | 'user_created'
  | 'user_updated'
  | 'work_order_canceled'
  | 'work_order_completed'
  | 'work_order_created'
  | 'work_order_deleted'
  | 'work_order_item_picked'
  | 'work_order_released'
  | 'work_order_started'
  | 'work_order_updated'
  | 'work_order_attachment_created'
  | 'work_order_step_completed'
  | 'work_order_step_created'
  | 'work_order_step_deleted'
  | 'work_order_step_started'
  | 'work_order_step_updated'
  | 'zone_created'
  | 'zone_updated'
  | 'zone_deleted'
  | 'zone_locked'
  | 'zone_unlocked';
export type User = {
  id?: string;
  email?: string;
  inserted_at?: string;
  updated_at?: string;
  name?: string;
  password?: string;
  role?: string;
  username?: string;
  active?: boolean;
  language?: string;
  last_login?: string;
};
export type Activity = {
  id?: string;
  action?: ActivityAction;
  user?: User;
  activity_data?: object;
  activity_name?: string;
  actor_data?: object;
  actor_id?: string;
  actor_type?: string;
  inserted_at?: string;
  occurred_at?: string;
  request_id?: string;
  segmentation_key?: string;
  source?: string;
  source_data?: object;
};
export type Error = {
  errors?: {
    name?: string;
    message?: string;
  }[];
};
export type AnnouncementCategory = 'error' | 'info' | 'success' | 'warning';
export type Announcement = {
  id?: string;
  category?: AnnouncementCategory;
  created_by_user?: User;
  created_by_user_id?: string;
  message?: string;
  inserted_at?: string;
  updated_at?: string;
};
export type Pagination = {
  page_number: number;
  page_size: number;
  total_entries: number;
  total_pages: number;
};
export type Attachment = {
  id?: string;
  uploaded_by?: string;
  uploaded_by_user?: User;
  source?: string;
  document_type?: string;
  file?: Blob;
  bucket_key?: string;
  original_filename?: string;
  inserted_at?: string;
  updated_at?: string;
  url?: string;
};
export type OutboundStatus =
  | 'open'
  | 'allocated'
  | 'picking'
  | 'picked'
  | 'packing'
  | 'packed'
  | 'staged'
  | 'shipped'
  | 'canceled';
export type OutboundShipmentType = 'dropship' | 'freight' | 'parcel' | 'willcall';
export type Address = {
  name?: string;
  company?: string;
  line1?: string;
  line2?: string;
  line3?: string;
  city?: string;
  state?: string;
  country?: string;
  postal_code?: string;
  phone?: string;
  email?: string | null;
  type?: 'residential' | 'commercial';
};
export type KitComponent = {
  id?: string;
  quantity?: number;
  inserted_at?: string;
  kit_id?: string;
  product_id?: string;
  product?: Product;
  updated_at?: string;
};
export type Kit = {
  id?: string;
  kit_components?: KitComponent[];
  instructions?: string;
  inserted_at?: string;
  product_id?: string;
  product?: Product;
  inherit_expiration_from?: ('earliest' | 'latest') | null;
  inherit_lot_from?: ('work_order' | 'product') | null;
  inherit_lot_from_product_id?: string | null;
  inherit_expiration_from_product?: Product;
  updated_at?: string;
};
export type SerialNumberMask = {
  name?: string;
  pattern?: string;
};
export type FifoType =
  | (
      | 'manufacturer_date'
      | 'received_at_date'
      | 'last_received_at_date'
      | 'expiration_date'
      | 'last_expiration_date'
    )
  | null;
export type UnitOfMeasure = {
  id?: string;
  abbreviation?: string;
  name?: string;
  shipper_id?: string;
  inserted_at?: string;
  updated_at?: string;
};
export type ProductUnitOfMeasure = {
  id?: string;
  primary?: boolean;
  quantity?: number;
  product_id?: string;
  unit_of_measure_id?: string;
  unit_of_measure?: UnitOfMeasure;
  shipper_id?: string;
  inserted_at?: string;
  updated_at?: string;
};
export type ProductLocationSummary = {
  damaged?: number;
  equipment?: number;
  packing?: number;
  quarantine?: number;
  staging?: number;
  forward?: number;
  forward_allocated?: number;
  reserve?: number;
  reserve_allocated?: number;
  storage?: number;
  allocated?: number;
  receiving?: number;
  returns?: number;
  locked?: number;
};
export type ProductBarcode = {
  id?: string;
  inserted_at?: string;
  name?: string;
  barcode_value?: string;
  product_id?: string;
  updated_at?: string;
};
export type BatteryInstallation = 'installed' | 'uninstalled';
export type PackingGroup = 'i' | 'ii' | 'iii';
export type TransportMode =
  | 'any'
  | 'cargo_aircraft_only'
  | 'ground'
  | 'passenger_and_cargo_aircraft';
export type HazmatInfo = {
  battery_count?: number;
  battery_installation?: BatteryInstallation;
  contains_lithium?: boolean;
  hazard_class?: string;
  limited_quantity?: boolean;
  lithium_ion_content?: number;
  packing_group?: PackingGroup;
  proper_shipping_name?: string;
  transport_mode?: TransportMode;
  un_id?: string;
};
export type Product = {
  id?: string;
  building_id?: string;
  name?: string;
  sku?: string;
  height?: number;
  hazmat?: boolean;
  is_shippable_in_own_container?: boolean;
  width?: number;
  weight?: number | null;
  weight_unit?: ('g' | 'oz' | 'lb' | 'kg' | 't' | 'st') | null;
  weight_in_pounds?: number | null;
  length?: number;
  picture_url?: string;
  inserted_at?: string;
  updated_at?: string;
  kit?: Kit;
  lot_tracked?: boolean;
  manufacturer_date_tracked?: boolean;
  expiration_date_tracked?: boolean;
  serial_number_masks?: SerialNumberMask[];
  serial_number_tracked?: boolean;
  description?: string;
  fifo_type?: FifoType;
  shipper_id?: string;
  units_of_measure?: ProductUnitOfMeasure[];
  location_summary?: ProductLocationSummary;
  tariff_code?: string | null;
  unit_price?: number;
  retail_price?: number;
  tags?: string[];
  customs_value?: number;
  country_of_origin?: string;
  customs_description?: string;
  allow_nested_packaging?: boolean;
  barcodes?: ProductBarcode[];
  hazmat_info?: HazmatInfo;
  auto_lot_locking_enabled?: boolean;
};
export type PickEquipmentGroup = {
  building_id?: string;
  description?: string;
  name?: string;
  id?: string;
  is_enabled_for_all_shippers?: boolean;
  shipper_ids?: string[];
  inserted_at?: string;
  updated_at?: string;
};
export type LocationProductSummary = {
  lpn_count?: number;
  items_quantity?: number;
  product?: Product;
  location_id?: string;
}[];
export type LocationSubtype =
  | (
      | 'assembly'
      | 'crossdock'
      | 'damaged'
      | 'dock_door'
      | 'forward'
      | 'lost'
      | 'packstation'
      | 'quarantine'
      | 'replenishment'
      | 'reserve'
      | 'returns'
      | 'triage'
    )
  | null;
export type LocationType = 'equipment' | 'pending' | 'shipped' | 'staging' | 'storage' | 'other';
export type Zone = {
  building_id?: string;
  deleted_at?: string;
  deleted_by_user_id?: string;
  description?: string;
  name?: string;
  id?: string;
  locations?: Location[];
  locked_at?: string;
  inserted_at?: string;
  updated_at?: string;
};
export type Location = {
  active?: boolean;
  id?: string;
  inserted_at?: string;
  legacy_barcode_value?: string;
  location_billing_type_id?: string | null;
  location_sequence?: number;
  locked_at?: string | null;
  max_capacity?: number | null;
  name?: string;
  pick_equipment_group?: PickEquipmentGroup | null;
  pick_equipment_group_id?: string | null;
  product_summary?: LocationProductSummary;
  replenishment_threshold?: number | null;
  subtype?: LocationSubtype;
  type?: LocationType;
  updated_at?: string;
  zone?: Zone;
  zone_id?: string | null;
};
export type AdjustmentReasonCode = {
  id?: string;
  code?: number;
  reason?: string;
};
export type PackagingMetadata = {
  packaging_material?: string;
};
export type Packaging = {
  id?: string;
  category?: 'box' | 'padded_envelope';
  default?: boolean;
  description?: string;
  height?: number;
  length?: number;
  name?: string;
  product_id?: string;
  shipper_id?: string;
  volume?: number;
  weight?: number;
  width?: number;
  barcode?: string;
  inserted_at?: string;
  updated_at?: string;
  metadata?: PackagingMetadata;
};
export type ParcelItem = {
  id?: string;
  building_id?: string;
  item?: Item;
  item_id?: string;
  parcel_id?: string;
  quantity?: number;
  inserted_at?: string;
  updated_at?: string;
};
export type ParcelStatus = 'pending' | 'awaiting_shipping_label' | 'ready' | 'void' | 'shipped';
export type Parcel = {
  id?: string;
  tracking_number?: string | null;
  tracking_url?: string | null;
  carrier_name?: string | null;
  shipping_label?: string | null;
  service_method?: string | null;
  currency_code?: string | null;
  price?: number | null;
  external_parcel_id?: string | null;
  height?: number;
  width?: number;
  weight?: number;
  length?: number;
  packaging?: Packaging;
  packaging_id?: string | null;
  parcel_items?: ParcelItem[];
  is_shipped_in_own_container?: boolean;
  license_plate?: LicensePlate | null;
  license_plate_id?: string | null;
  status?: ParcelStatus;
  outbound?: OutboundOrder;
  outbound_id?: string;
  verified_at?: string | null;
  verified_by_user?: User | null;
  inserted_at?: string;
  updated_at?: string;
};
export type LicensePlate = {
  id?: string;
  active?: boolean;
  number?: string;
  location_id?: string;
  location?: Location;
  locked_at?: string | null;
  inserted_at?: string;
  updated_at?: string;
  locked_reason_code?: AdjustmentReasonCode;
  locked_reason_code_id?: string;
  items?: Item[];
  parcels?: Parcel[];
};
export type SerialNumber = {
  id?: string;
  item_id?: string;
  item?: Item;
  inserted_at?: string;
  number?: string;
  parcel_id?: string;
  parcel?: Parcel;
  product_id?: string;
  product?: Product;
  updated_at?: string;
};
export type Item = {
  id?: string;
  product?: Product;
  quantity?: number;
  inserted_at?: string;
  updated_at?: string;
  inbound_line_id?: string;
  lot_number?: string | null;
  manufacturer_date?: string;
  outbound_line_item?: OutboundOrderLineItem;
  received_at_date?: string;
  expiration_date?: string;
  license_plate_id?: string;
  license_plate?: LicensePlate;
  shipper_id?: string;
  receipt_line_id?: string;
  serial_numbers?: SerialNumber[];
};
export type OutboundOrderLineItem = {
  id?: string;
  item?: Item;
  item_id?: string;
  outbound_line_id?: string;
  pick_id?: string;
};
export type OutboundOrderLine = {
  id?: string;
  allocatable?: boolean;
  custom_reference_1?: string;
  custom_reference_2?: string;
  custom_reference_3?: string;
  external_line_id?: string;
  uom?: string;
  quantity?: number;
  picked_quantity?: number;
  shelf_life_days?: number;
  staged_quantity?: number;
  lot_number?: string;
  product?: Product;
  outbound_line_items?: OutboundOrderLineItem[];
  exact_lot_number_required?: boolean;
};
export type ProductIdentifier = 'sku' | 'sku_and_vpn' | 'vpn';
export type OrderIdentifier = 'custom_reference' | 'external_id' | 'external_order_number';
export type LpnMask = {
  name?: string;
  pattern?: string;
};
export type ExternalDocumentIdentifier =
  | 'custom_reference'
  | 'external_id'
  | 'external_order_number'
  | 'external_order_number_and_custom_reference'
  | 'all';
export type ShipperConfigPremiumFeatures = {
  auto_lot_locking_enabled?: boolean;
  prop65_warning_enabled?: boolean;
  prop65_warning_text?: string;
};
export type ExpirationDateFormat = 'month_date_year' | 'month_year';
export type PackingSlipType = 'none' | '4x6' | '8.5x11';
export type D2CPickFromLocationStrategy =
  | 'any_location'
  | 'forward_subtype_first'
  | 'forward_subtype_only';
export type B2BPickFromLocationStrategy =
  | 'any_location'
  | 'reserve_subtype_first'
  | 'reserve_subtype_only';
export type PutawayLocationStrategy =
  | 'any_location'
  | 'forward_subtype_first'
  | 'forward_subtype_only'
  | 'reserve_subtype_first'
  | 'reserve_subtype_only'
  | 'zone_specific';
export type TrackingDateFormat = 'MM/DD/YYYY' | 'MM/YYYY';
export type WorkOrderPickFromLocationStrategy =
  | 'any_location'
  | 'forward_subtype_first'
  | 'forward_subtype_only'
  | 'reserve_subtype_first'
  | 'reserve_subtype_only';
export type PickLocationStrategy =
  | 'any_location'
  | 'forward_subtype_only'
  | 'forward_subtype_first'
  | 'reserve_subtype_only'
  | 'reserve_subtype_first'
  | 'zone_picking';
export type PickPathInventoryPriority = 'deplete_locations_first' | 'make_fewest_picks';
export type ShipperSettings = {
  auto_create_missing_skus_enabled?: boolean;
  auto_lock_license_plates_during_receiving?: boolean;
  bypass_upc_scan_during_picking_enabled?: boolean;
  cycle_count_auto_adjustment_enabled?: boolean;
  cycle_count_auto_adjustment_threshold?: number;
  cycle_count_auto_adjustment_excluded_products?: string[];
  expiration_date_format?: ExpirationDateFormat;
  invia_slipstream_picking_enabled?: boolean;
  multi_sku_storage_lpns_enabled?: boolean;
  multi_sku_receiving_lpns_enabled?: boolean;
  packing_slip_type?: PackingSlipType;
  packing_slip_custom_branding_enabled?: boolean;
  packing_slip_custom_text?: string;
  pack_station_allow_upc_copy?: boolean;
  pack_station_quickpack_enabled?: boolean;
  pack_station_quickpack_threshold?: number;
  pick_from_location_strategy?: D2CPickFromLocationStrategy;
  print_and_apply_enabled?: boolean;
  print_and_apply_enable_weight_validation?: boolean;
  b2b_pick_from_location_strategy?: B2BPickFromLocationStrategy;
  pregenerate_shipping_labels?: boolean;
  putaway_location_strategy?: PutawayLocationStrategy;
  putaway_location_zone_id?: string;
  ship_less_than_full?: boolean;
  standard_bulk_stream_creation_enabled?: boolean;
  standard_bulk_stream_creation_threshold?: number;
  tracking_date_format?: TrackingDateFormat;
  inbounds_auto_confirm_receipt_on_all_lpn_putaway?: boolean;
  work_order_pick_from_location_strategy?: WorkOrderPickFromLocationStrategy;
  freight_pick_from_location_strategy?: PickLocationStrategy;
  parcel_pick_from_location_strategy?: PickLocationStrategy;
  require_exact_outbound_line_lot_numbers?: boolean;
  pick_path_inventory_priority?: PickPathInventoryPriority;
};
export type ShipperConfig = {
  id?: string;
  collapse_inbound_lines?: boolean;
  collapse_outbound_lines?: boolean;
  packing_slip_custom_logo_attachment_id?: string;
  packing_slip_custom_logo_attachment?: Attachment;
  product_identifier?: ProductIdentifier;
  outbound_order_identifier?: OrderIdentifier;
  inbound_order_identifier?: OrderIdentifier;
  lpn_masks?: LpnMask[];
  serial_number_masks?: SerialNumberMask[];
  packing_slip_identifier?: ExternalDocumentIdentifier;
  bol_identifier?: ExternalDocumentIdentifier;
  pack_station_enabled?: boolean;
  premium_features?: ShipperConfigPremiumFeatures;
  settings?: ShipperSettings;
  shipper_id?: string;
  inserted_at?: string;
  updated_at?: string;
};
export type Shipper = {
  id?: string;
  active?: boolean;
  name?: string;
  shipium_tenant_id?: string;
  shipper_config?: ShipperConfig | null;
  invia_enabled?: boolean;
  inserted_at?: string;
  twoboxes_merchant_id?: string | null;
  updated_at?: string;
  building?: Building;
  building_id?: string;
};
export type Building = {
  id?: string;
  name?: string;
  inserted_at?: string;
  timezone?: string;
  insights_enabled?: boolean;
  invia_base_url?: string;
  updated_at?: string;
  shipping_from?: Address;
  shippers?: Shipper[];
};
export type Shipment = {
  id?: string;
  trailer_number?: string;
  pallet_quantity?: number;
  pro_number?: string;
  seal_number?: string;
  special_instructions?: string;
  weight?: number;
  outbound?: OutboundOrder;
  inserted_at?: string;
  updated_at?: string;
};
export type OutboundOrderType = 'sales' | 'transfer';
export type OutboundOrderChannelCategory = 'b2b' | 'd2c' | 'retail';
export type OutboundExceptionCategory = 'invia_pick' | 'label' | 'pick' | 'packing' | 'batch';
export type Exception = {
  created_by_user?: User;
  id?: string;
  inserted_at?: string;
  notes?: string;
  resolution_notes?: string;
  resolved_at?: string;
  resolved_by_user?: User;
  updated_at?: string;
};
export type InviaPickExceptionMetadata = {
  error?: string;
  params?: {
    donor_lpn?: string;
    location_name?: string;
    new_lpn?: string;
    outbound_id?: string;
    outbound_line_id?: string;
    quantity?: number;
  };
};
export type OutboundPickExceptionMetadata = {
  location_id?: string;
  pick_id?: string;
  actual_pick_quantity?: number;
};
export type ParcelLabelExceptionMetadata = {
  error_message?: string;
  label_provider?: string;
};
export type PackingExceptionMetadata = {
  packing_lpn?: string;
};
export type OutboundExceptionSubCategory =
  | 'api_error'
  | 'canceled_order'
  | 'damaged_barcode'
  | 'insufficient_quantity'
  | 'manual_location_override'
  | 'no_product'
  | 'validation_error'
  | 'wrong_product'
  | 'unable_to_allocate';
export type OutboundException = {
  id?: string;
  batch_id?: string;
  category?: OutboundExceptionCategory;
  exception?: Exception;
  exception_id?: string;
  inserted_at?: string;
  metadata?:
    | InviaPickExceptionMetadata
    | OutboundPickExceptionMetadata
    | ParcelLabelExceptionMetadata
    | PackingExceptionMetadata;
  outbound_id?: string;
  outbound_line_id?: string;
  shipper_id?: string;
  subcategory?: OutboundExceptionSubCategory;
  updated_at?: string;
};
export type OutboundOrderIdentifier = {
  label: string;
  value: string;
};
export type OutboundOrder = {
  id?: string;
  attachments?: Attachment[];
  packed_at?: string | null;
  staged_at?: string | null;
  picked_at?: string | null;
  expected_shipped_at?: any;
  expected_shipped_at_date?: string;
  expected_shipped_at_time?: string | null;
  externally_created_at?: string;
  gift_message?: string | null;
  shipped_at?: string | null;
  shipped_by_user_id?: string | null;
  shipped_by_user?: User | null;
  external_id?: string;
  inserted_at?: string;
  updated_at?: string;
  scac?: string | null;
  shipping_carrier?: string | null;
  shipping_code?: string;
  notes?: string | null;
  external_bol_number?: string | null;
  status?: OutboundStatus;
  shipment_type?: OutboundShipmentType;
  fulfillable?: boolean;
  allocatable?: boolean;
  shipping_address?: Address;
  lines?: OutboundOrderLine[];
  bill_to?: Address;
  building?: Building;
  batch?: Batch;
  batch_id?: string;
  shipment?: Shipment;
  parcels?: Parcel[];
  shipper_id?: string;
  custom_reference?: string;
  external_order_number?: string;
  cancelation_reason?: string | null;
  canceled_by_user_id?: string | null;
  canceled_by_user?: User | null;
  packed_by_user_id?: string | null;
  packed_by_user?: User | null;
  packed_from_lpns?: string[];
  carrier_processing_external_id?: string | null;
  carrier_service_method?: string | null;
  carrier_ship_option?: string | null;
  priority?: number;
  tags?: string[];
  business_days_in_transit?: number | null;
  planned_carrier_service?: string | null;
  order_type?: OutboundOrderType;
  channel_name?: string;
  channel_category?: OutboundOrderChannelCategory;
  exceptions?: OutboundException[];
  order_identifiers?: OutboundOrderIdentifier[];
  saturday_delivery?: boolean;
  signature_required?:
    | ('signature' | 'resident_signature' | 'adult_signature' | 'adult_resident_signature')
    | null;
  desired_delivery_date?: string | null;
  number_of_lines?: number;
  order_line_total_quantity?: number;
  do_not_ship_before_datetime?: string | null;
  must_ship_by_datetime?: string | null;
  scheduled_pick_up_datetime?: string | null;
  print_option_1?: string | null;
  print_option_2?: string | null;
  print_option_3?: string | null;
  billing_account_id?: string | null;
  third_party_billing_enabled?: boolean;
};
export type BatchStatus = 'pending' | 'ready' | 'in_progress' | 'completed';
export type PackStrategy = 'bulk' | 'discrete' | 'stage' | 'singles';
export type PickStrategy = 'batch' | 'cluster' | 'discrete';
export type PickTotesStrategy = 'pick_skus_to_single_tote' | 'pick_skus_to_separate_totes';
export type Batch = {
  id?: string;
  assigned_user?: User;
  created_user?: User;
  outbounds?: OutboundOrder[];
  inserted_at?: string;
  updated_at?: string;
  shipper_id?: string;
  shipper?: Shipper;
  status?: BatchStatus;
  completed_at?: string;
  automation_config_id?: string;
  released_at?: string;
  released_by_user_id?: string;
  released_by_user?: User;
  packaging_id?: string;
  packaging?: Packaging;
  pack_strategy?: PackStrategy;
  pick_strategy?: PickStrategy;
  pick_totes_strategy?: PickTotesStrategy | null;
  pick_location_strategy?: PickLocationStrategy;
  pick_equipment_group_id?: string;
  zones?: Zone[];
};
export type SimpleError = {
  errors?: {
    detail?: string;
  };
};
export type OutboundAvailability = 'allocatable' | 'fulfillable' | 'none';
export type BinLocation = {
  id?: string;
  name?: string;
  product_name?: string;
  shipper_name?: string;
  sku?: string;
  sku_description?: string;
  items_quantity?: number;
  uom?: string;
  lpn_count?: number;
  max_capacity?: number | null;
  replenishment_threshold?: number | null;
  zone_name?: string | null;
  type?: LocationType;
  subtype?: LocationSubtype;
};
export type BuildingAnnouncement = {
  id?: string;
  active?: boolean;
  announcement?: Announcement;
  announcement_id?: string;
  building?: Building;
  building_id?: string;
  created_by_user?: User;
  created_by_user_id?: string;
  inserted_at?: string;
  updated_at?: string;
  updated_by_user?: User;
  updated_by_user_id?: string;
};
export type BulkLabelCreationJob = {
  id?: string;
  status?: 'pending' | 'completed';
  completed?: number;
  total?: number;
};
export type OutboundAttachment = {
  attachment?: Attachment;
  attachment_id?: string;
  id?: string;
  outbound_id?: string;
};
export type Pick = {
  id?: string;
  completed_at?: string;
  diverted_from_location_id?: string;
  outbound_exception?: OutboundException;
  outbound_exception_id?: string;
  outbound?: OutboundOrder;
  outbound_id?: string;
  outbound_line?: OutboundOrderLine;
  outbound_line_id?: string;
  outbound_line_items?: OutboundOrderLineItem[];
  picked_from_lpn?: string;
  picked_quantity?: number;
  pick_path_location?: PickPathLocation;
  pick_path_location_id?: string;
  product?: Product;
  product_id?: string;
  requested_quantity?: number;
  inserted_at?: string;
  updated_at?: string;
};
export type PickPathLocation = {
  id?: string;
  batch_id?: string;
  location?: Location;
  location_id?: string;
  pick_path_sequence?: number;
  picks?: Pick[];
  shipper_id?: string;
  inserted_at?: string;
  updated_at?: string;
};
export type Printer = {
  id?: string;
  name: string;
  network_ip_address?: string;
  external_id?: string;
  inserted_at?: string;
  updated_at?: string;
};
export type Movement = {
  destination_location_id?: string;
  destination_location?: Location;
  id?: string;
  inserted_at?: string;
  item_id?: string;
  item?: Item;
  license_plate?: LicensePlate;
  original_location_id?: string;
  original_location?: Location;
  quantity?: number;
  product?: Product;
  updated_at?: string;
  user?: User;
};
export type Adjustment = {
  id?: string;
  adjusted_quantity?: number;
  change?: number;
  license_plate?: LicensePlate;
  location?: Location;
  lot_number?: string;
  notes?: string;
  product?: Product;
  reason_code?: AdjustmentReasonCode;
  user?: User;
  item?: Item;
  inserted_at?: string;
  updated_at?: string;
};
export type MultiError = {
  errors?: {
    name?: string;
    message?: string;
    reasons?: string[];
  }[];
};
export type InboundStatus =
  | 'open'
  | 'arrived'
  | 'partially_received'
  | 'received'
  | 'put_away'
  | 'canceled';
export type ReturnCondition = 'new' | 'damaged' | 'other';
export type ReturnMetadata = {
  condition?: ReturnCondition;
  receive_to_lpn?: string;
  lot_number?: string;
  manufacturer_date?: string;
  expiration_date?: string;
  return_type?: string;
  conditions?: string[];
  disposition?: string;
};
export type InboundOrderLine = {
  id?: string;
  received_at?: string;
  put_away_at?: string;
  expected_quantity?: number;
  received_quantity?: number;
  uom?: string;
  inbound_id?: string;
  product_id?: string;
  notes?: string;
  lot_number?: string;
  product?: Product;
  items?: Item[];
  external_line_id?: string;
  expiration_date?: string;
  return_metadata?: ReturnMetadata;
};
export type InboundOrderType = 'purchase' | 'return' | 'transfer';
export type InboundExceptionCategory = 'putaway';
export type PutawayExceptionMetadata = {
  license_plate_id?: string;
  location_id?: string;
};
export type InboundExceptionSubcategory =
  | 'cannot_fit_product'
  | 'damaged_barcode'
  | 'no_valid_locations'
  | 'wrong_product';
export type InboundException = {
  category?: InboundExceptionCategory;
  exception?: Exception;
  exception_id?: string;
  id?: string;
  inbound?: InboundOrder;
  inbound_id?: string;
  inserted_at?: string;
  metadata?: PutawayExceptionMetadata;
  shipper?: Shipper;
  shipper_id?: string;
  subcategory?: InboundExceptionSubcategory;
  updated_at?: string;
};
export type InboundOrder = {
  id?: string;
  arrived_at?: string;
  received_at?: string;
  put_away_at?: string;
  expected_at_date?: string;
  expected_at_time?: string | null;
  external_id?: string;
  externally_created_at?: string;
  inserted_at?: string;
  notes?: string;
  pallet_count?: number;
  status?: InboundStatus;
  lines?: InboundOrderLine[];
  building?: Building;
  shipper_id?: string;
  custom_reference?: string;
  external_order_number?: string;
  cancelation_reason?: string;
  canceled_by_user_id?: string;
  canceled_by_user?: User;
  order_type?: InboundOrderType;
  channel_name?: string;
  supplier_address?: Address;
  carrier_name?: string;
  tracking_number?: string;
  updated_at?: string;
  source?: string;
  return_postage_price_cents?: number | null;
  rma_number?: string;
  exceptions?: InboundException[];
};
export type Customer = {
  id?: string;
  company_id?: string;
  name?: string;
  external_id?: string;
  stord_managed?: boolean;
  inserted_at?: string;
  updated_at?: string;
};
export type ContractStatus = 'pending' | 'active' | 'expired';
export type BillingCategory = {
  id?: string;
  name?: string;
  inserted_at?: string;
  updated_at?: string;
};
export type BillingCode = {
  id?: string;
  active?: boolean;
  billing_category?: BillingCategory;
  billing_category_id?: string;
  company_id?: string;
  code?: string;
  name?: string;
  description?: string;
  unit?: string;
  inserted_at?: string;
  updated_at?: string;
};
export type Price = {
  id?: string;
  amount?: number;
  currency?: string;
  company_id?: string;
  billing_code_id?: string;
  billing_code?: BillingCode;
  contract_id?: string;
  inserted_at?: string;
  updated_at?: string;
};
export type Contract = {
  id?: string;
  cloned_from_id?: string;
  company_id?: string;
  customer_id?: string;
  category_id?: string;
  effective_at?: string;
  expires_at?: string;
  notes?: string;
  status?: ContractStatus;
  prices?: Price[];
  inserted_at?: string;
  updated_at?: string;
};
export type InvoiceStatus = 'draft' | 'open' | 'paid' | 'void';
export type Invoice = {
  id?: string;
  amount?: number;
  company_id?: string;
  currency?: string;
  customer_id?: string;
  ends_at?: string;
  notes?: string;
  summary?: {
    amount?: number;
    billing_category?: string;
  }[];
  status?: InvoiceStatus;
  starts_at?: string;
  inserted_at?: string;
  updated_at?: string;
};
export type InvoiceItem = {
  id?: string;
  amount?: number;
  charged_at?: string;
  company_id?: string;
  currency?: string;
  invoice_id?: string;
  name?: string;
  quantity?: number;
  source?: string;
  price?: Price;
  price_id?: string;
  inserted_at?: string;
  updated_at?: string;
};
export type ReceiptLine = {
  id?: string;
  sku?: string;
  quantity?: number;
  lot_number?: string;
  expiration_date?: string;
  item?: Item;
  product?: Product;
  inserted_at?: string;
  updated_at?: string;
};
export type Receipt = {
  id?: string;
  pallet_count?: number;
  status?: string;
  closed_at?: string;
  closed_by_user_id?: string;
  inbound_id?: string;
  receipt_lines?: ReceiptLine[];
  inserted_at?: string;
  updated_at?: string;
};
export type InboundOverviewStats = {
  arrived?: number;
  open?: number;
  partially_received?: number;
  received?: number;
};
export type OutboundOverviewStatsStatuses = {
  open?: number;
  allocated?: number;
  picking?: number;
  picked?: number;
  packing?: number;
  packed?: number;
  staged?: number;
  shipped?: number;
  total?: number;
};
export type OutboundOverviewStats = {
  current?: OutboundOverviewStatsStatuses;
  upcoming?: OutboundOverviewStatsStatuses;
  late?: number;
  unfulfillable?: number;
};
export type BroadcastNotification = {
  subject?: string;
  severity?: string;
  body?: string;
};
export type ProductLotNumber = {
  id?: string;
  inserted_at?: string;
  lot_number?: string;
  approved?: boolean;
  inserted_by_user?: User;
  inserted_by_user_id?: string;
  updated_by_user?: User;
  updated_by_user_id?: string;
  product_id?: string;
  product?: Product;
  shipper_id?: string;
  shipper?: Shipper;
  updated_at?: string;
};
export type WorkOrderCategory = 'kitting' | 'replenishment';
export type WorkOrderStatus = 'open' | 'released' | 'in_progress' | 'completed' | 'canceled';
export type WorkOrderStepAssembleKitInput = {
  kit_id?: string;
  kit_sku?: string;
  quantity?: number;
  location_id?: string;
  lpns?: string[];
  product_id?: string;
};
export type WorkOrderStepMoveInventoryInput = {
  location_id?: string;
  destination_location_type?: LocationType;
  destination_location_subtype?: LocationSubtype;
  destination_location_id?: string;
  lpns?: string[];
};
export type WorkOrderStepPickInventoryInput = {
  destination_location_id?: string;
  destination_location_type?: LocationType;
  destination_location_subtype?: LocationSubtype;
  requested_inventory?: {
    product_id?: string;
    sku?: string;
    quantity?: number;
  }[];
};
export type WorkOrderLpnInfo = {
  lpn?: string;
  location_id?: string;
  lot_number?: string;
  manufacturer_date?: string;
  expiration_date?: string;
  quantity?: number;
  product_id?: string;
};
export type WorkOrderStepAssembleKitOutput = {
  lpns?: string[];
  assembled_kit_history?: WorkOrderLpnInfo[];
};
export type WorkOrderStepMoveInventoryOutput = {
  lpns?: string[];
  movement_history?: {
    lpn?: string;
    location_id?: string;
  }[];
};
export type WorkOrderStepPickInventoryOutput = {
  license_plate_id?: string;
  lpns?: string[];
  location_id?: string;
  pick_history?: WorkOrderLpnInfo[];
};
export type WorkOrderStepStatus = 'open' | 'in_progress' | 'completed';
export type WorkOrderStepType = 'assemble_kit' | 'move_inventory' | 'pick_inventory';
export type WorkOrderStep = {
  id?: string;
  assigned_user?: User;
  completed_at?: string;
  inserted_at?: string;
  input?:
    | WorkOrderStepAssembleKitInput
    | WorkOrderStepMoveInventoryInput
    | WorkOrderStepPickInventoryInput;
  metadata?: {};
  output?:
    | WorkOrderStepAssembleKitOutput
    | WorkOrderStepMoveInventoryOutput
    | WorkOrderStepPickInventoryOutput;
  started_at?: string | null;
  status?: WorkOrderStepStatus;
  step_type?: WorkOrderStepType;
  step_number?: number;
  updated_at?: string;
  work_order_id?: string;
};
export type WorkOrder = {
  id?: string;
  category?: WorkOrderCategory;
  completed_at?: string;
  created_by_user?: User;
  inserted_at?: string;
  notes?: string;
  number?: string;
  priority?: number | null;
  updated_at?: string;
  shipper?: Shipper;
  shipper_id?: string;
  status?: WorkOrderStatus;
  steps?: WorkOrderStep[];
};
export type UserUpdate = {
  email?: string;
  name?: string;
  username?: string;
  password?: string;
  role?: string;
  active?: boolean;
  language?: string;
};
export type PackingSlipTemplateChannel = {
  id?: string;
  channel_name?: string;
  packing_slip_template_id?: string;
  shipper_id?: string;
};
export type PackingSlipTemplateLayout = 'branded' | 'custom' | 'standard';
export type PackingSlipTemplate = {
  id?: string;
  name?: string;
  format?: PackingSlipType;
  channels?: PackingSlipTemplateChannel[];
  default_template?: boolean;
  is_system_template?: boolean;
  layout?: PackingSlipTemplateLayout;
  shipper_id?: string;
  template?: string | null;
  additional_text?: string | null;
  logo_attachment_id?: string | null;
  logo_attachment?: Attachment;
  inserted_at?: string;
  updated_at?: string;
};
export type CycleCountAdjustmentOutcome = 'adjusted' | 'none' | 'rejected';
export type CycleCountExceptionCategory = 'lpn' | 'product';
export type CycleCountLpnExceptionMetadata = {
  license_plate_id?: string;
  location_id?: string;
};
export type CycleCountProductExceptionMetadata = {
  product_id?: string;
};
export type CycleCountExceptionSubCategory = 'wrong_location' | 'no_barcode';
export type CycleCountException = {
  id?: string;
  cycle_count_id?: string;
  category?: CycleCountExceptionCategory;
  exception?: Exception;
  exception_id?: string;
  inserted_at?: string;
  metadata?: CycleCountLpnExceptionMetadata | CycleCountProductExceptionMetadata;
  subcategory?: CycleCountExceptionSubCategory;
  updated_at?: string;
};
export type CycleCountItemMetadata = {
  lpn?: string;
  lot_number?: string;
  expiration_date?: string;
  manufacturer_date?: string;
};
export type CycleCountResult = {
  id?: string;
  adjusted_by_user?: User;
  adjustment?: Adjustment;
  adjustment_id?: string;
  calculated_threshold_units?: number;
  cycle_count_location_id?: string;
  inserted_at?: string;
  item?: Item;
  item_id?: string;
  item_metadata?: CycleCountItemMetadata;
  license_plate?: LicensePlate;
  license_plate_id?: string;
  product?: Product;
  product_id?: string;
  quantity_expected?: number;
  quantity_found?: number;
  submitted_at?: string;
  updated_at?: string;
};
export type CycleCountLocation = {
  id?: string;
  location?: Location;
  location_id?: string;
  cycle_count_id?: string;
  inserted_at?: string;
  updated_at?: string;
  cycle_count_results?: CycleCountResult[];
};
export type CycleCountStatus = 'open' | 'in_progress' | 'review' | 'completed' | 'canceled';
export type CycleCount = {
  id?: string;
  adjustment_outcome?: CycleCountAdjustmentOutcome;
  assigned_user?: User;
  canceled_at?: string;
  canceled_by_user?: User;
  completed_at?: string;
  created_user?: User;
  cycle_count_exceptions?: CycleCountException[];
  cycle_count_locations?: CycleCountLocation[];
  inserted_at?: string;
  outbound_exception?: OutboundException;
  outbound_exception_id?: string;
  reviewed_by_user?: User;
  started_at?: string;
  status?: CycleCountStatus;
  submitted_at?: string;
};
export type LicensePlateLockableSearchFields = {
  product_ids?: string;
  location_ids?: string;
  inbound_ids?: string;
  outbound_ids?: string;
  shipper_ids?: string;
  search?: string;
  location_name?: string;
  location_types?: string;
  location_subtypes?: string;
  locked?: boolean;
  item_ids?: string;
  inserted_before_datetime?: string;
  inserted_after_datetime?: string;
  inbound_id?: string;
  inbound_line_ids?: string;
  license_plate_ids?: string;
  license_plate_numbers?: string;
  outbound_id?: string;
  skus?: string;
  lot_number?: string;
  manufacturer_date?: string;
  expiration_date?: string;
  has_expiration_date?: boolean;
};
export type KitAttachment = {
  id?: string;
  attachment?: Attachment;
  kit?: Kit;
  kit_id?: string;
};
export type ShipperConfigDeveloperFeatures = {
  verbose_logging?: boolean;
};
export type ShipperAddress = {
  id?: string;
  default_bill_to?: boolean;
  name?: string;
  address?: Address;
  shipper_id?: string;
  inserted_at?: string;
  updated_at?: string;
};
export type SpecialInstruction = {
  id?: string;
  name?: string;
  default?: boolean;
  description?: string;
  shipper_id?: string;
  last_modified_by_user_id?: string;
  last_modified_by_user?: User;
  inserted_at?: string;
  updated_at?: string;
};
export type AutomationConfigCategory = 'stream_outbounds' | 'putaway_lpn';
export type AutomationConfigStreamOutboundsMetadata = {
  order_threshold?: number;
  packaging_id?: string;
  packaging?: Packaging;
  pack_strategy?: PackStrategy;
  pick_equipment_group_id?: string;
  pick_equipment_group?: PickEquipmentGroup;
  pick_strategy?: PickStrategy;
  pick_totes_strategy?: PickTotesStrategy | null;
  pick_location_strategy?: PickLocationStrategy | null;
  release_streams_before?: string;
  zone_id?: string | null;
  zone?: Zone;
};
export type AutomationConfigPutawayLpnMetadata = {
  putaway_location_strategy?: PutawayLocationStrategy;
  putaway_location_zone_id?: string;
};
export type AutomationConfig = {
  id?: string;
  category?: AutomationConfigCategory;
  inserted_at?: string;
  updated_at?: string;
  shipper_id?: string;
  shipper?: Shipper;
  priority?: number;
  name?: string;
  description?: string;
  active?: boolean;
  metadata?: AutomationConfigStreamOutboundsMetadata | AutomationConfigPutawayLpnMetadata;
  conditions?: object;
};
export type OrionWarehouseMapping = {
  active?: boolean;
  building_id?: string;
  distribution_network_id?: string;
  facility_id?: string;
  id?: string;
  inserted_at?: string;
  orion_version?: number;
  shipper_id?: string;
  tenant_id?: string;
  updated_at?: string;
  warehouse_alias?: string;
};
export type PackoutSession = {
  id?: string;
  license_plate_id?: string;
  license_plate_ids?: string[];
  packaging_id?: string;
  packing_method?: 'bulk' | 'discrete';
  outbound_ids?: string[];
  shipper_id?: string;
};
export type KittingWorkOrderMetadata = {
  kit_id?: string;
  quantity?: number;
};
export type ReplenishmentWorkOrderMetadata = {
  product_id?: string;
  replenish_location_id?: string;
  quantity?: number;
};
export type ReplenishmentLookAheadResult = {
  product_id?: string;
  shipper_id?: string;
  blocked_outbounds?: number;
  blocked_quantity?: number;
  planned_replenishment_quantity?: number;
};
export type WorkOrderSearchFields = {
  number?: string;
  inserted_before_datetime?: string;
  inserted_after_datetime?: string;
  completed_before_datetime?: string;
  completed_after_datetime?: string;
  status?: string;
  shipper_id?: string;
  shipper_ids?: string;
  category?: string;
  created_by_user_id?: string;
  assigned_user_id?: string;
  available_for_user_id?: string;
  product_id?: string;
  location_id?: string;
};
export type WorkOrderAttachment = {
  id?: string;
  attachment?: Attachment;
  work_order?: WorkOrder;
  work_order_id?: string;
};
export type BarcodeDetailsCombined = {
  schema?: 'product' | 'license_plate' | 'location' | 'outbound';
  data?: Location | LicensePlate | Product | OutboundOrder;
};
export type BarcodeDetails = {
  locations?: Location[];
  license_plates?: LicensePlate[];
  products?: Product[];
  outbounds?: OutboundOrder[];
  combined?: BarcodeDetailsCombined[];
};
export type InventoryTotal = {
  building_id?: string;
  shipper_id?: string;
  product_id?: string;
  storage_subtype?: string;
  physical_count?: number;
  pending_allocation_count?: number;
  released_allocation_count?: number;
};
export type Provider = {
  id?: string;
  name?: string;
  active?: boolean;
  inserted_at?: string;
  updated_at?: string;
};
export type BatchExceptionMetadata = {
  sku?: string;
  line_insufficient_quantity?: number;
  total_insufficient_quantity?: number;
};
export type CubiscanLabelResponse = {
  Command?: string;
  CartonID?: string;
  Sequence?: number;
  StatusCode?: number;
  Message?: string;
  LabelZPL?: string;
  TrackingCode?: string;
  ReturnLabelZPL?: string;
  ReturnTrackingCode?: string;
  Length?: string;
  Width?: string;
  Height?: string;
  Weight?: string;
  DimUOM?: string;
  WeightUOM?: string;
  TotalCharge?: string;
  DivertLane?: number;
  ShipViaCode?: string;
};
export type CubiscanStatusUpdateResponse = {
  Command?: string;
  CartonID?: string;
  Sequence?: number;
  StatusCode?: number;
  Message?: string;
};
