import { Button, VStack } from '@chakra-ui/react';
import { RHFInput, RHFSelect } from '@components/forms';
import { addressTypeOptions } from '@constants/addressTypes';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSetFieldErrors, useToast } from '@hooks';
import { usStatesOptions } from '@constants/usStates';
import { caProvinceOptions } from '@constants/canadianProvinces';
import type { Address } from '@store/services/api.generated';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { WmsModalFooter } from './WmsModal';
import { hasGenericApiErrors, isFetchBaseQueryError } from '@store/services/utils';
import { capitalize } from 'lodash';

interface AddressFormProps {
  address?: Address;
  saveAddress: (values: AddressFormData) => Promise<void>;
  onSuccess: () => void;
  onCancel: () => void;
  customId?: string;
  showCustomId?: boolean;
  defaultAddressType?: Address['type'];
}

// Only require state and zip for US and CA
const COUNTRIES_WITH_REQUIRED_STATE_AND_POSTAL_VALUE = ['CA', 'US'];

const addressRawSchema = yup.object({
  name: yup.string().required('Name is required'),
  company: yup.string(),
  custom_id: yup.string(),
  line1: yup.string().required('Address is required'),
  line2: yup.string(),
  city: yup.string().required('City is required'),
  state: yup.string().when('country', {
    is: (country: string | undefined) =>
      COUNTRIES_WITH_REQUIRED_STATE_AND_POSTAL_VALUE.includes(country?.toUpperCase() ?? ''),
    then: (schema) => schema.required('State is required'),
    otherwise: (schema) => schema.optional(),
  }),
  country: yup
    .string()
    .required('Country is required')
    .length(2, 'Country code must be two characters exactly'),
  email: yup.string().email(),
  phone: yup.string(),
  postal_code: yup.string().when('country', {
    is: (country: string | undefined) =>
      COUNTRIES_WITH_REQUIRED_STATE_AND_POSTAL_VALUE.includes(country?.toUpperCase() ?? ''),
    then: (schema) => schema.required('Zip/postal is required'),
    otherwise: (schema) => schema.optional(),
  }),
  type: yup.mixed().oneOf(['commercial', 'residential']),
});

export type AddressFormData = yup.InferType<typeof addressRawSchema>;

export const AddressForm = ({
  address,
  defaultAddressType,
  saveAddress,
  onSuccess,
  onCancel,
  customId,
  showCustomId = false,
}: AddressFormProps) => {
  const { successToast, errorToast } = useToast();

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    setError,
    watch,
  } = useForm<AddressFormData>({
    defaultValues: {
      custom_id: customId ?? '',
      name: address?.name ?? '',
      company: address?.company ?? '',
      line1: address?.line1 ?? '',
      line2: address?.line2 ?? '',
      city: address?.city ?? '',
      state: address?.state ?? '',
      country: address?.country ?? 'US',
      phone: address?.phone ?? '',
      email: address?.email ?? '',
      postal_code: address?.postal_code ?? '',
      type: address?.type ?? defaultAddressType ?? '',
    },
    resolver: yupResolver(addressRawSchema),
  });

  const setFieldErrors = useSetFieldErrors({ setError });

  const country = watch('country');

  const onSubmit = handleSubmit(async (values: AddressFormData) => {
    try {
      await saveAddress(values);
      successToast('Address saved');
      onSuccess();
    } catch (err) {
      const didSetErrors = setFieldErrors(err);
      if (!didSetErrors) {
        errorToast('An error occurred trying to update address');
      } else if (isFetchBaseQueryError(err) && hasGenericApiErrors(err.data)) {
        const errorMessage = capitalize(err?.data?.errors?.[0]?.message);
        errorToast(errorMessage);
      }
    }
  });

  const hasEnumeratedStates = country === 'US' || country === 'CA';

  return (
    <form onSubmit={onSubmit}>
      <VStack align="stretch">
        {showCustomId && <RHFInput name="custom_id" label="Custom ID" control={control} />}
        <RHFSelect
          name="type"
          label="Type"
          placeholder="--"
          options={addressTypeOptions}
          control={control}
        />
        <RHFInput name="name" label="Name" control={control} />
        <RHFInput name="company" label="Company" control={control} />
        <RHFInput name="line1" label="Address 1" control={control} />
        <RHFInput name="line2" label="Address 2" control={control} />
        <RHFInput name="city" label="City" control={control} />
        {hasEnumeratedStates ? (
          <RHFSelect
            name="state"
            label="State/Province"
            placeholder="--"
            options={country === 'CA' ? caProvinceOptions : usStatesOptions}
            control={control}
          />
        ) : (
          <RHFInput name="state" label="State/Province" control={control} />
        )}
        <RHFInput name="postal_code" label="ZIP/Postal" control={control} />
        <RHFInput name="country" label="Country Code" control={control} />
        <RHFInput name="phone" label="Phone Number" control={control} />
        <RHFInput name="email" type="email" label="Email Address" control={control} />
        <WmsModalFooter>
          <Button variant="outline" colorScheme="gray" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" data-testid="confirm" isLoading={isSubmitting}>
            Save
          </Button>
        </WmsModalFooter>
      </VStack>
    </form>
  );
};
