import { mode } from '@chakra-ui/theme-tools';
import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const ScannerFlowOptionTemplate: ComponentStyleConfig = {
  parts: [
    'resultBanner',
    'resultBannerText',
    'resultIcon',
    'additionalTextContainer',
    'additionalText',
  ],
  baseStyle: (props) => ({
    resultBanner: {},
    resultBannerText: {
      color: mode('orange.700', 'gray.900')(props),
      fontSize: '3xl',
      fontWeight: 'bold',
      lineHeight: '40px',
    },
    resultIcon: {
      color: mode('white', 'gray.900')(props),
      ml: 8,
    },
    additionalTextContainer: {},
    additionalText: {},
  }),
  variants: {
    option: (props) => ({
      resultBanner: {
        bgColor: mode('orange.50', 'orange.200')(props),
      },
    }),
  },
};
