import * as yup from 'yup';
import { dateValidationSchema } from './dateValidationSchema';

export const createReturnSchema = yup.object({
  external_id: yup.string(),
  shipper_id: yup.string().required('Shipper is required'),
  tracking_number: yup.string().required('Tracking number is required'),
  carrier: yup.string(),
  custom_reference: yup.string(),
  name: yup.string(),
  line1: yup.string(),
  line2: yup.string(),
  city: yup.string(),
  state: yup.string(),
  country: yup.string(),
  postal_code: yup.string(),
  return_postage_price_cents: yup
    .number()
    .nullable(true)
    .transform((_, val) => {
      if (val === '' || val === null) return null;
      const numericValue = parseFloat(val);
      return isNaN(numericValue) ? null : numericValue;
    })
    .min(0.01, 'Postage Due must be at least $0.01')
    .test('max-decimals', 'Must be a valid format (e.g. $0.01)', (value) => {
      if (value === null || value === undefined) return true;
      const price = value * 100;
      return Math.trunc(price) === price;
    }),
});

export const addReturnLineSchema = yup.object({
  id: yup.string(),
  name: yup.string(),
  sku: yup.string().required(),
  uom: yup.string().required(),
  quantity: yup
    .number()
    .typeError('Quantity must be a number')
    .required('Quantity is required')
    .positive('Must be greater than 0'),
  return_type: yup.string().required('Reason is required'),
  return_condition: yup.string().required('Condition is required'),
  lot_number: yup.string().when('$lotTracked', {
    is: true,
    then: yup.string().required('Lot number is required'),
  }),
  manufacturer_date: dateValidationSchema({
    label: 'Manufacturer Date',
    key: 'manufacturerDateTracked',
  }),
  expiration_date: dateValidationSchema({
    label: 'Expiration Date',
    key: 'expirationDateTracked',
  }),
  lpn: yup.string().required('LPN is required'),
});
