import { forwardRef, memo, useMemo } from 'react';
import type { ReactNode } from 'react';
import type { SelectProps } from '@chakra-ui/react';
import { isDropDownOptionSelected } from '@utils/filterSelection';
import { Box, Select, useColorModeValue as mode } from '@chakra-ui/react';
import { useHighlightSelectedProps } from '@hooks/useHighlightSelectedProps';
import { FilterLabel } from '@components';

declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactNode | null
  ): (props: P & React.RefAttributes<T>) => React.ReactNode | null;
}

interface DropdownFilterProps<T = string> extends Omit<SelectProps, 'onChange'> {
  label?: ReactNode;
  onChange?: (value: T) => void;
  options: DropdownFilterOption<T>[];
  valueAsNumber?: boolean;
  defaultSelection?: T;
  disableSelectedHighlight?: boolean;
  staticWidth?: boolean;
}

export interface DropdownFilterOption<T = string> {
  value: T;
  label: ReactNode;
  disabled?: boolean;
}

const DropdownFilterComponentInner = <T extends string | number>(
  {
    label,
    defaultSelection,
    onChange,
    options,
    valueAsNumber,
    disableSelectedHighlight = false,
    staticWidth = false,
    ...selectProps
  }: DropdownFilterProps<T>,
  ref: React.ForwardedRef<HTMLSelectElement>
) => {
  const width = selectProps?.width ?? selectProps?.w ?? { base: '50%', md: '144px' };

  const currentValue = selectProps?.defaultValue || selectProps?.value;
  const shouldHighlight = useMemo(
    () => !disableSelectedHighlight && isDropDownOptionSelected(currentValue, defaultSelection),
    [currentValue, defaultSelection, disableSelectedHighlight]
  );
  const highlightingProps = useHighlightSelectedProps(shouldHighlight);

  return (
    <Box>
      {label && <FilterLabel label={label} />}
      <Select
        ref={ref}
        color={mode('gray.700', 'darkmode.gray.100')}
        minWidth={!staticWidth ? 'max-content' : undefined}
        onChange={(event) =>
          onChange?.(valueAsNumber ? (Number(event.target.value) as T) : (event.target.value as T))
        }
        width={width}
        {...selectProps}
        {...highlightingProps}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value} disabled={option.disabled}>
            {option.label}
          </option>
        ))}
      </Select>
    </Box>
  );
};

const DropdownFilterComponent = forwardRef(DropdownFilterComponentInner);

export const DropdownFilter = memo(DropdownFilterComponent) as typeof DropdownFilterComponent;
