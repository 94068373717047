import type { ChakraTheme } from '@chakra-ui/react';
import { extendTheme, cssVar } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { colors } from './foundations';
import {
  Announcement,
  ScannerFlowContent,
  ScannerFlowCompleteTemplate,
  ScannerFlowOptionTemplate,
} from './templates';
import { Alert, Toast } from './components';
import { Button } from './components/Button';

const overrides: Partial<ChakraTheme> = {
  colors,
  components: {
    Alert,
    Announcement,
    Button,
    Badge: {
      variants: {
        success: (props) => ({
          bg: mode('green.100', 'none')(props),
          boxShadow: mode('none', 'inset 0 0 0px 1px')(props),
          color: mode('green.800', 'green.300')(props),
        }),
        neutral: (props) => ({
          bg: mode('gray.200', 'none')(props),
          boxShadow: mode('none', 'inset 0 0 0px 1px')(props),
          color: mode('gray.800', 'gray.300')(props),
        }),
        outline: (props) => ({
          boxShadow: 'inset 0 0 0px 1px',
          color: `${props.colorScheme}.600`,
          cursor: 'pointer',
          _hover: {
            bg: mode(`${props.colorScheme}.50`, `${props.colorScheme}.100`)(props),
          },
        }),
        subtle: (props) => ({
          bg: mode(`${props.colorScheme}.50`, 'none')(props),
          boxShadow: mode('none', 'inset 0 0 0px 1px')(props),
          color: mode(`${props.colorScheme}.500`, `${props.colorScheme}.200`)(props),
          cursor: 'pointer',
          _hover: {
            bg: mode(`${props.colorScheme}.100`, `${props.colorScheme}.50`)(props),
            color: mode(`${props.colorScheme}.500`, `${props.colorScheme}.600`)(props),
          },
        }),
        lighter: (props) => ({
          bg: mode(`${props.colorScheme}.50`, 'transparent')(props),
          borderRadius: '4px 0px 0px 4px',
          boxShadow: mode('none', 'inset 0 0 0px 1px')(props),
          color: mode('black', `${props.colorScheme}.200`)(props),
          cursor: 'auto',
          fontSize: 'md',
          fontWeight: 'normal',
          mr: 0,
          paddingX: 2,
          textTransform: 'none',
          _hover: {
            bg: mode(`${props.colorScheme}.50`, 'transparent')(props),
          },
        }),
        light: (props: Record<string, any>) => {
          const { colorScheme: c } = props;
          const lightBg = c === 'green' ? 'green.500' : `${props.colorScheme}.200`;

          return {
            bg: mode(lightBg, `${props.colorScheme}.200`)(props),
            borderRadius: '0px 4px 4px 0px',
            boxShadow: 'none',
            color: mode('black', 'gray.900')(props),
            cursor: 'auto',
            fontSize: 'md',
            fontWeight: 'semibold',
            paddingX: 2,
            textTransform: 'none',
            _hover: {
              bg: mode(lightBg, `${props.colorScheme}.200`)(props),
            },
          };
        },
      },
      sizes: {
        lg: {
          fontSize: '1.05rem',
        },
      },
    },
    Heading: {
      baseStyle: (props) => ({
        color: mode('gray.900', 'darkmode.gray.100')(props),
      }),
    },
    Input: {
      variants: {
        outline: (props) => ({
          field: {
            bg: mode('white', 'darkmode.gray.800')(props),
            borderColor: mode('gray.200', 'whiteAlpha.300')(props),
            borderRadius: '4px',
          },
        }),
        scannable: (props) => ({
          ...theme.components.Input.variants.outline(props),
          color: mode('gray.800', 'darkmode.gray.200')(props),
          fontSize: 'sm',
          fontWeight: 'bold',
        }),
      },
    },
    Link: {
      baseStyle: (props) => ({
        color: mode('blue.500', 'darkmode.blue.300')(props),
      }),
      variants: {
        navLink: (props) => ({
          bg: 'transparent',
          border: 0,
          display: 'flex',
          alignItems: 'center',
          fontSize: 'md',
          fontWeight: 'normal',
          color: mode('gray.900', 'darkmode.gray.100')(props),
          _focus: {
            bg: mode('gray.100', 'gray.800')(props),
          },
          _hover: {
            bg: mode('gray.100', 'gray.800')(props),
            textDecoration: 'none',
            transform: 'scale(1.25)',
          },
        }),
      },
    },
    Modal: {
      baseStyle: (props) => ({
        dialog: {
          bg: mode('white', 'darkmode.gray.800')(props),
          borderWidth: '0px',
        },
      }),
    },
    Menu: {
      baseStyle: (props) => ({
        list: {
          bg: mode('white', 'darkmode.gray.800')(props),
          color: mode('gray.900', 'darkmode.gray.100')(props),
        },
        item: {
          bg: mode('white', 'darkmode.gray.800')(props),
          color: mode('gray.900', 'darkmode.gray.100')(props),
          _hover: {
            bg: mode('gray.100', 'gray.800')(props),
          },
        },
      }),
    },
    ScannerFlowContent,
    ScannerFlowCompleteTemplate,
    ScannerFlowOptionTemplate,
    Select: {
      variants: {
        outline: (props) => ({
          field: {
            bg: mode('white', 'darkmode.gray.800')(props),
            borderColor: mode('gray.200', 'whiteAlpha.300')(props),
            borderRadius: '4px',
          },
        }),
      },
    },
    Skeleton: {
      variants: {
        smallText: (props) => ({
          h: '21px',
        }),
      },
    },
    Table: {
      baseStyle: (props) => ({
        table: {
          bg: mode('white', 'darkmode.gray.800')(props),
          color: mode('gray.900', 'darkmode.gray.100')(props),
        },
        th: {
          bg: mode('gray.50', 'darkmode.gray.600')(props),
          color: mode('gray.700', 'darkmode.gray.300')(props),
        },
      }),
      variants: {
        simple: (props) => ({
          th: {
            borderBottom: 0,
          },
          td: {
            borderColor: mode('gray.200', 'whiteAlpha.300')(props),
          },
          tr: {
            '&:last-of-type': {
              td: {
                borderBottom: 0,
              },
            },
          },
        }),
      },
    },
    Text: {
      baseStyle: (props) => ({
        color: mode('gray.900', 'darkmode.gray.100')(props),
      }),
      variants: {
        light: (props) => ({
          color: mode('gray.800', 'darkmode.gray.200')(props),
        }),
        lighter: (props) => ({
          color: mode('gray.700', 'darkmode.gray.300')(props),
        }),
        smallLabel: (props) => ({
          color: mode('gray.700', 'darkmode.gray.300')(props),
          fontSize: 'xs',
          fontWeight: 'semibold',
        }),
        label: (props) => ({
          fontSize: 'sm',
          fontWeight: 'semibold',
        }),
        sectionHeading: (props) => ({
          fontSize: 'lg',
          fontWeight: 'semibold',
        }),
        danger: (props) => ({
          color: mode('red.500', 'red.550')(props),
        }),
      },
    },
    Toast,
    Tooltip: {
      baseStyle: (props) => {
        const backgroundColor = mode('gray.900', 'darkmode.gray.100')(props);

        const $bg = cssVar('tooltip-bg');
        const $arrowBg = cssVar('popper-arrow-bg');

        return {
          [$bg.variable]: `colors.${backgroundColor}`,
          bg: [$bg.reference],
          [$arrowBg.variable]: [$bg.reference],
        };
      },
    },
  },
  config: {
    initialColorMode: 'system',
    useSystemColorMode: true,
  },
  fonts: {
    heading: '"Inter", sans-serif',
    body: '"Inter", sans-serif',
    mono: 'monospace',
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode('white', 'darkmode.gray.700')(props),
      },
    }),
  },
};

export const theme = extendTheme(overrides);
export default theme;

export * from './Provider';
